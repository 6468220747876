import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

export default function LoginLayout() {
  return (
    <div className='container'>
        <Header />
        {/*<LayoutContext.Provider value={{}}>
            {props.children}
        {/*</LayoutContext.Provider>*/}
        <Outlet />
        <Footer />
    </div>
  )
}


