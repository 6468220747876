import { Box, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import i18next from 'i18next'

function StatementCard({ theme, cardData }) {
    const { t } = useTranslation(i18next.language)
  return (
    <Box className={`statementcard-container ${theme}`}>
        <Box className='card-content-container'>
            <Typography className={`big-statement ${theme}`}>
                <Trans components={{ highlight: <b/> }}>
                    { t(cardData.statement) }
                </Trans>
            </Typography>
            {
                cardData.indexScore && (
                    <Typography className={`index-score ${theme}`}>
                        { t(cardData.indexScore) }
                    </Typography>
                )
            }
        </Box>
    </Box>
  )
}

export default StatementCard