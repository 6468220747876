import React, { useState } from 'react'
import { Modal, Box, Typography, MobileStepper, Button } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// import { assets } from '../common/images';
import { submitInfoPopupStatus } from '../../service/API';
import { useMsal } from '@azure/msal-react';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";


function HomeModal({ CardData, closeHandler }) {
    const { t } = useTranslation(i18next.language)
    const [open, setOpen] = useState(true);
    const { accounts } = useMsal()
    const handleClose = () => {
        popupStatusApi()
        // window.sessionStorage.setItem('homepageTooltipLoaded', true)
        setOpen(false);
    }
    const tooltipData = CardData.tooltipData;
    // const page = CardData.page;

    const [activeStep, setActiveStep] = React.useState(0);
    const [activeCardData, setActiveCardData] = React.useState(tooltipData[0]);
    const maxSteps = tooltipData.length;

    async function popupStatusApi() {
        let popupData = {
            userId: accounts[0].username,
            pageName: 'home'
        }
        try {
            await submitInfoPopupStatus(popupData);
        } catch (error) {
            console.error("API Error:", error);
        }
    }

    function handleSkip() {
        if (closeHandler) {
            closeHandler(false);
        }
        setOpen(false);
    }

    const handleNext = () => {
        if (activeStep < maxSteps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setActiveCardData(tooltipData[activeStep + 1]);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setActiveCardData(tooltipData[activeStep - 1]);
        }
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box className='modal-content'>
                <CloseOutlinedIcon className='close-modal-button'
                    onClick={handleClose} />
                <Box className="card-content-box" sx={{ flexGrow: 1 }}>
                    <Box sx={{ width: "100%", borderRadius: 0 }} className='card'>
                        <Box className='modal-visual'
                            sx={{
                                // backgroundImage: `url(${assets.home.modalImage})`
                                backgroundImage: `url(${activeCardData?.imgPath})`
                            }}>
                        </Box>
                        <Box className='modal-text-container'>
                            <Typography className='modal-text heading'>
                                {/* {t('home.modal.heading')} */}
                                {t(activeCardData?.title)}
                            </Typography>
                            <Typography className='modal-text description'>
                                {/* {t('home.modal.description')} */}
                                {t(activeCardData?.description)}
                            </Typography>
                        </Box>
                        <Box className="popup-stepper-container">
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <KeyboardArrowRight
                                        onClick={handleNext}
                                        sx={{ fill: "#000000" }}
                                    />
                                }
                                backButton={
                                    <KeyboardArrowLeft
                                        onClick={handleBack}
                                        sx={{ fill: "#000000" }}
                                    />
                                }
                                sx={{ padding: "20px 0", background: "none" }}
                                classes={{
                                    dotActive: "blackDot",
                                }}
                            />
                            {activeStep === maxSteps - 1 ? (
                                <Button size="small" onClick={handleClose} className="next-btn">
                                    {t("buttons.toolTipFinish")}
                                </Button>
                            ) : (
                                <Button size="small" onClick={handleSkip} className="next-btn">
                                    {t("buttons.toolTipSkip")}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default HomeModal