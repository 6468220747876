import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import WayFinderLayout from "../../common/page-sections/WayFinderLayout";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//Where are People in the Demand Landscape Section
export default function SectionFour() {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();
  return (
    <Box className="ps-hub-section4-container">
      <Grid container sx={{ margin: "6.25rem" }}>
        <Grid item xs={5} className="left-item">
          <Divider className="divider-line" />
          <Typography className="heading-text">
            {t("people-segment.hub.section4.heroTitle")}
          </Typography>
          <Button
            className="view-demandspace-btn"
            variant="contained"
            onClick={() => {
              navigate("/demandspace")
              window.scrollTo(0,0)
            }}
          >
            {t("buttons.viewDemandLandscape")}
          </Button>
        </Grid>
        <Grid item xs={7} className="right-item">
          <Box className="right-item-maxcontent" >
            <Box sx={{cursor:"pointer"}} onClick={()=> {
              navigate("/demandspace")
              window.scrollTo(0,0)
            }}>
              <Button
                variant="contained"
                className="button-to-ds"
                onClick={() => {
                  navigate("/demandspace");
                  window.scrollTo(0, 0);
                }}
              >
                {t("buttons.clickToViewDemandLandscape")}
              </Button>
            <WayFinderLayout peopleSegments="true" />
            </Box>
            {/* <div className="demand-landscape-click-container">
              <Grid container spacing={1} className="interaction-tool-tip">
                <Grid item>
                  <InfoOutlinedIcon
                    style={{ color: "#F6E003" }}
                    className="info-icon"
                  />
                </Grid>
                <Grid item mt={0.3} className="tool-tip-text">
                  {t("people-segment.hub.section4.click-info-text")}
                </Grid>
              </Grid>
            </div> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
