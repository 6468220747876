import React, { useEffect, useState } from "react";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material/";
import { NavOptions } from "../../constants/NavOptions";

import { ReactComponent as Logo } from "../../assets/images/Login/Mark.svg";

function Header() {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1024) {
        setDeviceType("desktop");
      } else if (width >= 768 && width <= 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("mobile");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial device type detection

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="page-header">
      {(deviceType === "tablet" || deviceType === "mobile") && (
        <AppBar
          component="nav"
          position="fixed"
          className={`app-bar-container-nc ${deviceType}`}
        >
          <Logo fill="yellow" width={44} height={30} alt="Brand logo" />
        </AppBar>
      )}
      {deviceType === "desktop" && (
        <AppBar component="nav" position="fixed" className="app-bar-container">
          <Toolbar
            variant="dense"
            className="nav-bar-container"
            disableGutters={true}
          >
            <Button
              startIcon={
                // <img
                //   src={assets.home.homeLogo}
                //   width={44}
                //   height={30}
                //   alt="Brand logo"
                //   fill="yellow"
                // />
                <Logo fill="white" width={44} height={30} alt="Brand logo" />
              }
            />
            <Typography variant="h6" component="div" className="header-typo1" />
            <Box className="box-header-content">
              {NavOptions.loginHeaderOptions.map((item) => (
                <Button
                  key={item.title}
                  href={item.path}
                  className="header-nav-item"
                >
                  {item.title}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </div>
  );
}

export default Header;
