import { Box, Grid, Typography, Divider } from "@mui/material";
import React from "react";
import { ReactComponent as QuoteMarksIcon } from "../../../assets/images/Common/quotemarks-icon.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function QuoteAttributionImageCard({ theme, cardData }) {
  const { t } = useTranslation(i18next.language);
  const attributions = t(cardData.attribution).split("|");
  return (
    <Box className="quoteAttributionImageCard-container">
      <Grid container className="card-content-grid">
        <Grid item xs={6} className="left-item">
          <Box className="left-item-content">
            <QuoteMarksIcon className={`quote-icon ${theme}`} />
            <Typography className="quote-text">{t(cardData.quote)}</Typography>
            <Box className="card-attributions-container">
              {cardData.quoteBy && cardData.quoteBy !== "" && (
                <Typography className="quote-by">
                  {t(cardData.quoteBy)}
                </Typography>
              )}
              {cardData.attribution && (
                <Box className="attribution-items">
                  {attributions.map((attributionItem, index) => (
                    <React.Fragment key={`attributionItem${index}`}>
                      <Typography className={`attribution ${theme}`}>
                        {attributionItem}
                      </Typography>
                      {index !== attributions.length - 1 && (
                        <Divider
                          orientation="vertical"
                          className={`attribution-divider ${theme}`}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} className="right-item">
          <Box
            className="image-container"
            // sx={{
            //     backgroundImage: `url(${cardData.image})`
            // }}
          >
            <img alt="" src={cardData.image} className="card-image" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default QuoteAttributionImageCard;
