import { createSlice } from "@reduxjs/toolkit";

export const OccasionsTemplateSlice = createSlice({
    name: 'occasions',
    initialState: {
        occasionsData: {}
    },
    reducers: {
      setTemplateData: (state, action) => {
        state.occasionsData[action.payload.micro] = action.payload.data
      },
    }
  })
  
  // Action creators are generated for each case reducer function
  export const { setTemplateData } = OccasionsTemplateSlice.actions
  
  export default OccasionsTemplateSlice.reducer