import { downloadsAssets } from "../components/common/DownloadsAssets.js";
import { assets } from "../components/common/images";
import { occasionsAssets } from "../components/common/OccasionsAssets.js";
import RestaurantIcon from "../components/custom/Icons/RestaurantRoundedIcon.js";

export const occasionsMacroMapping = {
  Fram_OCCAS_11: ["Fram_OCCAS_14", "Fram_OCCAS_13", "Fram_OCCAS_12"],
  Fram_OCCAS_8: ["Fram_OCCAS_10", "Fram_OCCAS_9"],
  Fram_OCCAS_4: ["Fram_OCCAS_7", "Fram_OCCAS_5", "Fram_OCCAS_6"],
  Fram_OCCAS_1: ["Fram_OCCAS_3", "Fram_OCCAS_2"]
}

export const occasionsTempoLevelMacroMapping = {
  Fram_OCCAS_11: ["Fram_OCCAS_14", "Fram_OCCAS_13", "Fram_OCCAS_12"],
  Fram_OCCAS_8: ["Fram_OCCAS_10", "Fram_OCCAS_9"],
  Fram_OCCAS_4: ["Fram_OCCAS_7", "Fram_OCCAS_5", "Fram_OCCAS_6"],
  Fram_OCCAS_1: ["Fram_OCCAS_3", "Fram_OCCAS_2"]
}

export const occasionIcons = {
  Fram_OCCAS_12: <RestaurantIcon />,
  Fram_OCCAS_9: <RestaurantIcon />,
  Fram_OCCAS_10: <RestaurantIcon />,
  Fram_OCCAS_7: <RestaurantIcon />,
  Fram_OCCAS_3: <RestaurantIcon />,
};
//Occasions Template Anchor Nav Items
export const occasionsAnchorNavItems = [
  {
    key: "section2",
    text: "contextualFactors",
  },
  {
    key: "section3",
    text: "whatItLooksLike",
  },
  {
    key: "section4",
    text: "consumption",
  },
  {
    key: "section5",
    text: "demandSpaces",
  },
  {
    key: "section6",
    text: "wrapUp",
  },
  {
    key: "section7",
    text: "downloads",
  },
];

export const occasionsUGCItems = {
  Fram_OCCAS_2: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_2.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_2.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_2.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_2.gridLocations_Image2,
    },
    {
      type: "card",
      content: "meal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_2.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_2.gridMeals_Image2,
    // },
  ], //"Big Weekend Out"
  Fram_OCCAS_3: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_3.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_3.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_3.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_3.gridLocations_Image2,
    },
    {
      type: "card",
      content: "meal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_3.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_3.gridMeals_Image2,
    // },
  ], //"Lively Meal Out",
  Fram_OCCAS_5: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_5.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_5.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_5.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_5.gridLocations_Image2,
    },
    {
      type: "card",
      content: "nomeal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_5.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_5.gridMeals_Image2,
    // },
  ], //"Weekend Get-Together",
  Fram_OCCAS_6: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_6.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_6.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_6.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_6.gridLocations_Image2,
    },
    {
      type: "card",
      content: "nomeal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_6.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_6.gridMeals_Image2,
    // },
  ], //"Weekday Hang",
  Fram_OCCAS_7: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_7.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_7.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_7.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_7.gridLocations_Image2,
    },
    {
      type: "card",
      content: "meal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_7.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_7.gridMeals_Image2,
    // },
  ], //"Hosted Meal",
  Fram_OCCAS_9: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_9.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_9.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_9.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_9.gridLocations_Image2,
    },
    {
      type: "card",
      content: "meal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_9.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_9.gridMeals_Image2,
    // },
  ], //"Weekend Dining In",
  Fram_OCCAS_10: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_10.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_10.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_10.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_10.gridLocations_Image2,
    },
    {
      type: "card",
      content: "meal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_10.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_10.gridMeals_Image2,
    // },
  ],
  Fram_OCCAS_12: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_12.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_12.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_12.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_12.gridLocations_Image2,
    },
    {
      type: "card",
      content: "meal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_12.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_12.gridMeals_Image2,
    // },
  ], //"TV & Dinner",
  Fram_OCCAS_13: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_13.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_13.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_13.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_13.gridLocations_Image2,
    },
    {
      type: "card",
      content: "nomeal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_13.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_13.gridMeals_Image2,
    // },
  ], //"Unwinding Together",
  Fram_OCCAS_14: [
    {
      type: "card",
      content: "groupSize",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_14.gridGroup_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_14.gridGroup_Image2,
    },
    {
      type: "card",
      content: "location",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_14.gridLocations_Image1,
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_14.gridLocations_Image2,
    },
    {
      type: "card",
      content: "nomeal",
    },
    {
      type: "image",
      content: occasionsAssets.howTheyDrink.Fram_OCCAS_14.gridMeals_Image1,
    },
    // {
    //   type: "image",
    //   content: occasionsAssets.howTheyDrink.Fram_OCCAS_14.gridMeals_Image2,
    // },
  ], //"Me time"
};

export const occasionsContextualFactorsData = [
  "meal",
  "time",
  "location",
  "groupsize",
  "activities",
  "mood",
];

//old modular design
/* export const whatItLooksLikeModularContent = {
  Fram_OCCAS_2: [
    {
      cardType: "ImageCard",
      data: {
        image: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard1.attribution",
      },
    },

    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.modularContent.image2
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard4.name",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard4.attribution",
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard5.attribution",
      },
    }
  ], //"Big Weekend Out"
  Fram_OCCAS_3: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_3.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_3.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard5.attribution",
      },
    }
  ], //"Lively Meal Out",
  Fram_OCCAS_5: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_5.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_5.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard5.attribution",
      },
    }
  ], //"Weekend Get-Together",
  Fram_OCCAS_6: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_6.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_6.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard5.attribution",
      }
    }
  ], //"Weekday Hang",
  Fram_OCCAS_7: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_7.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_7.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard5.attribution",
      },
    },
  ], //"Hosted Meal",
  Fram_OCCAS_9: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_9.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_9.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard5.attribution",
      },
    },
  ], //"Weekend Dining In",
  Fram_OCCAS_10: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_10.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_10.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard5.attribution",
      },
    }
  ],
  Fram_OCCAS_12: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_12.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_12.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard5.attribution",
      },
    }
  ], //"TV & Dinner",
  Fram_OCCAS_13: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_13.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_13.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard5.attribution",
      },
    }
  ], //"Unwinding Together",
  Fram_OCCAS_14: [
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard1.attribution",
      },
    },
    {
      cardType: "StatementCard",
      data: {
        statement:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard2.stat",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_14.modularContent.image1,
      },
    },
    {
      cardType: "BigQuoteImageRightCard",
      data: {
        quoteattributioncarddata: {
          quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard4.qoute",
          name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard4.name",
          micro: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard4.micro",
          attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard4.attribution",
        },
        imagecarddata: {
          image:
            occasionsAssets.whatItLooksLike.Fram_OCCAS_14.modularContent.image2
        }
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard5.attribution",
      },
    }
  ], //"Me time"
};
*/
export const whatItLooksLikeModularContent = {
  Fram_OCCAS_2: [
    {
      cardType: "ImageCard",
      data: {
        image: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard1.attribution",
      },
    },

    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.modularContent.image2
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard4.attribution"
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_2.modularCard5.attribution",
      },
    }
  ], //"Big Weekend Out"
  Fram_OCCAS_3: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_3.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_3.modularContent.image2
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard4.attribution"
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_3.modularCard5.attribution",
      },
    }
  ], //"Lively Meal Out",
  Fram_OCCAS_5: [
    {
      cardType: "ImageCard",
      data: {
        image: occasionsAssets.whatItLooksLike.Fram_OCCAS_5.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_5.modularContent.image2
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard4.attribution",
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_5.modularCard5.attribution",
      },
    }
  ], //"Weekend Get-Together",
  Fram_OCCAS_6: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_6.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_6.modularContent.image2
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard4.attribution",
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_6.modularCard5.attribution",
      }
    }
  ], //"Weekday Hang",
  Fram_OCCAS_7: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_7.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_7.modularContent.image2
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard4.attribution",
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_7.modularCard5.attribution",
      },
    },
  ], //"Hosted Meal",
  Fram_OCCAS_9: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_9.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_9.modularContent.image2
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard4.attribution"
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_9.modularCard5.attribution",
      },
    },
  ], //"Weekend Dining In",
  Fram_OCCAS_10: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_10.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_10.modularContent.image2
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard4.attribution"
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_10.modularCard5.attribution",
      },
    }
  ],
  Fram_OCCAS_12: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_12.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_12.modularContent.image2
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard4.attribution",
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_12.modularCard5.attribution",
      },
    }
  ], //"TV & Dinner",
  Fram_OCCAS_13: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_13.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_13.modularContent.image2
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard4.attribution",
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_13.modularCard5.attribution",
      },
    }
  ], //"Unwinding Together",
  Fram_OCCAS_14: [
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_14.modularContent.image1,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard1.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard1.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard1.attribution",
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard3.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard3.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard3.attribution",
      },
    },
    {
      cardType: "ImageCard",
      data: {
        image:
          occasionsAssets.whatItLooksLike.Fram_OCCAS_14.modularContent.image2,
      },
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard4.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard4.name",
        attribution: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard4.attribution",
      }
    },
    {
      cardType: "QuoteAttributionCard",
      data: {
        quote:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard5.qoute",
        name: "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard5.name",
        attribution:
          "occasions.template.whatItLooksLike.modularContent.Fram_OCCAS_14.modularCard5.attribution",
      },
    }
  ], //"Me time"
};

export const occasionsWhatItLooksLikeExampleAudioData = {
  Fram_OCCAS_2: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_2.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_2.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_2.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_2.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_2.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_2.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_2.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Big Weekend Out"
  Fram_OCCAS_3: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_3.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_3.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_3.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_3.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_3.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_3.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_3.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_3.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_3.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_3.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_3.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_3.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Lively Meal Out",
  Fram_OCCAS_5: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_5.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_5.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_5.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_5.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_5.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_5.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_5.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_5.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_5.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_5.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_5.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_5.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Weekend Get-Together",
  Fram_OCCAS_6: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_6.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_6.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_6.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_6.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_6.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_6.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_6.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_6.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_6.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_6.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_6.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_6.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Weekday Hang",
  Fram_OCCAS_7: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_7.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_7.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_7.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_7.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_7.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_7.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_7.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_7.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_7.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_7.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_7.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_7.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Hosted Meal",
  Fram_OCCAS_9: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_9.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_9.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_9.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_9.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_9.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_9.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_9.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_9.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_9.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_9.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_9.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_9.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Weekend Dining In",
  Fram_OCCAS_10: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_10.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_10.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_10.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_10.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_10.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_10.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_10.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_10.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_10.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_10.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_10.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_10.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ],
  Fram_OCCAS_12: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_12.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_12.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_12.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_12.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_12.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_12.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_12.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_12.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_12.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_12.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_12.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_12.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"TV & Dinner",
  Fram_OCCAS_13: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_13.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_13.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_13.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_13.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_13.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_13.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_13.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_13.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_13.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_13.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_13.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_13.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Unwinding Together",
  Fram_OCCAS_14: [
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_14.example.carousel1,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_14.carousel1.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_14.example.carousel2,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_14.carousel2.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_14.example.carousel3,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_14.carousel3.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_14.example.carousel4,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_14.carousel4.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_14.example.carousel5,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_14.carousel5.description",
      subtitle: "",
      audioLink: null,
    },
    {
      media: occasionsAssets.whatItLooksLike.Fram_OCCAS_14.example.carousel6,
      title:
        "occasions.template.whatItLooksLike.example.Fram_OCCAS_14.carousel6.description",
      subtitle: "",
      audioLink: null,
    },
  ], //"Me time"
};

export const occasionsDemandSpaceMapping = {
  Fram_OCCAS_2: ["Fram_DS_1", "Fram_DS_3", "Fram_DS_9"],
  Fram_OCCAS_3: ["Fram_DS_1", "Fram_DS_4", "Fram_DS_9"],
  Fram_OCCAS_5: ["Fram_DS_1", "Fram_DS_5", "Fram_DS_7", "Fram_DS_10"],
  Fram_OCCAS_6: ["Fram_DS_1", "Fram_DS_5", "Fram_DS_7", "Fram_DS_10"],
  Fram_OCCAS_7: ["Fram_DS_1", "Fram_DS_5", "Fram_DS_7", "Fram_DS_10"],
  Fram_OCCAS_9: ["Fram_DS_2", "Fram_DS_12"],
  Fram_OCCAS_10: ["Fram_DS_2", "Fram_DS_13"],
  Fram_OCCAS_12: ["Fram_DS_2", "Fram_DS_6", "Fram_DS_8", "Fram_DS_11"],
  Fram_OCCAS_13: ["Fram_DS_2", "Fram_DS_6", "Fram_DS_8", "Fram_DS_11"],
  Fram_OCCAS_14: ["Fram_DS_2", "Fram_DS_6", "Fram_DS_8", "Fram_DS_11"],
};

export const OccasionsMenuOptions = [
  {
    header: "occasions.macroOccasion.Fram_OCCAS_11",
    listOfItems: [
      {
        itemText: "occasions.type.Fram_OCCAS_12",
        type: "Fram_OCCAS_12",
        routeParams: {
          macro: "Fram_OCCAS_11",
          micro: "Fram_OCCAS_12",
        },
      },
      {
        itemText: "occasions.type.Fram_OCCAS_13",
        type: "Fram_OCCAS_13",
        routeParams: {
          macro: "Fram_OCCAS_11",
          micro: "Fram_OCCAS_13",
        },
      },
      {
        itemText: "occasions.type.Fram_OCCAS_14",
        type: "Fram_OCCAS_14",
        routeParams: {
          macro: "Fram_OCCAS_11",
          micro: "Fram_OCCAS_14",
        },
      },
    ],
  },
  {
    header: "occasions.macroOccasion.Fram_OCCAS_8",
    listOfItems: [
      {
        itemText: "occasions.type.Fram_OCCAS_9",
        type: "Fram_OCCAS_9",
        routeParams: {
          macro: "Fram_OCCAS_8",
          micro: "Fram_OCCAS_9",
        },
      },
      {
        itemText: "occasions.type.Fram_OCCAS_10",
        type: "Fram_OCCAS_10",
        routeParams: {
          macro: "Fram_OCCAS_8",
          micro: "Fram_OCCAS_10",
        },
      },
    ],
  },
  {
    header: "occasions.macroOccasion.Fram_OCCAS_4",
    listOfItems: [
      {
        itemText: "occasions.type.Fram_OCCAS_6",
        type: "Fram_OCCAS_6",
        routeParams: {
          macro: "Fram_OCCAS_4",
          micro: "Fram_OCCAS_6",
        },
      },
      {
        itemText: "occasions.type.Fram_OCCAS_5",
        type: "Fram_OCCAS_5",
        routeParams: {
          macro: "Fram_OCCAS_4",
          micro: "Fram_OCCAS_5",
        },
      },
      {
        itemText: "occasions.type.Fram_OCCAS_7",
        type: "Fram_OCCAS_7",
        routeParams: {
          macro: "Fram_OCCAS_4",
          micro: "Fram_OCCAS_7",
        },
      },
    ],
  },
  {
    header: "occasions.macroOccasion.Fram_OCCAS_1",
    listOfItems: [
      {
        itemText: "occasions.type.Fram_OCCAS_2",
        type: "Fram_OCCAS_2",
        routeParams: {
          macro: "Fram_OCCAS_1",
          micro: "Fram_OCCAS_2",
        },
      },
      {
        itemText: "occasions.type.Fram_OCCAS_3",
        type: "Fram_OCCAS_3",
        routeParams: {
          macro: "Fram_OCCAS_1",
          micro: "Fram_OCCAS_3",
        },
      },
    ],
  },
];

export const OccasionTooltipCardData = {
  page: "occasionsFramework",
  tooltipData: [
    {
      label: "full-screen-experience-img",
      imgPath: assets.home.fullScreenExperienceImg,
      title: "home.modal.fullScreenExperienceTitle",
      description: "home.modal.fullScreenExperienceDescription",
    },
    {
      label: "quick-link-img",
      imgPath: assets.tooltip.quickLinkImg,
      title: "common.popupCard.quickLinkTitle",
      description: "common.popupCard.quickLinkDescription",
    },
    {
      label: "category-img",
      imgPath: assets.tooltip.categorySizingImg,
      title: "common.popupCard.categorySizingTitle",
      description: "common.popupCard.categorySizingDescription",
    },
    {
      label: "keydrivers-img",
      imgPath: assets.tooltip.keyDriversImg,
      title: "common.popupCard.keyDriversTitle",
      description: "common.popupCard.keyDriversDescription",
    },
    {
      label: "download-img",
      imgPath: assets.tooltip.downloadsImg,
      title: "common.popupCard.downloadsTitle",
      description: "common.popupCard.downloadsDescription",
    },
    {
      label: "expanding-img",
      imgPath: assets.tooltip.expandingDataImg,
      title: "common.popupCard.expandingDataTitle",
      description: "common.popupCard.expandingDataDescription",
    },
  ],
};

export const occasionsKeyElementsCardData = [
  {
    title: "occasions.hub.whatDefinesAnOccasion.keyElements.cards.eating.title",
    icon: "eating",
    description:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.eating.description",
  },
  {
    title:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.activity.title",
    icon: "activity",
    description:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.activity.description",
  },
  {
    title:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.location.title",
    icon: "location",
    description:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.location.description",
  },
  {
    title: "occasions.hub.whatDefinesAnOccasion.keyElements.cards.time.title",
    icon: "time",
    description:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.time.description",
  },
  {
    title: "occasions.hub.whatDefinesAnOccasion.keyElements.cards.group.title",
    icon: "group",
    description:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.group.description",
  },
  {
    title: "occasions.hub.whatDefinesAnOccasion.keyElements.cards.mood.title",
    icon: "mood",
    description:
      "occasions.hub.whatDefinesAnOccasion.keyElements.cards.mood.description",
  },
];

export const occasionsMacroOccasionsCardData = [
  {
    title:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.relaxing.title",
    icon: "relaxing",
    description:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.relaxing.description",
  },
  {
    title:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.dailydining.title",
    icon: "dailydining",
    description:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.dailydining.description",
  },
  {
    title:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.socializing.title",
    icon: "socializing",
    description:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.socializing.description",
  },
  {
    title:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.goingout.title",
    icon: "goingout",
    description:
      "occasions.hub.whatDefinesAnOccasion.macroOccasions.cards.goingout.description",
  },
];

export const occasionsTemplateDownloadItems = {
  Fram_OCCAS_2: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //Big Weekend Out
  Fram_OCCAS_3: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //Lively Meal Out
  Fram_OCCAS_5: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //"Weekend Get-Together",
  Fram_OCCAS_6: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_2.BWO_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //"Weekday Hang",
  Fram_OCCAS_7: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_7.HM_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_7.HM_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //"Hosted Meal",
  Fram_OCCAS_9: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_9.WDI_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_9.WDI_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //"Weekend Dining In",
  Fram_OCCAS_10: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_10.WM_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_10.WM_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //Weekday Meal
  Fram_OCCAS_12: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_12.TVD_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_12.TVD_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //"TV & Dinner",
  Fram_OCCAS_13: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_13.UT_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_13.UT_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //"Unwinding Together",
  Fram_OCCAS_14: [
    {
      title: "common.downloads.occasions.microProfile",
      subtitle: "common.downloads.occasions.microProfileSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_14.MT_Profile_PDF,
        ppt: downloadsAssets.occasionTemplateDownloads.Fram_OCCAS_14.MT_Profile_PPT
      }
    },
    {
      title: "common.downloadOccasions",
      subtitle: "common.downloadOccasionsSubtitle",
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.occasionTemplateDownloads.common.occasionProfilePPT,
        ppt: downloadsAssets.occasionTemplateDownloads.common.occasionsProfilePDF
      }
    },
    {
      title: "common.downloads.occasions.fullDataSpreadsheet",
      subtitle: "common.downloads.occasions.fullDataSpreadsheetSubtitle",
      multipleFiles: false,
      fileToDownload: downloadsAssets.occasionTemplateDownloads.common.occasionsDataSpreadsheet
    },
  ], //"Me time"
};

export const occasionsHubDownloadItems = [
  {
    title: 'common.downloadOccasions',
    subtitle: 'common.downloadOccasionsSubtitle',
    multipleFiles: true,
    fileToDownload: {
      pdf: downloadsAssets.commonDownloads.occasionsProfilePDF,
      ppt: downloadsAssets.commonDownloads.occasionProfilePPT
    }
  },
  {
    title: 'common.downloadDataPack',
    subtitle: 'common.downloadDataPackOccasions',
    multipleFiles: false,
    fileToDownload: downloadsAssets.commonDownloads.DataPackSpreadsheet
  }
]
