import React from "react";
import { ReactComponent as ArrowBack } from "../../../assets/images/Common/arrow-back.svg";
import { Box } from "@mui/material";

function DividerWithArrows() {
  return (
    <Box className="divider-container">
      <ArrowBack className="arrow-back-icon" />
      <Box className="divider-line" />
      <ArrowBack className="arrow-forward-icon" />
    </Box>
  );
}

export default DividerWithArrows;
