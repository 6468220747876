/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import Runner from "../custom/Runner";
import SectionFive from "./SectionFive";
import i18next from "i18next";
import HomeModal from "./HomeModal";
import { useNavigate } from "react-router-dom";
import { getInfoPopupStatus } from "../../service/API";
import { useMsal } from "@azure/msal-react";
import { HomeTooltipCardData } from "../../constants/constants";

function HomePage() {
  const navigate = useNavigate();
  const [popupStatus, setPopupStatus] = useState(true)
  const { accounts } = useMsal();

  const termsAccepted = window.sessionStorage.getItem("tncAccepted") === "true";

  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
    fetchPopupData()
  }, []);

  const fetchPopupData = async () => {
    try {
      if(!termsAccepted) {
        navigate("/terms", { replace: true });
        window.scrollTo(0, 0)
      }
      const response = await getInfoPopupStatus(accounts[0].username);
      setPopupStatus(response.home);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  return (
    <div className="main-content home-page">
     {
      !popupStatus && 
      <HomeModal CardData={HomeTooltipCardData} />
     }
      <div className="content">
        <SectionOne />
      </div>
      <SectionTwo />
      {/* <div className="content"> */}
      <SectionThree />
      <SectionFour />
      <SectionFive />
      {/* </div> */}
      <div className="runner">
        <Runner forPage={"homepage"} />
      </div>
    </div>
  );
}

export default HomePage;
