import React from 'react'

import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function Runner({ forPage, forType }) {
  const { t } = useTranslation(i18next.language)

  const textElements = t(`runner.${forPage}${forType ? `.${forType}` : ''}`,{ returnObjects: true })

  return (
    <div className={`runner-container ${forPage} ${forType}`}>
      <div className="runner-text">
      {/* <span class="moving-text">{textElements.join(" ")}</span> */}
        <div>
          {
            textElements.map((item,index) => (
              <span key={`text${index}`}>{ item }</span>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Runner