import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Box,
  MobileStepper,
  IconButton,
  CardActions,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { submitInfoPopupStatus } from "../../../service/API";
import { useMsal } from "@azure/msal-react";

function ToolTipPopUp({ CardData, closeHandler }) {
  const [open, setOpen] = useState(true);
  const { accounts } = useMsal()

  const tooltipData = CardData.tooltipData;
  const page = CardData.page;

  // Function to handle modal close
  const handleClose = () => {
    
    // window.sessionStorage.setItem(`${page}TooltipLoaded`, true);
    if (closeHandler) {
      closeHandler(false);
    }
    else{
      popupStatusApi()
    }
    setOpen(false);
  };
  function handleSkip() {
    if (closeHandler) {
      closeHandler(false);
    }
    setOpen(false);
  }

  async function popupStatusApi() {
    let popupData = {
      userId: accounts[0].username,
      pageName: page
    }
    try {
      await submitInfoPopupStatus(popupData);

    } catch (error) {
      console.error("API Error:", error);
    }
  }

  const { t } = useTranslation(i18next.language);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeCardData, setActiveCardData] = React.useState(tooltipData[0]);
  const maxSteps = tooltipData.length;

  const handleNext = () => {
    // if (activeStep === maxSteps - 1) {
    //     setActiveStep(0);
    //     setActiveCardData(tooltipData[0]);
    // } else if (activeStep < maxSteps) {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setActiveCardData(tooltipData[activeStep + 1])
    // }
    if (activeStep < maxSteps - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setActiveCardData(tooltipData[activeStep + 1]);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setActiveCardData(tooltipData[activeStep - 1]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: "500px",
          height: "500px",
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent
        sx={{ background: "#fff", padding: 0 }}
        className="dialog-content"
      >
        <Box className="card-content-box" sx={{ flexGrow: 1 }}>
          <Card sx={{ width: "100%", borderRadius: 0 }} className="card">
            <CardMedia
              component="img"
              height="275"
              width="100%"
              image={activeCardData.imgPath}
              alt={activeCardData.label}
            />
            <CardContent className="card-text-container">
              <Typography variant="h5" className="card-title-text">
                {t(activeCardData.title)}
              </Typography>
              <Typography variant="body2" className="card-description-text">
                {t(activeCardData.description)}
              </Typography>
            </CardContent>
            <CardActions className="popup-stepper-container">
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <KeyboardArrowRight
                    onClick={handleNext}
                    sx={{ fill: "#000000" }}
                  />
                }
                backButton={
                  <KeyboardArrowLeft
                    onClick={handleBack}
                    sx={{ fill: "#000000" }}
                  />
                }
                sx={{ padding: "20px" }}
                classes={{
                  dotActive: "blackDot",
                }}
              />
              {activeStep === maxSteps - 1 ? (
                <Button size="small" onClick={handleClose} className="next-btn">
                  {t("buttons.toolTipFinish")}
                </Button>
              ) : (
                <Button size="small" onClick={handleSkip} className="next-btn">
                  {t("buttons.toolTipSkip")}
                </Button>
              )}
            </CardActions>
          </Card>
          {/* <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                className='next-btn'
                            >{t('buttons.toolTipNext')}</Button>
                        }
                        sx={{ padding: "20px" }}
                        classes={{
                            dotActive: 'blackDot'
                    }} />      */}
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="icon-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

export default ToolTipPopUp;
