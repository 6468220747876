//import { BlockBlobClient } from '@azure/storage-blob'

function getDefaultHeaders() {
    let headers = new Headers();

    headers.append('Content-type', 'application/json; charset=UTF-8');
    headers.append('Accept', 'application/json');
    headers.append('XApiKey', process.env.REACT_APP_API_TOKEN);

    return headers
}

export function getAssetsSASToken(){
    fetch(process.env.REACT_APP_ASSETS_API, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: getDefaultHeaders(),
    })
    .then((response) => response.json())
    .then((data) => {
        localStorage.setItem('sasKey',data.sasKey)
        localStorage.setItem('storageUrl',data.storageUrl)
        localStorage.setItem('expiry',data.expiry)
    })
    .catch((err) => {
        console.log(err.message);
    });
}

export async function fetchPeopleSegmentData(microSegment) {
    let url = process.env.REACT_APP_PEOPLESEGMENTS_API + microSegment
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: getDefaultHeaders()
    })
    .then((response) => response.json())
}

export async function fetchOccasionsData(microOccasion) {
    let url = process.env.REACT_APP_OCCASIONS_API + microOccasion
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: getDefaultHeaders()
    })
    .then((response) => response.json())
}

export async function fetchDemandSpaceData(microDemandSpace) {
    let url = process.env.REACT_APP_DEMANDSPACES_API + microDemandSpace
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: getDefaultHeaders()
    })
    .then((response) => response.json())
}

export async function submitFeedback(requestBody) {
    let url = process.env.REACT_APP_FEEDBACK_API
    return fetch(url, {
        method: 'POST',
        headers: getDefaultHeaders(),
        body: JSON.stringify(requestBody)
    })
    .then((response) => response.json())
}

export async function getInfoPopupStatus(userId) {
    let url = process.env.REACT_APP_POPUP_API + `userId=${userId}`
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: getDefaultHeaders(),
    })
    .then((response) => response.json())
}

export async function submitInfoPopupStatus(requestBody) {
    let url = process.env.REACT_APP_POPUP_API 
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'POST',
        headers: getDefaultHeaders(),
        body: JSON.stringify(requestBody)
    })
    .then((response) => response.json())
    .catch((err)=> console.error(err.message))
}

/*export async function downloadFile(filename) {
    const file = filename.split('?')[0].split('/').pop()
    
    // Create a blob client from SAS token
    const client = new BlockBlobClient(filename);

    // Get blob url
    console.log(`blob.url: ${client.url}`);

    // Download file contents
    try {
        const downloadResponse = await client.download()
        console.log(`Received download file response: ${JSON.stringify(downloadResponse._response)}`)
    } catch(err) {
        console.log(`Error downloading file: ${JSON.stringify(err)}`)
    }
}
export async function downloadAllFiles() {
    let url = 'https://abi-dev-web-api.azurewebsites.net/api/ExportDataPack?fileName=PeopleSegment';
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: getDefaultHeaders(),
    })
    .then((response) => {console.log(response); return response;})

    async function blobToString(blob) {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
          fileReader.onloadend = (ev) => {
            resolve(ev.target.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsText(blob);
        });
      }
}*/