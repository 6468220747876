import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import DownloadResources from '../../common/page-sections/DownloadResources'
import { occasionsTemplateDownloadItems } from '../../../constants/OccasionsConstants'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function SectionSeven() {
    const { micro } = useParams()
    const { t } = useTranslation(i18next.language)
    useEffect(() => {
        if(localStorage.getItem('i18nnextLng')?.length >2) {
          i18next.changeLanguage('en')
        }
      },[])

    const items = occasionsTemplateDownloadItems[micro]
    const downloadItems = items.map((item) => {
        return (
            {
                title: t(item.title, { micro: t(`occasions.type.${micro}`) }),
                subtitle: t(item.subtitle, { micro: t(`occasions.type.${micro}`) }),
                multipleFiles: item.multipleFiles,
                fileToDownload: item.fileToDownload
            }
        )
    })
    
    return (
        <div className='section7'>
            <Box sx={{ flexGrow: 1}}>
                <DownloadResources listItems={downloadItems} />
            </Box>
        </div>
    )
}

export default SectionSeven