import React from 'react'
import { 
    Card,
    CardContent,
    Typography
} from '@mui/material'


function UGCCard(props) {
  return (
    <Card className='ugc-card-container'>
      <CardContent className='ugc-card-content'>
        { props.children }
        <Typography variant="h5" component="div"
            className='ugc-card-label'>
          { props.label }
        </Typography>
        <Typography variant='h5' component="div"
            className='ugc-card-labeltext'>
          { props.labeltext }
        </Typography>
      </CardContent>
    </Card>
  )
}

export default UGCCard