import React, { useEffect } from "react";

import i18next from "i18next";
import { useParams } from "react-router-dom";
import PeopleSegmentWrapup from "../../common/page-sections/Wrap-up/PeopleSegmentWrapup";

const SectionSeven = ({ sectionData }) => {
  const { macroSegment, microSegment } = useParams();
  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  return (
    <div className="section7">
      <PeopleSegmentWrapup 
        space={microSegment} 
        selectedMacro={macroSegment} 
        sectionData={sectionData}/>;
    </div>
  );
};

export default SectionSeven;
