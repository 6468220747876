import React, { useEffect, useState } from "react";
import DemandSpaces from "../../common/page-sections/DemandSpaces";
import { useParams } from "react-router-dom";

export default function SectionSix({ sectionData, space }) {
  const { macroSegment, microSegment } = useParams();
  const [demandSpaceData, setDemandspaceData] = useState({});

  useEffect(() => {
    let data = {};
    for (let i = 0; i < sectionData.length; i++) {
      data[sectionData[i].microCode] = parseFloat(
        sectionData[i].volume
      ).toFixed(1);
    }
    setDemandspaceData(data);
  }, [sectionData]);

  return (
    <div className="section6">
      <DemandSpaces
        page="people-segment"
        macro={macroSegment}
        micro={microSegment}
        data={demandSpaceData}
        space={space}
      />
    </div>
  );
}
