import React from "react";
import { Grid, Divider, Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import WrapUpHero from "./WrapUpHero";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BeerIcon from "../../../../assets/images/Common/consumption-cards/BeerWrapUp.svg";
import SpiritsIcon from "../../../../assets/images/Common/consumption-cards/SpiritsWrapUp.svg";
import WineIcon from "../../../../assets/images/Common/consumption-cards/WineWrapUp.svg";
import HardbevIcon from "../../../../assets/images/Common/consumption-cards/HardBevWrapUp.svg";
// import {
//   functionalNeedkey,
//   emotionalNeedKey,
// } from "../../../../constants/DemandSpace";
import { demandSpaceAssets } from "../../DemandSpaceAssets";

// function WrapUpUGC() {
//   return (
//     <Box className="wrapup-ugc-container">
//       <Box className="ugc-text">
//         <Typography className="ugc-title"></Typography>
//         <Typography className="ugc-description"></Typography>
//       </Box>
//     </Box>
//   );
// }

function DemandSpacesWrapUp({ macro, selectedMicro }) {
  const { t } = useTranslation(i18next.language);
  const allDrivers = [
    ...macro?.drivers?.functionalNeeds,
    ...macro?.drivers?.emotionalNeeds,
  ];

  allDrivers.sort(
    (a, b) => parseFloat(b.drinkPercent) - parseFloat(a.drinkPercent)
  );

  const sovVolume = Math.round(parseFloat(macro?.totalVolume));
  const sovVolumeRoundUp = JSON.stringify(sovVolume, null, 2);

  const sopVolume = Math.round(parseFloat(macro?.populationVolume));
  const sopVolumeRoundUp = JSON.stringify(sopVolume, null, 2);

  const wrapupContent = {
    title: t("demandspace.anchorItems.wrapUp"),
    micro: t(`demandspace.type.${selectedMicro}`),
    sov: sovVolumeRoundUp,
    sop: sopVolumeRoundUp,
    microDescription: t(
      `demandspace.template.wrapup.demandSpaceDescription.${selectedMicro}.paragraph`,
      {
        type: t(`demandspace.type.${selectedMicro}`),
      }
    ),
    microImage: demandSpaceAssets.heroImages[selectedMicro],
    visualText: t(
      `demandspace.template.peopleSegmentandOccasions.${selectedMicro}.partOfMacro`,
      {
        type: t(`demandspace.type.${selectedMicro}`),
        macroOccasion: t(`demandspace.macroOccasion.${selectedMicro}`),
      }
    ),
    microID: selectedMicro
  };

  // const emotionalNeedDescription = t(
  //   `demandspace.template.driversAndNeeds.needs.emotionalNeeds.${selectedMicro}`,
  //   {
  //     returnObjects: true,
  //   }
  // );

  // const functionalNeedDescription = t(
  //   `demandspace.template.driversAndNeeds.needs.functionalNeeds.${selectedMicro}`,
  //   {
  //     returnObjects: true,
  //   }
  // );

  function getBeverageIcon(type) {
    let icon = null;
    switch (type) {
      case "Fram_CBR_1":
        icon = BeerIcon;
        break;
        case "Fram_CS_1":
        icon = SpiritsIcon;
        break;
        case "Fram_CW_1":
        icon = WineIcon;
        break;
        case "Fram_CHB_1":
        icon = HardbevIcon;
        break;
      default:
        break;
    }
    return icon;
  }
  // const topDrivers = allDrivers.filter(
  //   (item) =>
  //     emotionalNeedDescription[emotionalNeedKey[item?.emotionalNeedKey]] ||
  //     functionalNeedDescription[functionalNeedkey[item?.functionalNeedKey]]
  // );

  // function navigateToSection(id) {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //       inline: "start",
  //     });
  //   }
  // }

  function getBackgroundColor(index) {
    if (index <= 85) {
      return "#CB4242"; // Red color
    } else if (index >= 115) {
      return "#309427"; // Green color
    } else {
      return "#434343"; // Neutral color
    }
  }

  function formatDrinkName(drinkName) {
    return drinkName
      .replace(/beverage/i, "bev")
      .replace(/\s*\(NET\)/, "")
      .replace(/\s*\+ NAB/, "");
  }

  return (
    <Box className="wrapup-main">
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12.5
      }}>
        <Divider className="divider" />
        <Box className="wrapup-intro">
          {/* <Divider className="divider" /> */}
          <WrapUpHero content={wrapupContent} />
        </Box>
      </Box>
      <Grid container gap={7.5} className="main-section4-casual">
        {/* <Grid item xs={5.65} className="left-item">
          <Box className="box">
            <Box className="heading">
              <Typography className="top-needs">
                {t("people-segment.section7.section4-casual.topneeds")}
              </Typography>
              <Box className="vol-index">
                <Typography>
                  {t("people-segment.section7.section4-casual.vol-per")}
                </Typography>
                <Typography>
                  {t("people-segment.section7.section4-casual.index")}
                </Typography>
              </Box>
            </Box>

            <Grid container className="top-drivers-container" gap={2.5}>
              {topDrivers.slice(0, 3).map((item, index) => (
                <Grid item xs={12}>
                  <Box className="row-content">
                    <Box className="row-text">
                      <Typography className="head-text">
                        {item?.functionalNeed || item?.emotionalNeed}
                      </Typography>
                      <Typography className="head-sub-text">
                        {emotionalNeedDescription[
                          emotionalNeedKey[item?.emotionalNeedKey]
                        ] ||
                          functionalNeedDescription[
                            functionalNeedkey[item?.functionalNeedKey]
                          ]}
                      </Typography>
                    </Box>
                    <Box className="vol-index">
                      <Typography className="vol-per">
                        {Math.round(item?.drinkPercent)}%
                      </Typography>
                      <Typography
                        className="index"
                        style={{
                          backgroundColor: getBackgroundColor(
                            Math.round(item?.drinkIndex)
                          ),
                        }}
                      >
                        {Math.round(item?.drinkIndex)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid> */}
        <Grid item xs={12} className="right-item">
          <Box className="box">
            <Typography className="top-needs">
              {t("people-segment.section7.section4-casual.alc-bev-cat")}
            </Typography>
            <Grid container gap={1.5}>
              {macro?.consumptions?.drinks?.map((item, index) => (
                <Grid item xs={2.85} key={index}>
                  <Grid container gap={2.5} className="right-grid">
                    <Grid item xs={3.35}>
                      <Box className="icon-img">
                        <img
                          className="icon"
                          src={getBeverageIcon(item?.drinkIdentifier)}
                          alt="icon-text"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={7.35}>
                      <Box className="icon-text-margin">
                        <Typography className="icon-text">
                          {formatDrinkName(item?.drinkName).toLowerCase()}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2.5 }}>
                          <Typography className="vol-per">
                            {Math.round(item?.drinkVolume)}%
                          </Typography>
                          <Typography
                            className="index"
                            style={{
                              backgroundColor: getBackgroundColor(
                                Math.round(item?.drinkIndex)
                              ),
                            }}
                          >
                            {Math.round(item?.drinkIndex)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        {/* </div> */}
      </Grid>
      <Box className="wrapup-interaction-tooltip">
        <QueryStatsIcon className="icon" color="primary" />
        <Typography
          variant="body1"
          color="secondary"
          className="interaction-tooltip-text"
          // onClick={() => navigateToSection("section8")}
        >
          <Trans components={{ underline: <i /> }}>
            {t("demandspace.template.wrapup.interactionTooltipText")}
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
}

export default DemandSpacesWrapUp;
