//Common download sheets

//An overview of the Demand Landscape as a whole
const demandLandscapeIntroDeck = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/ExcelExport/DemandLandscapeIntro.pdf?${localStorage.getItem("sasKey")}`;

//A guide to all People Segments
const peopleSegmentsProfilePDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/PeopleSegments.pdf?${localStorage.getItem("sasKey")}`;

const peopleSegmentsProfilePPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/PeopleSegments.ppt?${localStorage.getItem("sasKey")}`;

//A guide to all Occasions
const occasionsProfilePDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/OccasionsFramework.pdf?${localStorage.getItem("sasKey")}`;

const occasionProfilePPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/OccasionsFramework.ppt?${localStorage.getItem("sasKey")}`;

//A guide to all Demand Spaces
const demandSpaceProfilesPDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/DemandLandscape.pdf?${localStorage.getItem("sasKey")}`;

const demandSpaceProfilesPPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/DemandLandscape.ppt?${localStorage.getItem("sasKey")}`;

//Full data set for total Demand Landscape
const DataPackSpreadsheet = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/ExcelExport/DL_OCG_Data Pack.xlsx?${localStorage.getItem("sasKey")}`;

//Full data set for People Segments
const peopleSegmentDataSpreadsheet = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/PeopleSegments_DataPack.xlsx?${localStorage.getItem("sasKey")}`;

//Full data set for Demand Spaces
const demandspaceTemplateDataSpreadsheet = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/ExcelExport/DL_OCG_Data Pack.xlsx?${localStorage.getItem("sasKey")}`;

//Full data set for Occasions
const occasionsDataSpreadsheet = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/ExcelExport/DL_OCG_Data Pack.xlsx?${localStorage.getItem("sasKey")}`;

// People Segment Downloads
//Comfort Cravers
// const CC_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const CC_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/ComfortCravers.pdf?${localStorage.getItem("sasKey")}`;

const CC_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/ComfortCravers.ppt?${localStorage.getItem("sasKey")}`;

//Loyal Traditionalists
// const LT_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const LT_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/LoyalTradionalists.pdf?${localStorage.getItem("sasKey")}`;

const LT_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/LoyalTradionalists.ppt?${localStorage.getItem("sasKey")}`;

//Balanced Enjoyers
// const BE_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const BE_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/BalancedEnjoyers.pdf?${localStorage.getItem("sasKey")}`;

const BE_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/BalancedEnjoyers.ppt?${localStorage.getItem("sasKey")}`;

//Diligent Discoverers
// const DD_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const DD_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/DiligentDiscoverers.pdf?${localStorage.getItem("sasKey")}`;

const DD_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/DiligentDiscoverers.ppt?${localStorage.getItem("sasKey")}`;

//Care Free Fun-Lovers
// const CFL_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const CFL_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/CarefreeFunLovers.pdf?${localStorage.getItem("sasKey")}`;

const CFL_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/CarefreeFunLovers.ppt?${localStorage.getItem("sasKey")}`;

//Proud Celebrators
// const PC_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const PC_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/ProudCelebrators.pdf?${localStorage.getItem("sasKey")}`;

const PC_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/ProudCelebrators.ppt?${localStorage.getItem("sasKey")}`;

//Creative Explorers
// const CE_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const CE_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/CreativeExplorers.pdf?${localStorage.getItem("sasKey")}`;

const CE_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/CreativeExplorers.ppt?${localStorage.getItem("sasKey")}`;

//Social Curators
// const SC_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const SC_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/SocialCurators.pdf?${localStorage.getItem("sasKey")}`;

const SC_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/people-segment/SocialCurators.ppt?${localStorage.getItem("sasKey")}`;

// Demand Space Template Downloads

//Casual Good Times
// const CGT_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const CGT_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/CasualGoodTimes.pdf?${localStorage.getItem("sasKey")}`;

const CGT_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/CasualGoodTimes.ppt?${localStorage.getItem("sasKey")}`;

//Easy Going Hangout
// const EGH_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const EGH_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/EasyGoingHangout.pdf?${localStorage.getItem("sasKey")}`;

const EGH_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/EasyGoingHangout.ppt?${localStorage.getItem("sasKey")}`;

//Routine Living
// const RL_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const RL_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/RoutineLiving.pdf?${localStorage.getItem("sasKey")}`;

const RL_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/RoutineLiving.ppt?${localStorage.getItem("sasKey")}`;

//Energetic Night Out
// const ENO_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const ENO_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/EnergeticNightOut.pdf?${localStorage.getItem("sasKey")}`;

const ENO_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/EnergeticNightOut.ppt?${localStorage.getItem("sasKey")}`;

//Upbeat Social Meal
// const USM_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const USM_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/UpbeatSocialMeal.pdf?${localStorage.getItem("sasKey")}`;

const USM_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/UpbeatSocialMeal.ppt?${localStorage.getItem("sasKey")}`;

//Simple Unplug
// const SU_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const SU_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/SimpleUnplug.pdf?${localStorage.getItem("sasKey")}`;

const SU_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/SimpleUnplug.ppt?${localStorage.getItem("sasKey")}`;

//Lively Social Gathering
// const LSG_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const LSG_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/LivelySocialGathering.pdf?${localStorage.getItem("sasKey")}`;

const LSG_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/LivelySocialGathering.ppt?${localStorage.getItem("sasKey")}`;

//Intentional Unwind
// const IU_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const IU_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/IntentionalUnwind.pdf?${localStorage.getItem("sasKey")}`;

const IU_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/IntentionalUnwind.ppt?${localStorage.getItem("sasKey")}`;

//Refined Weekend Out
// const RWO_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const RWO_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/RefinedWeekendOut.pdf?${localStorage.getItem("sasKey")}`;

const RWO_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/RefinedWeekendOut.ppt?${localStorage.getItem("sasKey")}`;

//Thoughtful Connection
// const TC_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const TC_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/ThoughtfulConnection.pdf?${localStorage.getItem("sasKey")}`;

const TC_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/ThoughtfulConnection.ppt?${localStorage.getItem("sasKey")}`;

//Sensible Slowdown
// const SS_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const SS_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/SensibleSlowdown.pdf?${localStorage.getItem("sasKey")}`;

const SS_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/SensibleSlowdown.ppt?${localStorage.getItem("sasKey")}`;

//Intimate Dining In
// const IDI_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const IDI_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/IntimateDiningIn.pdf?${localStorage.getItem("sasKey")}`;

const IDI_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/IntimateDiningIn.ppt?${localStorage.getItem("sasKey")}`;

//Everyday Meal
// const EM_HeroVideo = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/ExcelExport/PeopleSegments.xlsx?${localStorage.getItem("sasKey")}`;

const EM_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/EverydayMeal.pdf?${localStorage.getItem("sasKey")}`;

const EM_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/demandspace/EverydayMeal.ppt?${localStorage.getItem("sasKey")}`;

//Occasions Template Downloads

//Big Weekend Out

const BWO_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/BigWeekendOut.pdf?${localStorage.getItem("sasKey")}`;

const BWO_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/BigWeekendOut.pptx?${localStorage.getItem("sasKey")}`;

//Lively Meal Out

const LMO_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/LivelyMealOut.pdf?${localStorage.getItem("sasKey")}`;

const LMO_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/LivelyMealOut.pptx?${localStorage.getItem("sasKey")}`;

//Weekend Get-Together

const WGT_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekendGetTogether.pdf?${localStorage.getItem("sasKey")}`;

const WGT_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekendGetTogether.pptx?${localStorage.getItem("sasKey")}`;

//Weekday Hang

const WH_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekdayHang.pdf?${localStorage.getItem("sasKey")}`;

const WH_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekdayHang.pptx?${localStorage.getItem("sasKey")}`;

//Hosted Meal

const HM_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/HostedMeal.pdf?${localStorage.getItem("sasKey")}`;

const HM_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/HostedMeal.pptx?${localStorage.getItem("sasKey")}`;

//Weekend Dining In

const WDI_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekendDiningIn.pdf?${localStorage.getItem("sasKey")}`;

const WDI_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekendDiningIn.pptx?${localStorage.getItem("sasKey")}`;

//Weekday Meal

const WM_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekdayMeal.pdf?${localStorage.getItem("sasKey")}`;

const WM_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/WeekdayMeal.pptx?${localStorage.getItem("sasKey")}`;

//TV and Dinner

const TVD_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/TVDinner.pdf?${localStorage.getItem("sasKey")}`;

const TVD_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/TVDinner.pptx?${localStorage.getItem("sasKey")}`;

//Unwinding Together

const UT_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/UnwindingTogether.pdf?${localStorage.getItem("sasKey")}`;

const UT_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/UnwindingTogether.pptx?${localStorage.getItem("sasKey")}`;

//Me Time

const MT_Profile_PDF = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/MeTime.pdf?${localStorage.getItem("sasKey")}`;

const MT_Profile_PPT = `${
  process.env.REACT_APP_AZURE_STORAGE_URL
}/Downloads/occasions/MeTime.pptx?${localStorage.getItem("sasKey")}`;

export const downloadsAssets = {
  commonDownloads: {
    demandLandscapeIntroDeck: demandLandscapeIntroDeck,
    DataPackSpreadsheet: DataPackSpreadsheet,
    peopleSegmentsProfilePDF: peopleSegmentsProfilePDF,
    peopleSegmentsProfilePPT: peopleSegmentsProfilePPT,
    demandSpaceProfilesPDF: demandSpaceProfilesPDF,
    demandSpaceProfilesPPT: demandSpaceProfilesPPT,
    occasionProfilePPT: occasionProfilePPT,
    occasionsProfilePDF: occasionsProfilePDF,
  },
  peopleSegmentTemplateDownloads: {
    common: {
      peopleSegmentsProfilePDF: peopleSegmentsProfilePDF,
      peopleSegmentsProfilePPT: peopleSegmentsProfilePPT,
      peopleSegmentDataSpreadsheet: peopleSegmentDataSpreadsheet,
    },
    Fram_PSGMT_2: {
      //CC_HeroVideo: CC_HeroVideo,
      CC_Profile_PDF: CC_Profile_PDF,
      CC_Profile_PPT: CC_Profile_PPT,
    },
    Fram_PSGMT_3: {
      //LT_HeroVideo: LT_HeroVideo,
      LT_Profile_PDF: LT_Profile_PDF,
      LT_Profile_PPT: LT_Profile_PPT,
    },
    Fram_PSGMT_5: {
      //BE_HeroVideo: BE_HeroVideo,
      BE_Profile_PDF: BE_Profile_PDF,
      BE_Profile_PPT: BE_Profile_PPT,
    },
    Fram_PSGMT_6: {
      //DD_HeroVideo: DD_HeroVideo,
      DD_Profile_PDF: DD_Profile_PDF,
      DD_Profile_PPT: DD_Profile_PPT,
    },
    Fram_PSGMT_8: {
      //CFL_HeroVideo: CFL_HeroVideo,
      CFL_Profile_PDF: CFL_Profile_PDF,
      CFL_Profile_PPT: CFL_Profile_PPT,
    },
    Fram_PSGMT_9: {
      //PC_HeroVideo: PC_HeroVideo,
      PC_Profile_PDF: PC_Profile_PDF,
      PC_Profile_PPT: PC_Profile_PPT,
    },
    Fram_PSGMT_11: {
      //CE_HeroVideo: CE_HeroVideo,
      CE_Profile_PDF: CE_Profile_PDF,
      CE_Profile_PPT: CE_Profile_PPT,
    },
    Fram_PSGMT_12: {
      //SC_HeroVideo: SC_HeroVideo,
      SC_Profile_PDF: SC_Profile_PDF,
      SC_Profile_PPT: SC_Profile_PPT,
    },
  },
  demandSpaceTemplateDownloads: {
    common: {
      demandSpaceProfilesPDF: demandSpaceProfilesPDF,
      demandSpaceProfilesPPT: demandSpaceProfilesPPT,
      demandspaceTemplateDataSpreadsheet: demandspaceTemplateDataSpreadsheet,
    },
    Fram_DS_1: {
      //EGH_HeroVideo: EGH_HeroVideo,
      EGH_Profile_PDF: EGH_Profile_PDF,
      EGH_Profile_PPT: EGH_Profile_PPT,
    },
    Fram_DS_2: {
      //RL_HeroVideo: RL_HeroVideo,
      RL_Profile_PDF: RL_Profile_PDF,
      RL_Profile_PPT: RL_Profile_PPT,
    },
    Fram_DS_3: {
      //ENO_HeroVideo: ENO_HeroVideo,
      ENO_Profile_PDF: ENO_Profile_PDF,
      ENO_Profile_PPT: ENO_Profile_PPT,
    },
    Fram_DS_4: {
      //USM_HeroVideo: USM_HeroVideo,
      USM_Profile_PDF: USM_Profile_PDF,
      USM_Profile_PPT: USM_Profile_PPT,
    },
    Fram_DS_5: {
      //CGT_HeroVideo: CGT_HeroVideo,
      CGT_Profile_PDF: CGT_Profile_PDF,
      CGT_Profile_PPT: CGT_Profile_PPT,
    },
    Fram_DS_6: {
      //SU_HeroVideo: SU_HeroVideo,
      SU_Profile_PDF: SU_Profile_PDF,
      SU_Profile_PPT: SU_Profile_PPT,
    },
    Fram_DS_7: {
      //LSG_HeroVideo: LSG_HeroVideo,
      LSG_Profile_PDF: LSG_Profile_PDF,
      LSG_Profile_PPT: LSG_Profile_PPT,
    },
    Fram_DS_8: {
      //IU_HeroVideo: IU_HeroVideo,
      IU_Profile_PDF: IU_Profile_PDF,
      IU_Profile_PPT: IU_Profile_PPT,
    },
    Fram_DS_9: {
      //RWO_HeroVideo: RWO_HeroVideo,
      RWO_Profile_PDF: RWO_Profile_PDF,
      RWO_Profile_PPT: RWO_Profile_PPT,
    },
    Fram_DS_10: {
      //TC_HeroVideo: TC_HeroVideo,
      TC_Profile_PDF: TC_Profile_PDF,
      TC_Profile_PPT: TC_Profile_PPT,
    },
    Fram_DS_11: {
      //SS_HeroVideo: SS_HeroVideo,
      SS_Profile_PDF: SS_Profile_PDF,
      SS_Profile_PPT: SS_Profile_PPT,
    },
    Fram_DS_12: {
      //IDI_HeroVideo: IDI_HeroVideo,
      IDI_Profile_PDF: IDI_Profile_PDF,
      IDI_Profile_PPT: IDI_Profile_PPT,
    },
    Fram_DS_13: {
      //EM_HeroVideo: EM_HeroVideo,
      EM_Profile_PDF: EM_Profile_PDF,
      EM_Profile_PPT: EM_Profile_PPT,
    },
  },
  occasionTemplateDownloads: {
    common: {
      occasionProfilePPT: occasionProfilePPT,
      occasionsProfilePDF: occasionsProfilePDF,
      occasionsDataSpreadsheet: occasionsDataSpreadsheet,
    },
    Fram_OCCAS_2: {
      BWO_Profile_PDF: BWO_Profile_PDF,
      BWO_Profile_PPT: BWO_Profile_PPT,
    },
    Fram_OCCAS_3: {
      LMO_Profile_PDF: LMO_Profile_PDF,
      LMO_Profile_PPT: LMO_Profile_PPT,
    },
    Fram_OCCAS_5: {
      WGT_Profile_PDF: WGT_Profile_PDF,
      WGT_Profile_PPT: WGT_Profile_PPT,
    },
    Fram_OCCAS_6: {
      WH_Profile_PDF: WH_Profile_PDF,
      WH_Profile_PPT: WH_Profile_PPT,
    },
    Fram_OCCAS_7: {
      HM_Profile_PDF: HM_Profile_PDF,
      HM_Profile_PPT: HM_Profile_PPT,
    },
    Fram_OCCAS_9: {
      WDI_Profile_PDF: WDI_Profile_PDF,
      WDI_Profile_PPT: WDI_Profile_PPT,
    },
    Fram_OCCAS_10: {
      WM_Profile_PDF: WM_Profile_PDF,
      WM_Profile_PPT: WM_Profile_PPT,
    },
    Fram_OCCAS_12: {
      TVD_Profile_PDF: TVD_Profile_PDF,
      TVD_Profile_PPT: TVD_Profile_PPT,
    },
    Fram_OCCAS_13: {
      UT_Profile_PDF: UT_Profile_PDF,
      UT_Profile_PPT: UT_Profile_PPT,
    },
    Fram_OCCAS_14: {
      MT_Profile_PDF: MT_Profile_PDF,
      MT_Profile_PPT: MT_Profile_PPT,
    },
  },
  //IndividualFiles
  contextualFactorsData : {
    DataPackSpreadsheet: DataPackSpreadsheet,
  },
  consumptionData : {
    DataPackSpreadsheet: DataPackSpreadsheet,
  },
  driverAndNeedsData : {
    DataPackSpreadsheet: DataPackSpreadsheet,
  },
  demographicTableData : {
    DataPackSpreadsheet: DataPackSpreadsheet,
  }
};
