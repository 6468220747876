export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUris: [
            process.env.REACT_APP_REDIRECT_URL,
            process.env.REACT_APP_REDIRECT_URL_INTERNAL
        ],
        postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation:'sessionStorage',
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: [
        'user.read'
    ],
}