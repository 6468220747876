import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const HoverArrowGrid = ({ title, isYellow, isYellowDisabled }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const boxStyle = {
    borderRadius: "4.223px",
    background: isYellow ? "#F6E003" : isYellowDisabled ? "#8F8200" : "#1D1D1D",
    minHeight: "19px",
    marginBottom: "5px",
    position: "relative",
  };

  const iconStyle = {
    color: "black",
    fontSize: "20px",
    display: isHovered ? "block" : "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const popupStyle = {
    display: isHovered ? "block" : "none",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    padding: "7px 7px",
    alignItems: "center",
    marginLeft: "20px",
    gap: "10px",
    color: "#FFFFFF",
    borderRadius: "8px",
    background: "var(--grayscale-800, #2e2e2e)",
    position: "absolute",
    top: "50%",
    left: "100%",
    transform: "translate(-10px, -50%)",
    whiteSpace: "nowrap",
    zIndex: 3,
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      <Paper sx={boxStyle} />
      <EastIcon style={iconStyle} />
      <div style={popupStyle}>{title}</div>
    </div>
  );
};

const HoverArrowGridPeopleSegment = ({ title, background }) => {
  const [isHoveredPS, setIsHoveredPS] = useState(false);

  const handleMouseEnter = () => {
    setIsHoveredPS(true);
  };

  const handleMouseLeave = () => {
    setIsHoveredPS(false);
  };

  const boxStyle = {
    borderRadius: "4.223px",
    minHeight: "21px",
    position: "relative",
  };

  const iconStyle = {
    color: "black",
    fontSize: "20px",
    display: isHoveredPS ? "block" : "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const popupStyle = {
    display: isHoveredPS ? "block" : "none",
    fontFamily: "Inter-Regular",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    padding: "7px 7px",
    alignItems: "center",
    marginLeft: "20px",
    gap: "10px",
    color: "#FFFFFF",
    borderRadius: "8px",
    background: "var(--grayscale-800, #2e2e2e)",
    position: "absolute",
    // top: "0%",
    right: "-50%",
    // transform: "translate(-10px, -50%)",
    whiteSpace: "nowrap",
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      <Paper sx={boxStyle} className={`${background}`} />
      <EastIcon style={iconStyle} />
      <div style={popupStyle}>{title}</div>
    </div>
  );
};

function WayFinderLayout({ occasions, peopleSegments }) {
  const { t } = useTranslation(i18next.language);

  const boxStyle = {
    borderRadius: "4.364px",
    background: "var(--grayscale-800, #1D1D1D)",
    minHeight: "21px",
    // maxWidth: "60px",
  };
  const boxStyleSegments = {
    marginBottom: "3px",
    borderRadius: "4.364px",
    // background: "var(--grayscale-800, #1D1D1D)",
    minHeight: "21px",
  };
  const boxStyleMid = {
    borderRadius: "4.364px",
    background: "var(--grayscale-800, #1D1D1D)",
    minHeight: "21px",
    marginTop: "5px",
  };
  const boxStyleMidBig = {
    borderRadius: "4.364px",
    background: "var(--grayscale-800, #1D1D1D)",
    minHeight: "63px",
    marginTop: "5px",
  };
  const boxStyleSquare = {
    borderRadius: "4.364px",
    background: "var(--grayscale-800, #1D1D1D)",
    minHeight: "115px",
  };
  const boxStyleSquare2 = {
    borderRadius: "4.364px",
    background: "var(--grayscale-800, #1D1D1D)",
    minHeight: "48px",
  };

  return (
    <Grid container width={"100%"} alignSelf={"flex-end"} spacing={0.2}>
      <Grid
        className={`people-segments-vertical-text ${
          occasions ? "highlight-color" : "dim-color"
        }`}
        item
        xs={0.5}
        mt={occasions ? 21.5 : 27}
      >
        Occasions
      </Grid>
      {occasions && (
        <Grid item xs={1.8}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            // spacing={2}
          >
            <Grid item xs={2} mt={9}>
              <div className="people-segments-titles">GOING OUT</div>
            </Grid>
            <Grid item xs={2} mt={4.8}>
              <div className="people-segments-titles-disabled">SOCIALIZING</div>
            </Grid>
            <Grid item xs={2} mt={5}>
              <div className="people-segments-titles">DAILY DINING</div>
            </Grid>
            <Grid item xs={2} mt={5}>
              <div className="people-segments-titles-disabled">RELAXING</div>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* Main content grid */}
      <Grid item xs={occasions ? 9.7 : 11.5}>
        <div
          className={`people-segments-head ${
            occasions ? "dim-color" : "highlight-color"
          } `}
        >
          PEOPLE SEGMENTS
        </div>

        <Grid container spacing={0.5}>
          {/* Grid items */}
          <Grid item xs={1.25}></Grid>
          <Grid item xs={2.5}>
            {peopleSegments && (
              <div
                // style={divStyle}
                className="people-segments-headings familiar-reserved"
              >
                {t("people-segment.macroPeopleSegment.Fram_PSGMT_1")
                  .replace(" + ", " ")
                  .split(" ")
                  .join("\n")}
              </div>
            )}
            <Grid container spacing={0.5}>
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_3")}
                    background={"loyal-traditionalist-background"}
                  />
                </Grid>
              )}
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_2")}
                    background={"comfort-craver-background"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2.5}>
            {peopleSegments && (
              <div className="people-segments-headings familiar-outgoing">
                {t("people-segment.macroPeopleSegment.Fram_PSGMT_7")
                  .replace(" + ", " ")
                  .split(" ")
                  .join("\n")}
              </div>
            )}
            <Grid container spacing={0.5}>
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_8")}
                    background={"carefree-fun-lover-background"}
                  />
                </Grid>
              )}
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_9")}
                    background={"proud-celebrator-background"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2.5}>
            {peopleSegments && (
              <div className="people-segments-headings exploratory-outgoing">
                {t("people-segment.macroPeopleSegment.Fram_PSGMT_10")
                  .replace(" + ", " ")
                  .split(" ")
                  .join("\n")}
              </div>
            )}
            <Grid container spacing={0.5}>
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_11")}
                    background={"creative-explorer-background"}
                  />
                </Grid>
              )}
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_12")}
                    background={"social-curator-background"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2.5}>
            {peopleSegments && (
              <div className="people-segments-headings exploratory-reserved">
                {t("people-segment.macroPeopleSegment.Fram_PSGMT_4")
                  .replace(" + ", " ")
                  .split(" ")
                  .join("\n")}
              </div>
            )}
            <Grid container spacing={0.5}>
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_5")}
                    background={"mature-discoverer-background"}
                  />
                </Grid>
              )}
              {occasions && (
                <Grid item xs={6}>
                  <Paper sx={boxStyleSegments} className="common-bg" />
                </Grid>
              )}
              {peopleSegments && (
                <Grid item xs={6}>
                  <HoverArrowGridPeopleSegment
                    title={t("people-segment.type.Fram_PSGMT_6")}
                    background={"diligent-discoverer-background"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0.5} mt={0.5}>
          {/* Grid items */}
          {occasions && (
            <Grid item xs={1.25}>
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_2")}
                isYellow
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_3")}
                isYellow
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_6")}
                isYellowDisabled
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_5")}
                isYellowDisabled
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_7")}
                isYellowDisabled
              />
            </Grid>
          )}
          {peopleSegments && (
            <Grid item xs={1.25}>
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
            </Grid>
          )}

          <Grid item xs={2.5}>
            <Paper sx={boxStyleSquare} />
          </Grid>
          <Grid item xs={5}>
            <Paper sx={boxStyle} />
            <Paper sx={boxStyleMid} />
            <Grid container spacing={0.5}>
              <Grid item xs={6}>
                <Paper sx={boxStyleMidBig} />
              </Grid>
              <Grid item xs={6}>
                <Paper sx={boxStyleMidBig} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2.5}>
            <Paper sx={boxStyleSquare2} />
            <Paper sx={boxStyleMidBig} />
          </Grid>
        </Grid>
        <Grid container spacing={0.5}>
          {/* Grid items */}
          {occasions && (
            <Grid item xs={1.25}>
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_9")}
                isYellow
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_10")}
                isYellow
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_12")}
                isYellowDisabled
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_13")}
                isYellowDisabled
              />
              <HoverArrowGrid
                title={t("occasions.type.Fram_OCCAS_14")}
                isYellowDisabled
              />
            </Grid>
          )}
          {peopleSegments && (
            <Grid item xs={1.25}>
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
              <Paper sx={boxStyleSegments} className="common-bg" />
            </Grid>
          )}
          <Grid item xs={2.5}>
            <Paper sx={boxStyleSquare} />
          </Grid>
          <Grid item xs={7.5}>
            <Paper sx={boxStyle} />
            <Paper sx={boxStyleMid} />
            <Grid container spacing={0.5}>
              <Grid item xs={4}>
                <Paper sx={boxStyleMidBig} />
              </Grid>
              <Grid item xs={4}>
                <Paper sx={boxStyleMidBig} />
              </Grid>
              <Grid item xs={4}>
                <Paper sx={boxStyleMidBig} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WayFinderLayout;
