import React from "react";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
const RestaurantIcon = ({ fontSize = "xs-small", color }) => {
  return (
    <RestaurantRoundedIcon
      style={{
        backgroundColor: "#FFFFFF",
        padding: "4px",
        color: color ? color : "#3D3D3D",
        borderRadius: "50%",
      }}
      fontSize={fontSize}
    />
  );
};
export default RestaurantIcon;

