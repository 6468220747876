import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import ScopeBadge from "../../custom/Badges/ScopeBadge";
import { demandspaceOccassionPeopleSegmentMapping } from "../../../constants/DemandSpace";
import { demandSpaceAssets } from "../../common/DemandSpaceAssets";
import WayFinderDemandSpace from "../../common/page-sections/WayFinderDemandSpace";

const SectionZero = ({ macro, space }) => {
  return (
    <>
      <Box
        className="img-container"
        sx={{
          backgroundImage: `linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ), url(${demandSpaceAssets.heroImages[space]})`,
        }}
      >
        <Box className="hero-container">
          <Box className={`hero-wayfinder ${space}`}>
            <WayFinderDemandSpace
              ids={demandspaceOccassionPeopleSegmentMapping[`${space}`]}
            />
          </Box>
          <Box className="hero-content">
            <Typography className="demandspace-type">
              {t(`demandspace.type.${space}`)}
            </Typography>
            <Box className="demandspace-type-scope">
              <ScopeBadge
                data={{
                  value: `${Math.round(macro?.totalVolume)}%`,
                  label: "Total Alcohol Volume",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SectionZero;
