import React from 'react'
import Footer from './Footer'
import HomeHeader from './HomeHeader'
import { Outlet } from 'react-router';

function AppLayout() {

    return (
        <div className='container'>
            <HomeHeader />
            {/*<LayoutContext.Provider value={{}}>
                {props.children}
            </LayoutContext.Provider>*/}
            <Outlet />
            <Footer />
        </div>
    )
}

export default AppLayout