import React, { useEffect } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import WayFinderLayout from "../../common/page-sections/WayFinderLayout";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";

const SectionFour = () => {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  return (
    <div className="section4-occassions">
      <Grid container className="section4-main">
        <Grid item className="section-left-item" xs={4.5}>
          <Divider className="divider" />
          <Box className="section4-title-container">
            <Typography
              className="section-heading"
              variant="h2"
              color="secondary"
            >
              {t("occasions.hub.whereAreOccasions.title")}
            </Typography>
            <Button
              variant="contained"
              className="explore-demandlandscape-btn outlined-button"
              onClick={() => {
                navigate("/demandspace");
                window.scrollTo(0, 0);
              }}
            >
              {t("buttons.viewDemandLandscape")}
            </Button>
          </Box>
        </Grid>
        <Grid item className="section-right-item" xs={7}>
          <Box className="visual-container">
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/demandspace");
                window.scrollTo(0, 0);
              }}
            >
              <Button
                variant="contained"
                className="button-to-ds"
                onClick={() => {
                  navigate("/demandspace");
                  window.scrollTo(0, 0);
                }}
              >
                {t("buttons.clickToViewDemandLandscape")}
              </Button>
              <WayFinderLayout occasions={true} />
            </Box>
            {/* <div className="demand-landscape-click-container">
              <Grid
                container
                spacing={1}
                ml={12}
                className="interaction-tool-tip"
              >
                <Grid item>
                  <InfoOutlinedIcon
                    style={{ color: "#F6E003" }}
                    className="info-icon"
                  />
                </Grid>
                <Grid item mt={0.3} className="tool-tip-text">
                  {t("occasions.hub.whereAreOccasions.click-info-text")}
                </Grid>
              </Grid>
            </div> */}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SectionFour;
