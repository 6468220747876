import { Box } from '@mui/material'
import React from 'react'

function ImageCard({ theme, cardData }) {
  return (
    <Box className='image-card-container'>
      <img key={cardData.image} className='image-card-visual' alt='visual'
        src={cardData.image} />
    </Box>
  )
}

export default ImageCard