import { Box, Typography } from "@mui/material";
import React from "react";

const UGCCardSection = ({ title, content, selectedSegment }) => {
  return (
    <Box className={`people-section2-experiences ${selectedSegment}`}>
      <Typography className="people-section2-experiences-typo">
        {title}
      </Typography>
      <Typography className="people-section2-experiences-typo2">
        {content}
      </Typography>
    </Box>
  );
};

export default UGCCardSection;
