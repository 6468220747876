import { Box, Typography } from "@mui/material";
import React from "react";

const CustomHorizontalBar = ({ data = [], title, color = [] }) => {
  function getIndexColor(indexValue) {
    if (indexValue < 85) {
      return "negative";
    } else if (indexValue > 115) {
      return "positive";
    } else {
      return "neutral";
    }
  }
  return (
    <>
      <Box className="horizontal-main">
        <Typography className="horizontal-heading">{title}</Typography>
        <Typography className="horizontal-line" />
        <Box className="custom-horizontal-data-container">
          {data?.map((item, indexes) => (
            <Box key={indexes} className="custom-horizontal-data">
              <Box className="custom-horizontal-bar">
                <Typography className="custom-horizontal-name">
                  {item.regionName}
                </Typography>
                <Box className="custom-horizontal-end">
                  <Typography className="custom-horizontal-percent">
                    {Math.round(item.regionPercent)}%
                  </Typography>
                  <Typography
                    className={`custom-horizontal-index ${getIndexColor(
                      item.regionIndex
                    )}`}
                  >
                    {Math.round(item.regionIndex)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "4px",
                  background: `linear-gradient(to right, ${color} ${item.regionPercent}%, rgba(255, 255, 255, 0.20) 5px )`,
                }}
              ></Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default CustomHorizontalBar;
