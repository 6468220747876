//Hub Hero Images
const comfortCraversHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/CC_HeroImage.jpg?${localStorage.getItem("sasKey")}`;
const loyalTraditionalistsHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/LT_HeroImage.jpg?${localStorage.getItem("sasKey")}`;
const balancedEnjoyersHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/BE_HeroImage.jpg?${localStorage.getItem("sasKey")}`;
const carefreeFunLoversHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/CFL_HeroImage.jpg?${localStorage.getItem("sasKey")}`;
const diligentDiscoverersHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/DD_HeroImage.jpg?${localStorage.getItem("sasKey")}`;
const proudCelebratorsHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/PC_HeroImage.jpg?${localStorage.getItem("sasKey")}`;
const creativeExplorersHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/CE_HeroImage.jpg?${localStorage.getItem("sasKey")}`;
const socialCuratorsHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/people-segment/common/SC_HeroImage.jpg?${localStorage.getItem("sasKey")}`;

// Template Images
//Hero Images
const easyGoingHangout = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/EH_Hero.jpg?${localStorage.getItem("sasKey")}`;
const routineLiving = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/RL_Hero.jpg?${localStorage.getItem("sasKey")}`;
const energeticNightOut = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/ENO_Hero.jpg?${localStorage.getItem("sasKey")}`;
const upbeatSocialMeal = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/USM_Hero.jpg?${localStorage.getItem("sasKey")}`;
const casualGoodTimes = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/CGT_Hero.jpg?${localStorage.getItem("sasKey")}`;
const simpleUnplug = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/SU_Hero.jpg?${localStorage.getItem("sasKey")}`;
const livelySocialGathering = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/LSG_Hero.jpg?${localStorage.getItem("sasKey")}`;
const intentionalUnwind = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/IU_Hero.jpg?${localStorage.getItem("sasKey")}`;
const refinedWeekendOut = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/RWO_Hero.jpg?${localStorage.getItem("sasKey")}`;
const thoughtFulConnection = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/TC_Hero.jpg?${localStorage.getItem("sasKey")}`;
const sensibleSlowdown = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/SS_Hero.jpg?${localStorage.getItem("sasKey")}`;
const initimateDiningIn = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/ID_Hero.jpg?${localStorage.getItem("sasKey")}`;
const everydayMeal = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/common/EM_Hero.jpg?${localStorage.getItem("sasKey")}`;

//Intro
const CGT_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/casual-good-times/video/CGT_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/casual-good-times/video/CGT_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EGH_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/easygoing-hangout/video/EGH_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/easygoing-hangout/video/EGH_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/everyday-meal/video/EM_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const EM_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/everyday-meal/video/EM_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/energetic-nightout/video/ENO_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/energetic-nightout/video/ENO_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/intentional-unwind/video/IU_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const IU_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/intentional-unwind/video/IU_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/routine-living/video/RL_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const RL_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/routine-living/video/RL_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/intimate-dining-in/video/ID_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const ID_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/intimate-dining-in/video/ID_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/lively-social-gathering/video/LSG_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/lively-social-gathering/video/LSG_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/refined-weekend-out/video/RWO_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/refined-weekend-out/video/RWO_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/simple-unplug/video/SU_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const SU_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/simple-unplug/video/SU_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/sensible-slowdown/video/SS_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const SS_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/sensible-slowdown/video/SS_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/thoughtful-connection/video/TC_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const TC_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/thoughtful-connection/video/TC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/upbeat-social-meal/video/USM_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem(
  "sasKey"
)}`;
const USM_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/intro/upbeat-social-meal/video/USM_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Visual Snapshot Images
//1.Casual Good Times
const CGT_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Easy Going Hangout

const EH_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Energetic Night Out
const ENO_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Routine Living
const RL_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/routine-living/RL_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Upbeat Social Meal
const USM_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//SimpleUnplug

const SU_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Lively Social Gathering

const LSG_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Intentional Unwind
const IU_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Refined Weekend Out

const RWO_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Thoughtful Connection

const TC_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Sensible Slowdown

const SS_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Intimate Dining In

const ID_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//everyday Meal

const EM_Grid1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid1.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid2.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid3.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid4.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid5 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid5.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid6.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid7.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid8 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid8.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Grid9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//The People

const CGT_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/casual-good-times/CGT_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/casual-good-times/CGT_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/easygoing-hangout/EH_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EH_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/easygoing-hangout/EH_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/energetic-nightout/ENO_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/energetic-nightout/ENO_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/intentional-unwind/IU_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/intentional-unwind/IU_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/intimate-dining-in/ID_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/intimate-dining-in/ID_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/lively-social-gathering/LSG_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/lively-social-gathering/LSG_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/refined-weekend-out/RWO_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/refined-weekend-out/RWO_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/routine-living/RL_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/routine-living/RL_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/sensible-slowdown/SS_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/sensible-slowdown/SS_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/simple-unplug/SU_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/simple-unplug/SU_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/thoughtful-connection/TC_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/thoughtful-connection/TC_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/upbeat-social-meal/USM_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/upbeat-social-meal/USM_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_People_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/everyday-meal/EM_people_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_People_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-people/everyday-meal/EM_people_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// The Occasions

const CGT_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/casual-good-times/CGT_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/casual-good-times/CGT_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/easygoing-hangout/EH_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EH_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/easygoing-hangout/EH_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/energetic-nightout/ENO_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/energetic-nightout/ENO_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/intentional-unwind/IU_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/intentional-unwind/IU_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/intimate-dining-in/ID_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/intimate-dining-in/ID_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/lively-social-gathering/LSG_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/lively-social-gathering/LSG_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/refined-weekend-out/RWO_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/refined-weekend-out/RWO_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/routine-living/RL_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/routine-living/RL_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/sensible-slowdown/SS_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/sensible-slowdown/SS_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/simple-unplug/SU_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/simple-unplug/SU_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/thoughtful-connection/TC_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/thoughtful-connection/TC_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/upbeat-social-meal/USM_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/upbeat-social-meal/USM_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Occasions_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/everyday-meal/EM_occasions_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Occasions_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/the-occasions/everyday-meal/EM_occasions_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Contextual Factor

const CGT_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/casual-good-times/CGT_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/casual-good-times/CGT_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/easygoing-hangout/EH_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EH_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/easygoing-hangout/EH_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/energetic-nightout/ENO_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/energetic-nightout/ENO_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/intentional-unwind/IU_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/intentional-unwind/IU_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/intimate-dining-in/ID_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/intimate-dining-in/ID_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/lively-social-gathering/LSG_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/lively-social-gathering/LSG_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/refined-weekend-out/RWO_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/refined-weekend-out/RWO_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/routine-living/RL_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/routine-living/RL_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/sensible-slowdown/SS_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/sensible-slowdown/SS_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/simple-unplug/SU_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/simple-unplug/SU_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/thoughtful-connection/TC_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/thoughtful-connection/TC_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/upbeat-social-meal/USM_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/upbeat-social-meal/USM_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_ContextualFactor_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/everyday-meal/EM_contextual-factor_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_ContextualFactor_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/contextual-factor/everyday-meal/EM_contextual-factor_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Drivers and Needs

const CGT_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/casual-good-times/CGT_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/casual-good-times/CGT_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/easygoing-hangout/EH_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EH_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/easygoing-hangout/EH_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/energetic-nightout/ENO_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/energetic-nightout/ENO_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/intentional-unwind/IU_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/intentional-unwind/IU_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/intimate-dining-in/ID_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/intimate-dining-in/ID_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/lively-social-gathering/LSG_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/lively-social-gathering/LSG_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/refined-weekend-out/RWO_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/refined-weekend-out/RWO_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RL_DriversNeeds_Front = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd8486bcf4ecd4458978e04905d2bede6`;
const RL_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/routine-living/RL_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/sensible-slowdown/SS_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/sensible-slowdown/SS_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/simple-unplug/SU_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_DriversNeeds_Back = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2b5f7560e6d7491c89ab38622c993661`;

const TC_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/thoughtful-connection/TC_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/thoughtful-connection/TC_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/upbeat-social-meal/USM_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/upbeat-social-meal/USM_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_DriversNeeds_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/everyday-meal/EM_drivers-needs_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_DriversNeeds_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/drivers-and-needs/everyday-meal/EM_drivers-needs_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;
//ModularContent
//Easygoing Hangout
const EGH_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Routine Living
const RL_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Energetic Night Out
const ENO_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Upbeat Social Meal
const USM_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Casual Good Times

const CGT_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Simple Unplug
const SU_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Lively Social Gathering

const LSG_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Intentional Unwind
const IU_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Refined Weekend Out
const RWO_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Thoughtful Connection
const TC_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_DriversNeeds_Mod7 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F16c7799ccf2d400c9538dad7e4e248fc`;
const TC_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//Sensible Slowdown
const SS_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Intimate Dining In
const ID_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// everyday Meal In
const EM_DriversNeeds_Mod10 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_10.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_DriversNeeds_Mod2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_DriversNeeds_Mod3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_DriversNeeds_Mod4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_4.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_DriversNeeds_Mod6 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_6.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_DriversNeeds_Mod7 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_7.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_DriversNeeds_Mod9 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_9.jpg?${localStorage.getItem(
  "sasKey"
)}`;

// Consumption

const CGT_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/casual-good-times/CGT_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/casual-good-times/CGT_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EH_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/easygoing-hangout/EH_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EH_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/easygoing-hangout/EH_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/energetic-nightout/ENO_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/energetic-nightout/ENO_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/intentional-unwind/IU_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/intentional-unwind/IU_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const ID_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/intimate-dining-in/ID_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ID_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/intimate-dining-in/ID_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/lively-social-gathering/LSG_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/lively-social-gathering/LSG_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/refined-weekend-out/RWO_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Consumption_Back = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5ab6faae0bf34f1ba9088fe5454fc694`;

const RL_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/routine-living/RL_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/routine-living/RL_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/sensible-slowdown/SS_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/sensible-slowdown/SS_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/simple-unplug/SU_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/simple-unplug/SU_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/thoughtful-connection/TC_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/thoughtful-connection/TC_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/upbeat-social-meal/USM_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/upbeat-social-meal/USM_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Consumption_Front = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/everyday-meal/EM_consumption_Front.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Consumption_Back = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/consumption/everyday-meal/EM_consumption_Back.jpg?${localStorage.getItem(
  "sasKey"
)}`;

//How they Drink

//VerticalImage are commented and for future use we have passed verticalImage as null, we can uncomment and use the verticalImage if needed
//Casual Good Times

const CGT_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Samantha.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel1_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const CGT_Carousel1_VerticalImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_2.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CGT_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Sam.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel2_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const CGT_Carousel2_VerticalImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_2.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CGT_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Jessica.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const CGT_Carousel3_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CGT_Carousel3_VideoClip = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fefc3309095fd4468849e41934b769c42%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=efc3309095fd4468849e41934b769c42&alt=media&optimized=true`;

const CGT_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Leo.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel4_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const CGT_Carousel4_VerticalImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_2.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CGT_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Andrea.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel5_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const CGT_Carousel5_VerticalImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_1.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CGT_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const CGT_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Tyler.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel6_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const CGT_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const CGT_Carousel6_VerticalImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_1.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CGT_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Easy Going Hangout

const EGH_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Jamie.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel1_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EGH_Carousel1_VerticalImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_1.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const EGH_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EGH_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Karl.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel2_SquareImage3 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feea20152e262491197e79f652ea5a525`;
// const EGH_Carousel2_VerticalImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car2_2.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const EGH_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EGH_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Nick_IA.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel3_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EGH_Carousel3_VerticalImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_2.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const EGH_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EGH_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Marti.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car4_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EGH_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Brad.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel5_SquareImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EGH_Carousel5_VerticalImage4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_4.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const EGH_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EGH_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Yessica.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EGH_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Routine Living

const RL_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Jamie.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car1_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RL_Carousel1_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/routine-living/images/RL_car1_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RL_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/video/RL_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Karl.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel2_SquareImage1 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9e18565ce28849b682ad1f80d0a0adc8`;
const RL_Carousel2_SquareImage2 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F31b6589e81eb43339e63257a77cc19a8`;
// const RL_Carousel2_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/routine-living/images/RL_car2_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RL_Carousel2_VideoClip = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F331c334dca6b4d01a7969a10068e1013%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=331c334dca6b4d01a7969a10068e1013&alt=media&optimized=true`;

const RL_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Nick_IA.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RL_Carousel3_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/routine-living/images/RL_car3_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RL_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/video/RL_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Marti.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car4_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RL_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/routine-living/images/RL_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RL_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/video/RL_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Brad.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel5_SquareImage1 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9782dd4eece547b9a9503d30866aed2a`;
const RL_Carousel5_SquareImage2 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3bc10f3545c045f5bb3d5c8dbe32c408`;
// const RL_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/routine-living/images/RL_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RL_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/video/RL_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const RL_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Yessica.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RL_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/images/RL_car6_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RL_Carousel6_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/routine-living/images/RL_car6_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RL_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/routine-living/video/RL_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Energetic Night Out

const ENO_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Nick_NY.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel1_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const ENO_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Hector.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel2_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const ENO_Carousel2_VideoClip = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa5920331a4fb4e20a44b86d4bd04da01%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=a5920331a4fb4e20a44b86d4bd04da01&alt=media&optimized=true`;

const ENO_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Tyler.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel3_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const ENO_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Samantha.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const ENO_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Andrea.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const ENO_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Jessica.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel6_SquareImage1 = `${
process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_3.jpg?${localStorage.getItem(
"sasKey"
)}`;
const ENO_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel6_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const ENO_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Carousel7_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Jourdan.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel7_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel7_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel7_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const ENO_Carousel7_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car7_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const ENO_Carousel8_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Craig.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel8_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const ENO_Carousel8_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const ENO_Carousel8_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const ENO_Carousel8_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car8_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Upbeat Social Meal

const USM_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Nick_NY.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const USM_Carousel1_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const USM_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Andrea.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const USM_Carousel2_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const USM_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Jourdan.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const USM_Carousel3_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const USM_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Tyler.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel4_SquareImage1 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff445d2ee4d5f456981bdec704b3780bd`;
const USM_Carousel4_SquareImage2 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F774f876cba614152b10dd6e49dfbd865`;
// const USM_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const USM_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Craig.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel5_SquareImage1 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3a9af699c44c4f1683864dbb3d5db3cf`;
const USM_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const USM_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const USM_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const USM_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Hector.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const USM_Carousel6_SquareImage1 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F54f662ee7a754d408b4ccc77b7f85a10`;
const USM_Carousel6_SquareImage2 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fec14c4441f2c450d9d09797a3a058fee`;
// const USM_Carousel6_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car6_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const USM_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Simple Unplug

const SU_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Andrea.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const SU_Carousel1_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SU_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Jessica.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const SU_Carousel2_SquareImage2 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car2_2.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
// const SU_Carousel2_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car2_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SU_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Leo.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const SU_Carousel3_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SU_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Tyler.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const SU_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const SU_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Samantha.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const SU_Carousel5_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SU_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SU_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Bledar.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SU_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const SU_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SU_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Lively Social Gathering

const LSG_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Nick_NY.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const LSG_Carousel1_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const LSG_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Hector.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const LSG_Carousel2_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const LSG_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Kiana.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const LSG_Carousel3_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const LSG_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Jourdan.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const LSG_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const LSG_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Craig.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const LSG_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const LSG_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const LSG_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Daniel_NY.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const LSG_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const LSG_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const LSG_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Intentional Unwind

const IU_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Nick_NY.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IU_Carousel1_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const IU_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Hector.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IU_Carousel2_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const IU_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Kiana.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IU_Carousel3_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const IU_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Jourdan.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IU_Carousel4_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const IU_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Craig.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IU_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const IU_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IU_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Daniel_NY.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IU_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IU_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const IU_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Refined Weekend Out

const RWO_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Danielle.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RWO_Carousel1_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RWO_Carousel1_VideoClip = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F97a0d53fcbc442cd83dcc15b38b97630%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=97a0d53fcbc442cd83dcc15b38b97630&alt=media&optimized=true`;

const RWO_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Maria.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RWO_Carousel2_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RWO_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Brandon.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RWO_Carousel3_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const RWO_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Sharyn.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RWO_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RWO_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const RWO_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Jeannine.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const RWO_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RWO_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const RWO_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car5_Video.mp4?${localStorage.getItem(
"sasKey"
)}`;

const RWO_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_1.jpg?${localStorage.getItem(
"sasKey"
)}`;
const RWO_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_2.jpg?${localStorage.getItem(
"sasKey"
)}`;

const RWO_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Kelly.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const RWO_Carousel6_SquareImage1 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_1.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
// const RWO_Carousel6_SquareImage2 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_2.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
// const RWO_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
// const RWO_Carousel6_VideoClip = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car6_Video.mp4?${localStorage.getItem(
//   "sasKey"
// )}`;

//Thoughtful Connection

const TC_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Danielle.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const TC_Carousel1_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const TC_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Daniel_IL.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const TC_Carousel2_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const TC_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_An.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const TC_Carousel3_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const TC_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Sharyn.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const TC_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const TC_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Jeannine.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const TC_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const TC_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const TC_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Jon.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const TC_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const TC_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const TC_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Sensible Slowdown

const SS_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Danielle.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel1_SquareImage2 = `${
process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_2.jpg?${localStorage.getItem(
"sasKey"
)}`;
// const SS_Carousel1_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SS_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Maria.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel2_SquareImage1 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F06630f7f060b4b32951b8400def57933`;
const SS_Carousel2_SquareImage2 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ffa015778cc654ad09ad3ea451c7fd9b2`;
// const SS_Carousel2_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car2_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SS_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Brandon.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel3_SquareImage2 = `${
process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_2.jpg?${localStorage.getItem(
"sasKey"
)}`;
// const SS_Carousel3_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SS_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Sharyn.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel4_SquareImage2 = `${
process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_2.jpg?${localStorage.getItem(
"sasKey"
)}`;
// const SS_Carousel4_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SS_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Jeannine.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const SS_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const SS_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const SS_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Kelly.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const SS_Carousel6_SquareImage2 = `${
process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_2.jpg?${localStorage.getItem(
"sasKey"
)}`;
// const SS_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const SS_Carousel6_VideoClip = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F636b01e943b5480baae92fab96b9a095%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=636b01e943b5480baae92fab96b9a095&alt=media&optimized=true`;

//Initimate Dining In

const IDI_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Daniel_IL.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IDI_Carousel1_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const IDI_Carousel1_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car1_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IDI_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Melissa.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IDI_Carousel2_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const IDI_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IDI_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Anahi.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IDI_Carousel3_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const IDI_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IDI_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Sam.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_3.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IDI_Carousel4_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const IDI_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IDI_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Kat.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IDI_Carousel5_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const IDI_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const IDI_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Elizabeth.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const IDI_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const IDI_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const IDI_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

//Everyday Meal

const EM_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Danielle.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EM_Carousel1_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const EM_Carousel1_VideoClip = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ffd0d4a2b3dab427baed9a3293f1ab400%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=fd0d4a2b3dab427baed9a3293f1ab400&alt=media&optimized=true`;

const EM_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Steven.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EM_Carousel2_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const EM_Carousel2_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car2_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Leo.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EM_Carousel3_VerticalImage3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_3.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
const EM_Carousel3_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car3_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Erika.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EM_Carousel4_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const EM_Carousel4_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car4_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Noha.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EM_Carousel5_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const EM_Carousel5_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car5_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

const EM_Carousel6_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Mike.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel6_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_1.jpg?${localStorage.getItem(
  "sasKey"
)}`;
const EM_Carousel6_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_2.jpg?${localStorage.getItem(
  "sasKey"
)}`;
// const EM_Carousel6_VerticalImage3 = `${
//   process.env.REACT_APP_AZURE_STORAGE_URL
// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_3.jpg?${localStorage.getItem(
//   "sasKey"
// )}`;
const EM_Carousel6_VideoClip = `${process.env.REACT_APP_AZURE_STORAGE_URL
}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car6_Video.mp4?${localStorage.getItem(
  "sasKey"
)}`;

export const demandSpaceAssets = {
hubHeroImages: {
  comfortCraversHero: comfortCraversHero,
  loyalTraditionalistsHero: loyalTraditionalistsHero,
  balancedEnjoyersHero: balancedEnjoyersHero,
  diligentDiscoverersHero: diligentDiscoverersHero,
  carefreeFunLoversHero: carefreeFunLoversHero,
  proudCelebratorsHero: proudCelebratorsHero,
  creativeExplorersHero: creativeExplorersHero,
  socialCuratorsHero: socialCuratorsHero,
},
heroImages: {
  Fram_DS_5: casualGoodTimes,
  Fram_DS_1: easyGoingHangout,
  Fram_DS_3: energeticNightOut,
  Fram_DS_4: upbeatSocialMeal,
  Fram_DS_9: refinedWeekendOut,
  Fram_DS_7: livelySocialGathering,
  Fram_DS_10: thoughtFulConnection,
  Fram_DS_2: routineLiving,
  Fram_DS_12: initimateDiningIn,
  Fram_DS_13: everydayMeal,
  Fram_DS_6: simpleUnplug,
  Fram_DS_8: intentionalUnwind,
  Fram_DS_11: sensibleSlowdown,
},
intro: {
  Fram_DS_5: {
    //Casual Good Times
    featuredPersonVideo: CGT_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: CGT_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_1: {
    //Easy Going Hangout
    featuredPersonVideo: EGH_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: EGH_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_3: {
    //Energetic Night Out
    featuredPersonVideo: ENO_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: ENO_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_4: {
    //Upbeat Social Meal
    featuredPersonVideo: USM_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: USM_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_9: {
    //Refined Weekend Out
    featuredPersonVideo: RWO_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: RWO_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_7: {
    //Lively Social Gathering
    featuredPersonVideo: LSG_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: LSG_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_10: {
    //Thoughtful Connection
    featuredPersonVideo: TC_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: TC_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_2: {
    //Routine Living
    featuredPersonVideo: RL_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: RL_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_12: {
    //Intimate Dining
    featuredPersonVideo: ID_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: ID_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_13: {
    //Everyday Meal
    featuredPersonVideo: EM_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: EM_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_6: {
    //Simple Unplug
    featuredPersonVideo: SU_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: SU_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_8: {
    //Intentional Unwind
    featuredPersonVideo: IU_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: IU_FeaturedPerson_IntroVideo_Thumbnail,
  },
  Fram_DS_11: {
    //Sensible Slowdown
    featuredPersonVideo: SS_FeaturedPerson_IntroVideo,
    featuredPersonVideoThumbnail: SS_FeaturedPerson_IntroVideo_Thumbnail,
  },
},
visualSnapshot: {
  Fram_DS_5: [
    {
      grid: CGT_Grid1,
    },
    {
      grid: CGT_Grid2,
    },
    {
      grid: CGT_Grid3,
    },
    {
      grid: CGT_Grid4,
    },
    {
      grid: CGT_Grid5,
    },
    {
      grid: CGT_Grid6,
    },
    {
      grid: CGT_Grid7,
    },
    {
      grid: CGT_Grid8,
    },
    {
      grid: CGT_Grid9,
    },
  ],
  Fram_DS_1: [
    {
      grid: EH_Grid1,
    },
    {
      grid: EH_Grid2,
    },
    {
      grid: EH_Grid3,
    },
    {
      grid: EH_Grid4,
    },
    {
      grid: EH_Grid5,
    },
    {
      grid: EH_Grid6,
    },
    {
      grid: EH_Grid7,
    },
    {
      grid: EH_Grid8,
    },
    {
      grid: EH_Grid9,
    },
  ],
  Fram_DS_3: [
    {
      grid: ENO_Grid1,
    },
    {
      grid: ENO_Grid2,
    },
    {
      grid: ENO_Grid3,
    },
    {
      grid: ENO_Grid4,
    },
    {
      grid: ENO_Grid5,
    },
    {
      grid: ENO_Grid6,
    },
    {
      grid: ENO_Grid7,
    },
    {
      grid: ENO_Grid8,
    },
    {
      grid: ENO_Grid9,
    },
  ],
  Fram_DS_2: [
    {
      grid: RL_Grid1,
    },
    {
      grid: RL_Grid2,
    },
    {
      grid: RL_Grid3,
    },
    {
      grid: RL_Grid4,
    },
    {
      grid: RL_Grid5,
    },
    {
      grid: RL_Grid6,
    },
    {
      grid: RL_Grid7,
    },
    {
      grid: RL_Grid8,
    },
    {
      grid: RL_Grid9,
    },
  ],
  Fram_DS_4: [
    {
      grid: USM_Grid1,
    },
    {
      grid: USM_Grid2,
    },
    {
      grid: USM_Grid3,
    },
    {
      grid: USM_Grid4,
    },
    {
      grid: USM_Grid5,
    },
    {
      grid: USM_Grid6,
    },
    {
      grid: USM_Grid7,
    },
    {
      grid: USM_Grid8,
    },
    {
      grid: USM_Grid9,
    },
  ],
  Fram_DS_6: [
    {
      grid: SU_Grid1,
    },
    {
      grid: SU_Grid2,
    },
    {
      grid: SU_Grid3,
    },
    {
      grid: SU_Grid4,
    },
    {
      grid: SU_Grid5,
    },
    {
      grid: SU_Grid6,
    },
    {
      grid: SU_Grid7,
    },
    {
      grid: SU_Grid8,
    },
    {
      grid: SU_Grid9,
    },
  ],
  Fram_DS_7: [
    {
      grid: LSG_Grid1,
    },
    {
      grid: LSG_Grid2,
    },
    {
      grid: LSG_Grid3,
    },
    {
      grid: LSG_Grid4,
    },
    {
      grid: LSG_Grid5,
    },
    {
      grid: LSG_Grid6,
    },
    {
      grid: LSG_Grid7,
    },
    {
      grid: LSG_Grid8,
    },
    {
      grid: LSG_Grid9,
    },
  ],
  Fram_DS_8: [
    {
      grid: IU_Grid1,
    },
    {
      grid: IU_Grid2,
    },
    {
      grid: IU_Grid3,
    },
    {
      grid: IU_Grid4,
    },
    {
      grid: IU_Grid5,
    },
    {
      grid: IU_Grid6,
    },
    {
      grid: IU_Grid7,
    },
    {
      grid: IU_Grid8,
    },
    {
      grid: IU_Grid9,
    },
  ],
  Fram_DS_9: [
    {
      grid: RWO_Grid1,
    },
    {
      grid: RWO_Grid2,
    },
    {
      grid: RWO_Grid3,
    },
    {
      grid: RWO_Grid4,
    },
    {
      grid: RWO_Grid5,
    },
    {
      grid: RWO_Grid6,
    },
    {
      grid: RWO_Grid7,
    },
    {
      grid: RWO_Grid8,
    },
    {
      grid: RWO_Grid9,
    },
  ],
  Fram_DS_10: [
    {
      grid: TC_Grid1,
    },
    {
      grid: TC_Grid2,
    },
    {
      grid: TC_Grid3,
    },
    {
      grid: TC_Grid4,
    },
    {
      grid: TC_Grid5,
    },
    {
      grid: TC_Grid6,
    },
    {
      grid: TC_Grid7,
    },
    {
      grid: TC_Grid8,
    },
    {
      grid: TC_Grid9,
    },
  ],
  Fram_DS_11: [
    {
      grid: SS_Grid1,
    },
    {
      grid: SS_Grid2,
    },
    {
      grid: SS_Grid3,
    },
    {
      grid: SS_Grid4,
    },
    {
      grid: SS_Grid5,
    },
    {
      grid: SS_Grid6,
    },
    {
      grid: SS_Grid7,
    },
    {
      grid: SS_Grid8,
    },
    {
      grid: SS_Grid9,
    },
  ],
  Fram_DS_12: [
    {
      grid: ID_Grid1,
    },
    {
      grid: ID_Grid2,
    },
    {
      grid: ID_Grid3,
    },
    {
      grid: ID_Grid4,
    },
    {
      grid: ID_Grid5,
    },
    {
      grid: ID_Grid6,
    },
    {
      grid: ID_Grid7,
    },
    {
      grid: ID_Grid8,
    },
    {
      grid: ID_Grid9,
    },
  ],
  Fram_DS_13: [
    {
      grid: EM_Grid1,
    },
    {
      grid: EM_Grid2,
    },
    {
      grid: EM_Grid3,
    },
    {
      grid: EM_Grid4,
    },
    {
      grid: EM_Grid5,
    },
    {
      grid: EM_Grid6,
    },
    {
      grid: EM_Grid7,
    },
    {
      grid: EM_Grid8,
    },
    {
      grid: EM_Grid9,
    },
  ],
},
thePeople: {
  Fram_DS_5: {
    front: CGT_People_Front,
    back: CGT_People_Back,
  },
  Fram_DS_1: {
    front: EH_People_Front,
    back: EH_People_Back,
  },
  Fram_DS_2: {
    front: RL_People_Front,
    back: RL_People_Back,
  },
  Fram_DS_3: {
    front: ENO_People_Front,
    back: ENO_People_Back,
  },
  Fram_DS_4: {
    front: USM_People_Front,
    back: USM_People_Back,
  },
  Fram_DS_6: {
    front: SU_People_Front,
    back: SU_People_Back,
  },
  Fram_DS_7: {
    front: LSG_People_Front,
    back: LSG_People_Back,
  },
  Fram_DS_8: {
    front: IU_People_Front,
    back: IU_People_Back,
  },
  Fram_DS_9: {
    front: RWO_People_Front,
    back: RWO_People_Back,
  },
  Fram_DS_10: {
    front: TC_People_Front,
    back: TC_People_Back,
  },
  Fram_DS_11: {
    front: SS_People_Front,
    back: SS_People_Back,
  },
  Fram_DS_12: {
    front: ID_People_Front,
    back: ID_People_Back,
  },
  Fram_DS_13: {
    front: EM_People_Front,
    back: EM_People_Back,
  },
},
theOccasions: {
  Fram_DS_5: {
    front: CGT_Occasions_Front,
    back: CGT_Occasions_Back,
  },
  Fram_DS_1: {
    front: EH_Occasions_Front,
    back: EH_Occasions_Back,
  },
  Fram_DS_2: {
    front: RL_Occasions_Front,
    back: RL_Occasions_Back,
  },
  Fram_DS_3: {
    front: ENO_Occasions_Front,
    back: ENO_Occasions_Back,
  },
  Fram_DS_4: {
    front: USM_Occasions_Front,
    back: USM_Occasions_Back,
  },
  Fram_DS_6: {
    front: SU_Occasions_Front,
    back: SU_Occasions_Back,
  },
  Fram_DS_7: {
    front: LSG_Occasions_Front,
    back: LSG_Occasions_Back,
  },
  Fram_DS_8: {
    front: IU_Occasions_Front,
    back: IU_Occasions_Back,
  },
  Fram_DS_9: {
    front: RWO_Occasions_Front,
    back: RWO_Occasions_Back,
  },
  Fram_DS_10: {
    front: TC_Occasions_Front,
    back: TC_Occasions_Back,
  },
  Fram_DS_11: {
    front: SS_Occasions_Front,
    back: SS_Occasions_Back,
  },
  Fram_DS_12: {
    front: ID_Occasions_Front,
    back: ID_Occasions_Back,
  },
  Fram_DS_13: {
    front: EM_Occasions_Front,
    back: EM_Occasions_Back,
  },
},
contextualFactor: {
  Fram_DS_5: {
    front: CGT_ContextualFactor_Front,
    back: CGT_ContextualFactor_Back,
  },
  Fram_DS_1: {
    front: EH_ContextualFactor_Front,
    back: EH_ContextualFactor_Back,
  },
  Fram_DS_2: {
    front: RL_ContextualFactor_Front,
    back: RL_ContextualFactor_Back,
  },
  Fram_DS_3: {
    front: ENO_ContextualFactor_Front,
    back: ENO_ContextualFactor_Back,
  },
  Fram_DS_4: {
    front: USM_ContextualFactor_Front,
    back: USM_ContextualFactor_Back,
  },
  Fram_DS_6: {
    front: SU_ContextualFactor_Front,
    back: SU_ContextualFactor_Back,
  },
  Fram_DS_7: {
    front: LSG_ContextualFactor_Front,
    back: LSG_ContextualFactor_Back,
  },
  Fram_DS_8: {
    front: IU_ContextualFactor_Front,
    back: IU_ContextualFactor_Back,
  },
  Fram_DS_9: {
    front: RWO_ContextualFactor_Front,
    back: RWO_ContextualFactor_Back,
  },
  Fram_DS_10: {
    front: TC_ContextualFactor_Front,
    back: TC_ContextualFactor_Back,
  },
  Fram_DS_11: {
    front: SS_ContextualFactor_Front,
    back: SS_ContextualFactor_Back,
  },
  Fram_DS_12: {
    front: ID_ContextualFactor_Front,
    back: ID_ContextualFactor_Back,
  },
  Fram_DS_13: {
    front: EM_ContextualFactor_Front,
    back: EM_ContextualFactor_Back,
  },
},
driverAndNeeds: {
  Fram_DS_5: {
    front: CGT_DriversNeeds_Front,
    back: CGT_DriversNeeds_Back,
  },
  Fram_DS_1: {
    front: EH_DriversNeeds_Front,
    back: EH_DriversNeeds_Back,
  },
  Fram_DS_2: {
    front: RL_DriversNeeds_Front,
    back: RL_DriversNeeds_Back,
  },
  Fram_DS_3: {
    front: ENO_DriversNeeds_Front,
    back: ENO_DriversNeeds_Back,
  },
  Fram_DS_4: {
    front: USM_DriversNeeds_Front,
    back: USM_DriversNeeds_Back,
  },
  Fram_DS_6: {
    front: SU_DriversNeeds_Front,
    back: SU_DriversNeeds_Back,
  },
  Fram_DS_7: {
    front: LSG_DriversNeeds_Front,
    back: LSG_DriversNeeds_Back,
  },
  Fram_DS_8: {
    front: IU_DriversNeeds_Front,
    back: IU_DriversNeeds_Back,
  },
  Fram_DS_9: {
    front: RWO_DriversNeeds_Front,
    back: RWO_DriversNeeds_Back,
  },
  Fram_DS_10: {
    front: TC_DriversNeeds_Front,
    back: TC_DriversNeeds_Back,
  },
  Fram_DS_11: {
    front: SS_DriversNeeds_Front,
    back: SS_DriversNeeds_Back,
  },
  Fram_DS_12: {
    front: ID_DriversNeeds_Front,
    back: ID_DriversNeeds_Back,
  },
  Fram_DS_13: {
    front: EM_DriversNeeds_Front,
    back: EM_DriversNeeds_Back,
  },
},
inTheirOwnWords: {
  Fram_DS_1: {
    EGH_Mod_10: EGH_DriversNeeds_Mod10,
    EGH_Mod_2: EGH_DriversNeeds_Mod2,
    EGH_Mod_3: EGH_DriversNeeds_Mod3,
    EGH_Mod_4: EGH_DriversNeeds_Mod4,
    EGH_Mod_6: EGH_DriversNeeds_Mod6,
    EGH_Mod_7: EGH_DriversNeeds_Mod7,
    EGH_Mod_9: EGH_DriversNeeds_Mod9,
  },
  Fram_DS_2: {
    RL_Mod_10: RL_DriversNeeds_Mod10,
    RL_Mod_2: RL_DriversNeeds_Mod2,
    RL_Mod_3: RL_DriversNeeds_Mod3,
    RL_Mod_4: RL_DriversNeeds_Mod4,
    RL_Mod_6: RL_DriversNeeds_Mod6,
    RL_Mod_7: RL_DriversNeeds_Mod7,
    RL_Mod_9: RL_DriversNeeds_Mod9,
  },
  Fram_DS_3: {
    ENO_Mod_10: ENO_DriversNeeds_Mod10,
    ENO_Mod_2: ENO_DriversNeeds_Mod2,
    ENO_Mod_3: ENO_DriversNeeds_Mod3,
    ENO_Mod_4: ENO_DriversNeeds_Mod4,
    ENO_Mod_6: ENO_DriversNeeds_Mod6,
    ENO_Mod_7: ENO_DriversNeeds_Mod7,
    ENO_Mod_9: ENO_DriversNeeds_Mod9,
  },
  Fram_DS_4: {
    USM_Mod_10: USM_DriversNeeds_Mod10,
    USM_Mod_2: USM_DriversNeeds_Mod2,
    USM_Mod_3: USM_DriversNeeds_Mod3,
    USM_Mod_4: USM_DriversNeeds_Mod4,
    USM_Mod_6: USM_DriversNeeds_Mod6,
    USM_Mod_7: USM_DriversNeeds_Mod7,
    USM_Mod_9: USM_DriversNeeds_Mod9,
  },
  Fram_DS_5: {
    CGT_Mod_10: CGT_DriversNeeds_Mod10,
    CGT_Mod_2: CGT_DriversNeeds_Mod2,
    CGT_Mod_3: CGT_DriversNeeds_Mod3,
    CGT_Mod_4: CGT_DriversNeeds_Mod4,
    CGT_Mod_6: CGT_DriversNeeds_Mod6,
    CGT_Mod_7: CGT_DriversNeeds_Mod7,
    CGT_Mod_9: CGT_DriversNeeds_Mod9,
  },
  Fram_DS_6: {
    SU_Mod_10: SU_DriversNeeds_Mod10,
    SU_Mod_2: SU_DriversNeeds_Mod2,
    SU_Mod_3: SU_DriversNeeds_Mod3,
    SU_Mod_4: SU_DriversNeeds_Mod4,
    SU_Mod_6: SU_DriversNeeds_Mod6,
    SU_Mod_7: SU_DriversNeeds_Mod7,
    SU_Mod_9: SU_DriversNeeds_Mod9,
  },
  Fram_DS_7: {
    LSG_Mod_10: LSG_DriversNeeds_Mod10,
    LSG_Mod_2: LSG_DriversNeeds_Mod2,
    LSG_Mod_3: LSG_DriversNeeds_Mod3,
    LSG_Mod_4: LSG_DriversNeeds_Mod4,
    LSG_Mod_6: LSG_DriversNeeds_Mod6,
    LSG_Mod_7: LSG_DriversNeeds_Mod7,
    LSG_Mod_9: LSG_DriversNeeds_Mod9,
  },
  Fram_DS_8: {
    IU_Mod_10: IU_DriversNeeds_Mod10,
    IU_Mod_2: IU_DriversNeeds_Mod2,
    IU_Mod_3: IU_DriversNeeds_Mod3,
    IU_Mod_4: IU_DriversNeeds_Mod4,
    IU_Mod_6: IU_DriversNeeds_Mod6,
    IU_Mod_7: IU_DriversNeeds_Mod7,
    IU_Mod_9: IU_DriversNeeds_Mod9,
  },
  Fram_DS_9: {
    RWO_Mod_10: RWO_DriversNeeds_Mod10,
    RWO_Mod_2: RWO_DriversNeeds_Mod2,
    RWO_Mod_3: RWO_DriversNeeds_Mod3,
    RWO_Mod_4: RWO_DriversNeeds_Mod4,
    RWO_Mod_6: RWO_DriversNeeds_Mod6,
    RWO_Mod_7: RWO_DriversNeeds_Mod7,
    RWO_Mod_9: RWO_DriversNeeds_Mod9,
  },
  Fram_DS_10: {
    TC_Mod_10: TC_DriversNeeds_Mod10,
    TC_Mod_2: TC_DriversNeeds_Mod2,
    TC_Mod_3: TC_DriversNeeds_Mod3,
    TC_Mod_4: TC_DriversNeeds_Mod4,
    TC_Mod_6: TC_DriversNeeds_Mod6,
    TC_Mod_7: TC_DriversNeeds_Mod7,
    TC_Mod_9: TC_DriversNeeds_Mod9,
  },
  Fram_DS_11: {
    SS_Mod_10: SS_DriversNeeds_Mod10,
    SS_Mod_2: SS_DriversNeeds_Mod2,
    SS_Mod_3: SS_DriversNeeds_Mod3,
    SS_Mod_4: SS_DriversNeeds_Mod4,
    SS_Mod_6: SS_DriversNeeds_Mod6,
    SS_Mod_7: SS_DriversNeeds_Mod7,
    SS_Mod_9: SS_DriversNeeds_Mod9,
  },
  Fram_DS_12: {
    ID_Mod_10: ID_DriversNeeds_Mod10,
    ID_Mod_2: ID_DriversNeeds_Mod2,
    ID_Mod_3: ID_DriversNeeds_Mod3,
    ID_Mod_4: ID_DriversNeeds_Mod4,
    ID_Mod_6: ID_DriversNeeds_Mod6,
    ID_Mod_7: ID_DriversNeeds_Mod7,
    ID_Mod_9: ID_DriversNeeds_Mod9,
  },
  Fram_DS_13: {
    EM_Mod_10: EM_DriversNeeds_Mod10,
    EM_Mod_2: EM_DriversNeeds_Mod2,
    EM_Mod_3: EM_DriversNeeds_Mod3,
    EM_Mod_4: EM_DriversNeeds_Mod4,
    EM_Mod_6: EM_DriversNeeds_Mod6,
    EM_Mod_7: EM_DriversNeeds_Mod7,
    EM_Mod_9: EM_DriversNeeds_Mod9,
  },
},
consumption: {
  Fram_DS_5: {
    front: CGT_Consumption_Front,
    back: CGT_Consumption_Back,
  },
  Fram_DS_1: {
    front: EH_Consumption_Front,
    back: EH_Consumption_Back,
  },
  Fram_DS_2: {
    front: RL_Consumption_Front,
    back: RL_Consumption_Back,
  },
  Fram_DS_3: {
    front: ENO_Consumption_Front,
    back: ENO_Consumption_Back,
  },
  Fram_DS_4: {
    front: USM_Consumption_Front,
    back: USM_Consumption_Back,
  },
  Fram_DS_6: {
    front: SU_Consumption_Front,
    back: SU_Consumption_Back,
  },
  Fram_DS_7: {
    front: LSG_Consumption_Front,
    back: LSG_Consumption_Back,
  },
  Fram_DS_8: {
    front: IU_Consumption_Front,
    back: IU_Consumption_Back,
  },
  Fram_DS_9: {
    front: RWO_Consumption_Front,
    back: RWO_Consumption_Back,
  },
  Fram_DS_10: {
    front: TC_Consumption_Front,
    back: TC_Consumption_Back,
  },
  Fram_DS_11: {
    front: SS_Consumption_Front,
    back: SS_Consumption_Back,
  },
  Fram_DS_12: {
    front: ID_Consumption_Front,
    back: ID_Consumption_Back,
  },
  Fram_DS_13: {
    front: EM_Consumption_Front,
    back: EM_Consumption_Back,
  },
},
howTheyDrink: {
  Fram_DS_5: {
    avatars: [
      CGT_Carousel3_Avatar,
      CGT_Carousel1_Avatar,
      CGT_Carousel4_Avatar,
      CGT_Carousel2_Avatar,
      CGT_Carousel5_Avatar,
      CGT_Carousel6_Avatar,
    ],
    "carousel-0": {
      squareImages: [CGT_Carousel3_SquareImage1, CGT_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, CGT_Carousel3_VideoClip],
    },
    "carousel-1": {
      squareImages: [CGT_Carousel1_SquareImage1, CGT_Carousel1_SquareImage3],
      verticalImage: null,
      videos: [null, CGT_Carousel1_VideoClip],
    },
    "carousel-2": {
      squareImages: [CGT_Carousel4_SquareImage3, CGT_Carousel4_SquareImage1],
      verticalImage: null,
      videos: [null, CGT_Carousel4_VideoClip],
    },
    "carousel-3": {
      squareImages: [CGT_Carousel2_SquareImage1, CGT_Carousel2_SquareImage3],
      verticalImage: null,
      videos: [null, CGT_Carousel2_VideoClip],
    },
    "carousel-4": {
      squareImages: [CGT_Carousel5_SquareImage2, CGT_Carousel5_SquareImage3],
      verticalImage: null,
      videos: [null, CGT_Carousel5_VideoClip],
    },
    "carousel-5": {
      squareImages: [CGT_Carousel6_SquareImage3, CGT_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, CGT_Carousel6_VideoClip],
    },
  },
  Fram_DS_1: {
    avatars: [
      EGH_Carousel1_Avatar,
      EGH_Carousel4_Avatar,
      EGH_Carousel2_Avatar,
      EGH_Carousel5_Avatar,
      EGH_Carousel3_Avatar,
      EGH_Carousel6_Avatar,
    ],
    "carousel-0": {
      squareImages: [EGH_Carousel1_SquareImage2, EGH_Carousel1_SquareImage3],
      verticalImage: null,
      videos: [null, EGH_Carousel1_VideoClip],
    },
    "carousel-1": {
      squareImages: [EGH_Carousel4_SquareImage1, EGH_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, EGH_Carousel4_VideoClip],
    },
    "carousel-2": {
      squareImages: [EGH_Carousel2_SquareImage1, EGH_Carousel2_SquareImage3],
      verticalImage: null,
      videos: [null, EGH_Carousel2_VideoClip],
    },
    "carousel-3": {
      squareImages: [EGH_Carousel5_SquareImage2, EGH_Carousel5_SquareImage3],
      verticalImage: null,
      videos: [null, EGH_Carousel5_VideoClip],
    },
    "carousel-4": {
      squareImages: [EGH_Carousel3_SquareImage1, EGH_Carousel3_SquareImage3],
      verticalImage: null,
      videos: [null, EGH_Carousel3_VideoClip],
    },
    "carousel-5": {
      squareImages: [EGH_Carousel6_SquareImage1, EGH_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, EGH_Carousel6_VideoClip],
    },
  },
  Fram_DS_2: {
    avatars: [
      RL_Carousel1_Avatar,
      RL_Carousel4_Avatar,
      RL_Carousel2_Avatar,
      RL_Carousel5_Avatar,
      RL_Carousel3_Avatar,
      RL_Carousel6_Avatar,
    ],
    "carousel-0": {
      squareImages: [RL_Carousel1_SquareImage1, RL_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, RL_Carousel1_VideoClip],
    },
    "carousel-1": {
      squareImages: [RL_Carousel4_SquareImage1, RL_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, RL_Carousel4_VideoClip],
    },
    "carousel-2": {
      squareImages: [RL_Carousel2_SquareImage1, RL_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, RL_Carousel2_VideoClip],
    },
    "carousel-3": {
      squareImages: [RL_Carousel5_SquareImage1, RL_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, RL_Carousel5_VideoClip],
    },
    "carousel-4": {
      squareImages: [RL_Carousel3_SquareImage1, RL_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, RL_Carousel3_VideoClip],
    },
    "carousel-5": {
      squareImages: [RL_Carousel6_SquareImage1, RL_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, RL_Carousel6_VideoClip],
    },
  },
  Fram_DS_3: {
    avatars: [
      ENO_Carousel5_Avatar,
      ENO_Carousel3_Avatar,
      ENO_Carousel1_Avatar,
      ENO_Carousel7_Avatar,
      ENO_Carousel6_Avatar,
      ENO_Carousel4_Avatar,
      ENO_Carousel2_Avatar,
      ENO_Carousel8_Avatar,
    ],
    "carousel-0": {
      squareImages: [ENO_Carousel5_SquareImage1, ENO_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel5_VideoClip],        
    },
    "carousel-1": {
      squareImages: [ENO_Carousel3_SquareImage1, ENO_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel3_VideoClip],
    },
    "carousel-2": {
      squareImages: [ENO_Carousel1_SquareImage1, ENO_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel1_VideoClip],
    },
    "carousel-3": {
      squareImages: [ENO_Carousel7_SquareImage1, ENO_Carousel7_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel7_VideoClip],
    },
    "carousel-4": {
      squareImages: [ENO_Carousel6_SquareImage1,ENO_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel6_VideoClip],
    },
    "carousel-5": {
      squareImages: [ENO_Carousel4_SquareImage1, ENO_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel4_VideoClip],
    },
    "carousel-6": {
      squareImages: [ENO_Carousel2_SquareImage1, ENO_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel2_VideoClip],
    },
    "carousel-7": {
      squareImages: [ENO_Carousel8_SquareImage1, ENO_Carousel8_SquareImage2],
      verticalImage: null,
      videos: [null, ENO_Carousel8_VideoClip],
    },
  },
  Fram_DS_4: {
    avatars: [
      USM_Carousel1_Avatar,
      USM_Carousel3_Avatar,
      USM_Carousel2_Avatar,
      USM_Carousel4_Avatar,
      USM_Carousel6_Avatar,
      USM_Carousel5_Avatar,
    ],
    "carousel-0": {
      squareImages: [USM_Carousel1_SquareImage1, USM_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, USM_Carousel1_VideoClip],
    },
    "carousel-1": {
      squareImages: [USM_Carousel3_SquareImage1, USM_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, USM_Carousel3_VideoClip],
    },
    "carousel-2": {
      squareImages: [USM_Carousel2_SquareImage1, USM_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, USM_Carousel2_VideoClip],
    },
    "carousel-3": {
      squareImages: [USM_Carousel4_SquareImage1, USM_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, USM_Carousel4_VideoClip],
    },
    "carousel-4": {
      squareImages: [USM_Carousel6_SquareImage1, USM_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, USM_Carousel6_VideoClip],        
    },
    "carousel-5": {
      squareImages: [USM_Carousel5_SquareImage1, USM_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, USM_Carousel5_VideoClip],
    },
  },
  Fram_DS_6: {
    avatars: [
      SU_Carousel1_Avatar,
      SU_Carousel4_Avatar,
      SU_Carousel2_Avatar,
      SU_Carousel5_Avatar,
      SU_Carousel3_Avatar,
      SU_Carousel6_Avatar,
    ],
    "carousel-0": {
      squareImages: [SU_Carousel1_SquareImage1, SU_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, SU_Carousel1_VideoClip],
    },
    "carousel-1": {
      squareImages: [SU_Carousel4_SquareImage1, SU_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, SU_Carousel4_VideoClip],
    },
    "carousel-2": {
      squareImages: [SU_Carousel2_SquareImage1],
      verticalImage: null,
      videos: [null, SU_Carousel2_VideoClip],
    },
    "carousel-3": {
      squareImages: [SU_Carousel5_SquareImage1, SU_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, SU_Carousel5_VideoClip],
    },
    "carousel-4": {
      squareImages: [SU_Carousel3_SquareImage1, SU_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, SU_Carousel3_VideoClip],
    },
    "carousel-5": {
      squareImages: [SU_Carousel6_SquareImage1, SU_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, SU_Carousel6_VideoClip],
    },
  },
  Fram_DS_7: {
    avatars: [
      LSG_Carousel1_Avatar,
      LSG_Carousel4_Avatar,
      LSG_Carousel2_Avatar,
      LSG_Carousel5_Avatar,
      LSG_Carousel3_Avatar,
      LSG_Carousel6_Avatar,
    ],
    "carousel-0": {
      squareImages: [LSG_Carousel1_SquareImage1, LSG_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, LSG_Carousel1_VideoClip],
    },
    "carousel-1": {
      squareImages: [LSG_Carousel4_SquareImage1, LSG_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, LSG_Carousel4_VideoClip],
    },
    "carousel-2": {
      squareImages: [LSG_Carousel2_SquareImage1, LSG_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, LSG_Carousel2_VideoClip],
    },
    "carousel-3": {
      squareImages: [LSG_Carousel5_SquareImage1, LSG_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, LSG_Carousel5_VideoClip],
    },
    "carousel-4": {
      squareImages: [LSG_Carousel3_SquareImage1, LSG_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, LSG_Carousel3_VideoClip],
    },
    "carousel-5": {
      squareImages: [LSG_Carousel6_SquareImage1, LSG_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, LSG_Carousel6_VideoClip],
    },
  },
  Fram_DS_8: {
    avatars: [
      IU_Carousel1_Avatar,
      IU_Carousel4_Avatar,
      IU_Carousel2_Avatar,
      IU_Carousel5_Avatar,
      IU_Carousel3_Avatar,
      IU_Carousel6_Avatar,
    ],
    "carousel-0": {
      squareImages: [IU_Carousel1_SquareImage1, IU_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, IU_Carousel1_VideoClip],
    },
    "carousel-1": {
      squareImages: [IU_Carousel4_SquareImage1, IU_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, IU_Carousel4_VideoClip],
    },
    "carousel-2": {
      squareImages: [IU_Carousel2_SquareImage1, IU_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, IU_Carousel2_VideoClip],
    },
    "carousel-3": {
      squareImages: [IU_Carousel5_SquareImage1, IU_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, IU_Carousel5_VideoClip],
    },
    "carousel-4": {
      squareImages: [IU_Carousel3_SquareImage1, IU_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, IU_Carousel3_VideoClip],
    },
    "carousel-5": {
      squareImages: [IU_Carousel6_SquareImage1, IU_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, IU_Carousel6_VideoClip],
    },
  },
  Fram_DS_9: {
    avatars: [
      RWO_Carousel4_Avatar,
      RWO_Carousel1_Avatar,
      RWO_Carousel5_Avatar,
      RWO_Carousel2_Avatar,
      RWO_Carousel6_Avatar,
      RWO_Carousel3_Avatar,
    ],
    "carousel-0": {
      squareImages: [RWO_Carousel4_SquareImage1, RWO_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, RWO_Carousel4_VideoClip],
    },
    "carousel-1": {
      squareImages: [RWO_Carousel1_SquareImage1, RWO_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, RWO_Carousel1_VideoClip],
    },
    "carousel-2": {
      squareImages: [RWO_Carousel5_SquareImage1, RWO_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, RWO_Carousel5_VideoClip],
    },
    "carousel-3": {
      squareImages: [RWO_Carousel2_SquareImage1, RWO_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, RWO_Carousel2_VideoClip],
    },
    "carousel-4": {
      squareImages: [RWO_Carousel6_SquareImage1,RWO_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null],
    },
    "carousel-5": {
      squareImages: [RWO_Carousel3_SquareImage1, RWO_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, RWO_Carousel3_VideoClip],
    },
  },
  Fram_DS_10: {
    avatars: [
      TC_Carousel4_Avatar,
      TC_Carousel1_Avatar,
      TC_Carousel5_Avatar,
      TC_Carousel2_Avatar,
      TC_Carousel6_Avatar,
      TC_Carousel3_Avatar,
    ],
    "carousel-0": {
      squareImages: [TC_Carousel4_SquareImage1, TC_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, TC_Carousel4_VideoClip],
    },
    "carousel-1": {
      squareImages: [TC_Carousel1_SquareImage1, TC_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, TC_Carousel1_VideoClip],
    },
    "carousel-2": {
      squareImages: [TC_Carousel5_SquareImage1, TC_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, TC_Carousel5_VideoClip],
    },
    "carousel-3": {
      squareImages: [TC_Carousel2_SquareImage1, TC_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, TC_Carousel2_VideoClip],
    },
    "carousel-4": {
      squareImages: [TC_Carousel6_SquareImage1, TC_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, TC_Carousel6_VideoClip],
    },
    "carousel-5": {
      squareImages: [TC_Carousel3_SquareImage1, TC_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, TC_Carousel3_VideoClip],
    },
  },
  Fram_DS_11: {
    avatars: [
      SS_Carousel4_Avatar,
      SS_Carousel1_Avatar,
      SS_Carousel5_Avatar,
      SS_Carousel2_Avatar,
      SS_Carousel6_Avatar,
      SS_Carousel3_Avatar
    ],
    "carousel-0": {
      squareImages: [SS_Carousel4_SquareImage1,SS_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, SS_Carousel4_VideoClip],
    },
    "carousel-1": {
      squareImages: [SS_Carousel1_SquareImage1,SS_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, SS_Carousel1_VideoClip],
    },
    "carousel-2": {
      squareImages: [SS_Carousel5_SquareImage1, SS_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, SS_Carousel5_VideoClip],
    },
    "carousel-3": {
      squareImages: [SS_Carousel2_SquareImage1,SS_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, SS_Carousel2_VideoClip],
    },
    "carousel-4": {
      squareImages: [SS_Carousel6_SquareImage1,SS_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, SS_Carousel6_VideoClip],
    },
    "carousel-5": {
      squareImages: [SS_Carousel3_SquareImage1,SS_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, SS_Carousel3_VideoClip],
    },
  },
  Fram_DS_12: {
    avatars: [
      IDI_Carousel3_Avatar,
      IDI_Carousel4_Avatar,
      IDI_Carousel5_Avatar,
      IDI_Carousel6_Avatar,
      IDI_Carousel2_Avatar,
      IDI_Carousel1_Avatar
    ],
    "carousel-0": {
      squareImages: [IDI_Carousel3_SquareImage1, IDI_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, IDI_Carousel3_VideoClip],
    },
    "carousel-1": {
      squareImages: [IDI_Carousel4_SquareImage1, IDI_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, IDI_Carousel4_VideoClip],
    },
    "carousel-2": {
      squareImages: [IDI_Carousel5_SquareImage1, IDI_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, IDI_Carousel5_VideoClip],
    },
    "carousel-3": {
      squareImages: [IDI_Carousel6_SquareImage1, IDI_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, IDI_Carousel6_VideoClip],
    },
    "carousel-4": {
      squareImages: [IDI_Carousel2_SquareImage1, IDI_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, IDI_Carousel2_VideoClip],
    },
    "carousel-5": {
      squareImages: [IDI_Carousel1_SquareImage1, IDI_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, IDI_Carousel1_VideoClip],
    },
  },
  Fram_DS_13: {
    avatars: [
      EM_Carousel3_Avatar,
      EM_Carousel4_Avatar,
      EM_Carousel5_Avatar,
      EM_Carousel6_Avatar,
      EM_Carousel2_Avatar,
      EM_Carousel1_Avatar,
    ],
    "carousel-0": {
      squareImages: [EM_Carousel3_SquareImage1, EM_Carousel3_SquareImage2],
      verticalImage: null,
      videos: [null, EM_Carousel3_VideoClip],
    },
    "carousel-1": {
      squareImages: [EM_Carousel4_SquareImage1, EM_Carousel4_SquareImage2],
      verticalImage: null,
      videos: [null, EM_Carousel4_VideoClip],
    },
    "carousel-2": {
      squareImages: [EM_Carousel5_SquareImage1, EM_Carousel5_SquareImage2],
      verticalImage: null,
      videos: [null, EM_Carousel5_VideoClip],
    },
    "carousel-3": {
      squareImages: [EM_Carousel6_SquareImage1, EM_Carousel6_SquareImage2],
      verticalImage: null,
      videos: [null, EM_Carousel6_VideoClip],
    },
    "carousel-4": {
      squareImages: [EM_Carousel2_SquareImage1, EM_Carousel2_SquareImage2],
      verticalImage: null,
      videos: [null, EM_Carousel2_VideoClip],
    },
    "carousel-5": {
      squareImages: [EM_Carousel1_SquareImage1, EM_Carousel1_SquareImage2],
      verticalImage: null,
      videos: [null, EM_Carousel1_VideoClip],
    },
  },
},
};
