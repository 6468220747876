import { Box, Grid } from '@mui/material'
import React from 'react'
import SmallQuoteAttributionCard from './SmallQuoteAttributionCard'
import VideoCard from './VideoCard'

export default function BigQuoteVideoRightCard({ theme, dataprops }) {
    const hasVideoData = !!dataprops.videocarddata.video; // Check if video card data is present

    return (
        <Box className='bigquotevideoright-card-container'>
            <Grid container className='card-content-container'>
                <Grid item xs={hasVideoData ? 6 : 12} maxHeight="31.25rem" >
                    <SmallQuoteAttributionCard theme={theme} cardData={dataprops.quoteattributioncarddata} />
                </Grid>
                {hasVideoData && (
                    <Grid item xs={6} maxHeight="31.25rem">
                        <VideoCard theme={theme} cardData={dataprops.videocarddata} />
                    </Grid>
                )}
                {/* <Grid item xs={6} maxHeight="31.25rem">
                    <VideoCard theme={theme} cardData={dataprops.videocarddata} />
                </Grid> */}
            </Grid>
        </Box>
    )
}
