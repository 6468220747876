import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const PriceBrandTable = ({
  drinkPriceIndex,
  drinkPricePercent,
  drinkPriceBrand,
  showIndex
}) => {
  function getColor(value) {
    if (value < 85) {
      return "negative";
    } else if (value > 115) {
      return "positive";
    } else {
      return "neutral";
    }
  }
  return (
    <>
      <Box className="price-tier-table-data">
        <Box className="price-tier-beertype">
          <Typography className="price-tier-beertype-typo">
            {drinkPriceBrand}
          </Typography>
        </Box>
        { showIndex && 
          <Box className="price-tier-percent">
            <Typography className="price-tier-percent-typo">
              {drinkPricePercent}%
            </Typography>
            <Box className={`price-tier-index ${getColor(drinkPriceIndex)}`}>
              <Typography className="price-tier-index-typo">
                {drinkPriceIndex}
              </Typography>
            </Box>
          </Box>
        }
      </Box>
      <Divider className="price-tier-divider"></Divider>
    </>
  );
};

export default PriceBrandTable;
