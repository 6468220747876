import { Box } from "@mui/material";
import React, { useEffect } from "react";
import DownloadResources from "../../common/page-sections/DownloadResources";
import { peopleSegmentTemplateDownloadItems } from "../../../constants/PeopleSegment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useParams } from "react-router-dom";

function SectionEight() {
  const { t } = useTranslation(i18next.language);
  const { microSegment } = useParams();

  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  let downloadItems = peopleSegmentTemplateDownloadItems[microSegment].map(
    (item) => {
      return {
        title: t(item.title, {
          micro: t(`people-segment.type.${microSegment}`),
        }),
        subtitle: t(item.subtitle, {
          micro: t(`people-segment.type.${microSegment}`),
        }),
        multipleFiles: item.multipleFiles,
        fileToDownload: item.fileToDownload,
      };
    }
  );

  return (
    <div className="section8">
      <Box sx={{ flexGrow: 1 }}>
        <DownloadResources listItems={downloadItems} />
      </Box>
    </div>
  );
}

export default SectionEight;
