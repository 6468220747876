import { Box, Typography } from "@mui/material";
import React from "react";

const HorizontalBarChart = ({ data = [], title, color = "" }) => {
  function getIndexColor(indexValue) {
    if (indexValue < 85) return "negative";
    else if (indexValue > 115) return "positive";
    else return "neutral";
  }

  const generateCategoryContent = (category, property) => {
    // Sorting the data in descending order based on the percentage
    // const sortedData = data
    //   .slice()
    //   .sort(
    //     (a, b) =>
    //       parseFloat(b[`${category}Percent`]) -
    //       parseFloat(a[`${category}Percent`])
    //   );

    return (
      <Box className="horizontal-main">
        <Typography className="horizontal-heading">{title}</Typography>
        <Typography className="horizontal-line" />
        {data?.map((item, index) => (
          <Box key={index} className="horizontal-data">
            <Box className="horizontal-bar">
              <Typography className="horizontal-name">
                {item[`${category}${property}`]}
              </Typography>
              <Box className="horizontal-end">
                <Typography className="horizontal-percent">
                  {Math.round(item[`${category}Percent`])}%
                </Typography>
                <Typography
                  className={`horizontal-index ${getIndexColor(
                    item[`${category}Index`]
                  )}`}
                >
                  {Math.round(item[`${category}Index`])}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "4px",
                background: `linear-gradient(to right, ${color} ${
                  item[`${category}Percent`]
                }%, rgba(255, 255, 255, 0.20) 5px )`,
              }}
            ></Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      {title === "Ethnicity" && generateCategoryContent("ethnicity", "Name")}
      {title === "Income" && generateCategoryContent("income", "Category")}
      {title === "Living Area" &&
        generateCategoryContent("livingArea", "Category")}
    </>
  );
};

export default HorizontalBarChart;
