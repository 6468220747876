import { Box, Grid } from "@mui/material";
import React from "react";
import CustomCard from "../../custom/Cards/CustomCard";
import { occasionsMacroMapping } from '../../../constants/OccasionsConstants'

function SectionThree() {

  return (
    <Box
      className="section3-background"
    >
      <Box className="ugc-container">
        <Grid container spacing={4}>
          {Object.keys(occasionsMacroMapping).slice(0, 2)
            .map((item, index) => (
              <React.Fragment key={item}>
            <CustomCard
              keyId={`OccasionsHub-Section3-MacroOccasionGroup-${index}`}
              index={index}
              cards={occasionsMacroMapping[item]}
              heading={item}
            />
            </React.Fragment>
          ))}
        </Grid>
        <Grid container spacing={4}>
          {Object.keys(occasionsMacroMapping).slice(2, 4)
            .map((item, index) => (
              <React.Fragment key={item}>
            <CustomCard
              keyId={`OccasionsHub-Section3-MacroOccasionGroup-${index+2}`}
              index={index}
              cards={occasionsMacroMapping[item]}
              heading={item}
            />
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default SectionThree;
