import React from "react";
import { ReactComponent as QuoteIcon } from "../../../assets/images/Common/quotemarks-icon.svg";

import { Box, Divider, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";

function SmallQuoteAttributionCard({ theme, cardData }) {
  const { t } = useTranslation(i18next.language);
  const attributions = t(cardData.attribution).split("|");
  return (
    <Box className={`smallquote-attribution-card-container ${theme}`}>
      <Box className="smallquote-attribution-card-content-container">
        <QuoteIcon className={`quote-icon ${theme}`} />
        <Typography className={`smallquote-card-text ${theme}`}>
          <Trans>
            {t(cardData.quote)}
          </Trans>
        </Typography>
        <Box className={`card-attribution-container ${theme}`}>
          {cardData.name && (
            <Typography className={`attribution-name ${theme}`}>
              {t(cardData.name)}
            </Typography>
          )}
          {cardData.micro && (
            <Typography className={`attribution-micro ${theme}`}>
              {`- ${t(cardData.micro)}`}
            </Typography>
          )}
          <Box className="attribution-items">
            {attributions.map((attributionItem, index) => (
              <React.Fragment key={attributionItem}>
                <Typography className={`attribution ${theme} ${attributionItem.toLowerCase().replace(" ", "")}`}>
                  {attributionItem}
                </Typography>
                {index !== attributions.length - 1 && (
                  <Divider
                    orientation="vertical"
                    className={`attribution-divider ${theme}`}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SmallQuoteAttributionCard;
