// Hub Images
const hubHeroImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/hub/images/PS-Hub-HeroImage.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
// Whats in people segment
const PS_WhatsInPeopleSegment_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/hub/images/whats-in-people-segment/PS_WhatsInPeopleSegment_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PS_WhatsInPeopleSegment_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/hub/images/whats-in-people-segment/PS_WhatsInPeopleSegment_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

// Template Images
//Hero Images
const comfortCraversHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/CC_Hero.jpg?${localStorage.getItem("sasKey")}`;
const loyalTraditionalistsHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/LT_Hero.jpg?${localStorage.getItem("sasKey")}`;
const balancedEnjoyersHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/BE_Hero.jpg?${localStorage.getItem("sasKey")}`;
const carefreeFunLoversHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/CFL_Hero.jpg?${localStorage.getItem("sasKey")}`;
const diligentDiscoverersHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/DD_Hero.jpg?${localStorage.getItem("sasKey")}`;
const proudCelebratorsHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/PC_Hero.jpg?${localStorage.getItem("sasKey")}`;
const creativeExplorersHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/CE_Hero.jpg?${localStorage.getItem("sasKey")}`;
const socialCuratorsHero = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/common/SC_Hero.jpg?${localStorage.getItem("sasKey")}`;

//Intro
const CC_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/comfort-cravers/video/CC_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const CC_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/comfort-cravers/video/CC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const BE_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/balanced-enjoyers/video/BE_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const BE_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/balanced-enjoyers/video/BE_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const CFL_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/carefree-fun-lovers/video/CFL_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const CFL_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/carefree-fun-lovers/video/CFL_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const CE_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/creative-explorers/video/CE_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const CE_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/creative-explorers/video/CE_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const DD_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/diligent-discoverers/video/DD_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const DD_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/diligent-discoverers/video/DD_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const LT_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/loyal-traditionalists/video/LT_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const LT_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/loyal-traditionalists/video/LT_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const PC_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/proud-celebrators/video/PC_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const PC_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/proud-celebrators/video/PC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const SC_FeaturedPerson_IntroVideo = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/social-curators/video/SC_FeaturedPerson_IntroVideo.mp4?${localStorage.getItem("sasKey")}`;
const SC_FeaturedPerson_IntroVideo_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/social-curators/video/SC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${localStorage.getItem("sasKey")}`;

// Demographics
const CC_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/comfort-cravers/images/CC_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/comfort-cravers/images/CC_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/loyal-traditionalists/images/LT_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/loyal-traditionalists/images/LT_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/balanced-enjoyers/images/BE_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/balanced-enjoyers/images/BE_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/carefree-fun-lovers/images/CFL_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/carefree-fun-lovers/images/CFL_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/diligent-discoverers/images/DD_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/diligent-discoverers/images/DD_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/proud-celebrators/images/PC_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/proud-celebrators/images/PC_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/creative-explorers/images/CE_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/creative-explorers/images/CE_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Demographics_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/social-curators/images/SC_Demographics_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Demographics_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/demographics/social-curators/images/SC_Demographics_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

//What drives them
const CC_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_WhatDrivesThem_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_WhatDrivesThem_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_WhatDrivesThem_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_WhatDrivesThem_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

//What they drink
const CC_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/audio/CC_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const CC_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/video/CC_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const CC_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/video/CC_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

const LT_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/audio/LT_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const LT_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/video/LT_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const LT_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/video/LT_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

const BE_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/audio/BE_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const BE_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/video/BE_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const BE_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/video/BE_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

const CFL_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/audio/CFL_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const CFL_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/video/CFL_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const CFL_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/video/CFL_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

const DD_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/audio/DD_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const DD_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/video/DD_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const DD_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/video/DD_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

const PC_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/audio/PC_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const PC_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/video/PC_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const PC_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/video/PC_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

const CE_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/audio/CE_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const CE_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/video/CE_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const CE_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/video/CE_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

const SC_WhatTheyDrink_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_WhatTheyDrink_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_WhatTheyDrink_Carousel1_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel1_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_WhatTheyDrink_Carousel2_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_WhatTheyDrink_Carousel3_Image = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel3_Image.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_WhatTheyDrink_Audio = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/audio/SC_WhatTheyDrink_Audio.mp3?${localStorage.getItem("sasKey")}`
const SC_WhatTheyDrink_Video1 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/video/SC_WhatTheyDrink_Video1.mp4?${localStorage.getItem("sasKey")}`
const SC_WhatTheyDrink_Video2 = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/video/SC_WhatTheyDrink_Video2.mp4?${localStorage.getItem("sasKey")}`

//What drives them ModularContent
//Comfort Cravers
const CC_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_PropensityToExplore_5B = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_5B.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_PropensityToExplore_5C = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_5C.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Health = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Health.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Sociability_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_PropensityToExplore_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;
const CC_Sociability_5A_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2c4ca2c6ebf54583833d9115e7e8245b%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=2c4ca2c6ebf54583833d9115e7e8245b&alt=media&optimized=true`;
const CC_Sociability_5B_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8bf8e481f5a34865b541784daa3a040c%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=8bf8e481f5a34865b541784daa3a040c&alt=media&optimized=true`;
const CC_Sociability_5C_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5C.mp4?${localStorage.getItem("sasKey")}`;
const CC_Sociability_5D_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5D.mp4?${localStorage.getItem("sasKey")}`;

const CC_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CC_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CC_Sociability_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CC_Sociability_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CC_Sociability_5D_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5D_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

//Loyal Traditionalists
const LT_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_PropensityToExplore_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_PropensityToExplore_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Health = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Health.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Sociability_5B = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5B.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Sociability_5C = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5C.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Sociability_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_PropensityToExplore_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;
const LT_PropensityToExplore_5B_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb7c5f608fa694d389793103b67f84eee%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=b7c5f608fa694d389793103b67f84eee&alt=media&optimized=true`;
const LT_PropensityToExplore_5C_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F77a2dc05432e48dca69720789fa0cf3b%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=77a2dc05432e48dca69720789fa0cf3b&alt=media&optimized=true`;
const LT_Sociability_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_Sociability_5A.mp4?${localStorage.getItem("sasKey")}`;
//thumbnails
const LT_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const LT_PropensityToExplore_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const LT_PropensityToExplore_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const LT_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;


// Balanced Enjoyers
const BE_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_PropensityToExplore_5C = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_5C.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Health = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Health.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Sociability_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_PropensityToExplore_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;
const BE_PropensityToExplore_5B_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1a69e80846cf45b39eb6ce33dcf7f9ce%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=1a69e80846cf45b39eb6ce33dcf7f9ce&alt=media&optimized=true`;
const BE_PropensityToExplore_5D_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5D.mp4?${localStorage.getItem("sasKey")}`;

const BE_Sociability_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5A.mp4?${localStorage.getItem("sasKey")}`;
const BE_Sociability_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5B.mp4?${localStorage.getItem("sasKey")}`;
const BE_Sociability_5C_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/Be_Sociability_5C.mp4?${localStorage.getItem("sasKey")}`;
//thumbnails
const BE_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const BE_PropensityToExplore_5B_Video_thumbnail = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F849a090961c041f6987c9875fe88f97f`;
const BE_PropensityToExplore_5D_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5D_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const BE_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const BE_Sociability_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const BE_Sociability_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;


// Diligent Discoverers
const DD_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_PropensityToExplore_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_PropensityToExplore_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Sociability_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Sociability_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_PropensityToExplore_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;
const DD_PropensityToExplore_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5B.mp4?${localStorage.getItem("sasKey")}`;
const DD_PropensityToExplore_5C_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5C.mp4?${localStorage.getItem("sasKey")}`;
const DD_Sociability_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5A.mp4?${localStorage.getItem("sasKey")}`;
const DD_Sociability_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5B.mp4?${localStorage.getItem("sasKey")}`;
const DD_Sociability_5C_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5C.mp4?${localStorage.getItem("sasKey")}`;
//thumbnails
const DD_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const DD_PropensityToExplore_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const DD_PropensityToExplore_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const DD_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const DD_Sociability_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const DD_Sociability_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;


// Carefree Fun-Lovers
const CFL_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_PropensityToExplore_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Health = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Health.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Sociability_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Sociability_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_PropensityToExplore_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;
// const CFL_PropensityToExplore_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5B.mp4?${localStorage.getItem("sasKey")}`;
const CFL_PropensityToExplore_5C_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7bb759795c46416983468045359bf03d%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=7bb759795c46416983468045359bf03d&alt=media&optimized=true`;
const CFL_PropensityToExplore_5D_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5D.mp4?${localStorage.getItem("sasKey")}`;

const CFL_Sociability_5A_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fe71282aaad454a6fb66bfcc71994ec26%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=e71282aaad454a6fb66bfcc71994ec26&alt=media&optimized=true`;
const CFL_Sociability_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5B.mp4?${localStorage.getItem("sasKey")}`;
const CFL_Sociability_5C_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff33e3ee2e765400c9f5abc04e4d1f4a1%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=f33e3ee2e765400c9f5abc04e4d1f4a1&alt=media&optimized=true`;
//thumbnails
const CFL_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CFL_PropensityToExplore_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CFL_PropensityToExplore_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CFL_PropensityToExplore_5D_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5D_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const CFL_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CFL_Sociability_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CFL_Sociability_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;


// Proud Celebrators
const PC_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_PropensityToExplore_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Health = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Health.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Sociability_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Sociability_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_PropensityToExplore_5A_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff72294abd4b94b5b8310fd8cf98a5f8f%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=f72294abd4b94b5b8310fd8cf98a5f8f&alt=media&optimized=true`;
const PC_PropensityToExplore_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropenstiyToExplore_5B.mp4?${localStorage.getItem("sasKey")}`;
const PC_PropensityToExplore_5C_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0eef85836cea44c2a5ff836e698d347d%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=0eef85836cea44c2a5ff836e698d347d&alt=media&optimized=true`;
const PC_PropensityToExplore_5D_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F07bddc2ccb6e44bc87d310849b9fae8c%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=07bddc2ccb6e44bc87d310849b9fae8c&alt=media&optimized=true`;

const PC_Sociability_5A_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd61d25566c7a44a7adc983e898ded0c1%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=d61d25566c7a44a7adc983e898ded0c1&alt=media&optimized=true`;
const PC_Sociability_5B_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6786725400214180976b32907749b290%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=6786725400214180976b32907749b290&alt=media&optimized=true`;
const PC_Sociability_5C_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5C.mp4?${localStorage.getItem("sasKey")}`;
//thumbnails
const PC_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const PC_PropensityToExplore_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const PC_PropensityToExplore_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const PC_PropensityToExplore_5D_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5D_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const PC_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const PC_Sociability_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const PC_Sociability_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;


// Creative Explorers
const CE_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_PropensityToExplore_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_PropensityToExplore_5C = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_5C.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_Health = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Health.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_PropensityToExplore_5B_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc35647ac7e2e44d789fa973e00b52cc2%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=c35647ac7e2e44d789fa973e00b52cc2&alt=media&optimized=true`;
const CE_PropensityToExplore_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;

const CE_Sociability_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5A.mp4?${localStorage.getItem("sasKey")}`;
const CE_Sociability_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5B.mp4?${localStorage.getItem("sasKey")}`;
const CE_Sociability_5C_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5403fca56296468fa413e3e1683a2ea5%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=5403fca56296468fa413e3e1683a2ea5&alt=media&optimized=true`;
const CE_Sociability_5D_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5D.mp4?${localStorage.getItem("sasKey")}`;
//thumbnails
const CE_PropensityToExplore_5B_Video_thumbnail = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feeef22e088234ee7bb4bcce00714d18c`;
const CE_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const CE_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CE_Sociability_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CE_Sociability_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const CE_Sociability_5D_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5D_thumbnail.jpg?${localStorage.getItem("sasKey")}`;


// Social Curators
const SC_Mod_PropensityToExplore_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_PropensityToExplore_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_PropensityToExplore_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_PropensityToExplore_5D = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_5D.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_PropensityToExplore_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Health = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Health.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Outlook = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Outlook.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Sociability_2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_2.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Sociability_3 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_3.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Sociability_4 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_4.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Sociability_5E = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_5E.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Spending = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Spending.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Mod_Status = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Status.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_PropensityToExplore_5A_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;
const SC_PropensityToExplore_5B_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5B.mp4?${localStorage.getItem("sasKey")}`;
const SC_PropensityToExplore_5C_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5C.mp4?${localStorage.getItem("sasKey")}`;

const SC_Sociability_5A_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc7e485a6f932404cbc777830ac213aad%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=c7e485a6f932404cbc777830ac213aad&alt=media&optimized=true`;
const SC_Sociability_5B_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1055c7c718bb47db9bbaf69983262a7d%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=1055c7c718bb47db9bbaf69983262a7d&alt=media&optimized=true`;
const SC_Sociability_5C_Video = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5C.mp4?${localStorage.getItem("sasKey")}`;
const SC_Sociability_5D_Video = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff08345d3dff34edca515fc5c60e32eb9%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=f08345d3dff34edca515fc5c60e32eb9&alt=media&optimized=true`;
//thumbnails
const SC_PropensityToExplore_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const SC_PropensityToExplore_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const SC_PropensityToExplore_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

const SC_Sociability_5A_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const SC_Sociability_5B_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const SC_Sociability_5C_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
const SC_Sociability_5D_Video_thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5D_thumbnail.jpg?${localStorage.getItem("sasKey")}`;


//Meet the people

//Comfort Cravers
const CC_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_MeetThePeople_front.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_MeetThePeople_back.png?${localStorage.getItem(
    "sasKey"
  )}`;

const CC_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel1_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel1_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel1_VideoRight = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0688c780ceb24b299b0d88975e634289%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=0688c780ceb24b299b0d88975e634289&alt=media&optimized=true`;

const CC_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel2_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6862740bc9814d66ae83e0b98f8eb86c%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=6862740bc9814d66ae83e0b98f8eb86c&alt=media&optimized=true`;
const CC_Carousel2_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel2_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CC_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel3_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa8eb3ce06cdd4173afa1d4d0217a5279%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=a8eb3ce06cdd4173afa1d4d0217a5279&alt=media&optimized=true`;
const CC_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CC_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel4_VideoLeft_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoLeft_Thumbnail.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CC_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CC_Carousel5_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F139c8c2fba814282a328c59d1ee5f5b8%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=139c8c2fba814282a328c59d1ee5f5b8&alt=media&optimized=true`;
const CC_Carousel5_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel5_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

//Loyal Tradionalists
const LT_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_MeetThePeople_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_MeetThePeople_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const LT_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel1_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel1_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel1_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel1_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const LT_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel2_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel2_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel2_VideoRight = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc21f511be0d847fb8bddb1ef076e9801%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=c21f511be0d847fb8bddb1ef076e9801&alt=media&optimized=true`;

const LT_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel3_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbf849854e97f43329df3cdbb9c829126%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=bf849854e97f43329df3cdbb9c829126&alt=media&optimized=true`;
const LT_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const LT_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel4_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const LT_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const LT_Carousel5_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd6db68f947c94b73833480377f8218e5%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=d6db68f947c94b73833480377f8218e5&alt=media&optimized=true`;
const LT_Carousel5_VideoRight = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fad34e328e26440bcb484de530c406da1%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=ad34e328e26440bcb484de530c406da1&alt=media&optimized=true`;

//Balanced Enjoyers
const BE_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_MeetThePeople_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_MeetThePeople_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const BE_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel1_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd5f20b99844d49a99d4b62437aa3dde0%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=d5f20b99844d49a99d4b62437aa3dde0&alt=media&optimized=true`;
const BE_Carousel1_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel1_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const BE_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel2_SquareImage2 = `https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0b97590d535b4a4aa98b996162c881c9`;
const BE_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel2_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel2_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel2_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel2_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const BE_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel3_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel3_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const BE_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel4_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const BE_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel5_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel5_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const BE_Carousel5_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel5_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

//Diligent Discoverers
const DD_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_MeetThePeople_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_MeetThePeople_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const DD_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel1_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel1_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel1_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel1_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const DD_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel2_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9b4616800ff84a198fd52cf3fc386125%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=9b4616800ff84a198fd52cf3fc386125&alt=media&optimized=true`;
const DD_Carousel2_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel2_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const DD_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel3_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel3_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const DD_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel4_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const DD_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const DD_Carousel5_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fcb2d006bc5c848b5b4152511212bb70f%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=cb2d006bc5c848b5b4152511212bb70f&alt=media&optimized=true`;
const DD_Carousel5_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel5_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

//Carefree Fun Lovers
const CFL_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_MeetThePeople_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_MeetThePeople_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const CFL_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel1_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb562ececfccf47faae3b71befd6592ea%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=b562ececfccf47faae3b71befd6592ea&alt=media&optimized=true`;
const CFL_Carousel1_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel1_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel1_VideoRight_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel1_VideoRight_Thumbnail.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const CFL_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel2_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel2_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel2_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel2_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CFL_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel3_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel3_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CFL_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
// const CFL_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel4_VideoLeft.mp4?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CFL_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CFL_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel5_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel5_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CFL_Carousel5_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel5_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

//Proud Celebrators
const PC_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_MeetThePeople_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_MeetThePeople_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const PC_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel1_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel1_VideoLeft_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoLeft_Thumbnail.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel1_VideoRight = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F61241220080f4a2a96017abe40e25f6e%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=61241220080f4a2a96017abe40e25f6e&alt=media&optimized=true`;
const PC_Carousel1_VideoRight_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoRight_Thumbnail.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const PC_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel2_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel2_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel2_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel2_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const PC_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel3_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel3_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const PC_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel4_VideoLeft_Thumbnail = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoLeft_Thumbnail.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const PC_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel5_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel5_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const PC_Carousel5_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel5_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

//Creative Explorers
const CE_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_MeetThePeople_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_MeetThePeople_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const CE_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel1_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel1_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel1_VideoRight = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbe41326395e749d68e8d70b420e9d301%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=be41326395e749d68e8d70b420e9d301&alt=media&optimized=true`;

const CE_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel2_VideoLeft = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7123d8d515764c4082c5fa71c5e9e883%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=7123d8d515764c4082c5fa71c5e9e883&alt=media&optimized=true`;
const CE_Carousel2_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel2_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CE_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel3_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel3_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CE_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
// const CE_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_VerticalImage.png?${localStorage.getItem(
//     "sasKey"
//   )}`;
const CE_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel4_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const CE_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel5_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel5_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const CE_Carousel5_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel5_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

//Social Curators
const SC_MeetThePeople_front = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_MeetThePeople_front.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_MeetThePeople_back = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_MeetThePeople_back.jpg?${localStorage.getItem(
    "sasKey"
  )}`;

const SC_Carousel1_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel1_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel1_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel1_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_VideoImage1.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel1_VideoImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel1_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel1_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const SC_Carousel2_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel2_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel2_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel2_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel2_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel2_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel2_VideoRight = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F939498076c454da7b4539bf2e192a874%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=939498076c454da7b4539bf2e192a874&alt=media&optimized=true`;

const SC_Carousel3_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel3_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel3_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel3_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel3_VideoImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_VideoImage1.jpg?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel3_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel3_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const SC_Carousel4_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel4_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel4_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel4_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel4_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel4_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel4_VideoRight = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel4_VideoRight.mp4?${localStorage.getItem(
    "sasKey"
  )}`;

const SC_Carousel5_Avatar = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_Avatar.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel5_SquareImage1 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_SquareImage1.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel5_SquareImage2 = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_SquareImage2.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel5_VerticalImage = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_VerticalImage.png?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel5_VideoLeft = `${process.env.REACT_APP_AZURE_STORAGE_URL
  }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel5_VideoLeft.mp4?${localStorage.getItem(
    "sasKey"
  )}`;
const SC_Carousel5_VideoRight = `https://cdn.builder.io/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc4206048d5274e91ac335a9569a75503%2Fcompressed?apiKey=2dcadbf627b34cb5811ae15b698d7ebb&token=c4206048d5274e91ac335a9569a75503&alt=media&optimized=true`;



export const peopleSegmentAssets = {
  // Fram_PSGMT_2 : "Comfort Cravers",
  // Fram_PSGMT_3 : "Loyal Traditionalists",
  // Fram_PSGMT_5 : "Balanced Enjoyers",
  // Fram_PSGMT_6 : "Diligent Discoverer",
  // Fram_PSGMT_8 : "Carefree Fun Lovers",
  // Fram_PSGMT_9 : "Proud Celebrators",
  // Fram_PSGMT_11 : "Creative Explorers",
  // Fram_PSGMT_12 : "Social Curators",
  hubHeroImage: hubHeroImage,
  heroImages: {
    Fram_PSGMT_2: comfortCraversHero, //link to CC hero image
    Fram_PSGMT_3: loyalTraditionalistsHero, //link to LT hero image
    Fram_PSGMT_5: balancedEnjoyersHero, //link to BE hero image
    Fram_PSGMT_6: diligentDiscoverersHero, //link to DD hero image
    Fram_PSGMT_8: carefreeFunLoversHero, //link to CFL hero image
    Fram_PSGMT_9: proudCelebratorsHero, //link to PC hero image
    Fram_PSGMT_11: creativeExplorersHero, //link to CE hero image
    Fram_PSGMT_12: socialCuratorsHero, //link to SC hero image
  },
  whatsInPeopleSegment: {
    front: PS_WhatsInPeopleSegment_front,
    back: PS_WhatsInPeopleSegment_back,
  },
  intro: {
    Fram_PSGMT_2: {
      //Comfort Cravers
      featuredPersonVideo: CC_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: CC_FeaturedPerson_IntroVideo_Thumbnail
    },
    Fram_PSGMT_3: {
      //Loyal Traditionalists
      featuredPersonVideo: LT_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: LT_FeaturedPerson_IntroVideo_Thumbnail
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      featuredPersonVideo: BE_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: BE_FeaturedPerson_IntroVideo_Thumbnail
    },
    Fram_PSGMT_6: {
      //Diligent Discoverer
      featuredPersonVideo: DD_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: DD_FeaturedPerson_IntroVideo_Thumbnail
    },
    Fram_PSGMT_8: {
      //Carefree Fun Lovers
      featuredPersonVideo: CFL_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: CFL_FeaturedPerson_IntroVideo_Thumbnail
    },
    Fram_PSGMT_9: {
      //Proud Celebrators
      featuredPersonVideo: PC_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: PC_FeaturedPerson_IntroVideo_Thumbnail
    },
    Fram_PSGMT_11: {
      //Creative Explorers
      featuredPersonVideo: CE_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: CE_FeaturedPerson_IntroVideo_Thumbnail
    },
    Fram_PSGMT_12: {
      //Social Curators
      featuredPersonVideo: SC_FeaturedPerson_IntroVideo,
      featuredPersonVideoThumbnail: SC_FeaturedPerson_IntroVideo_Thumbnail
    }
  },
  whatDrivesThem: {
    Fram_PSGMT_2: {
      //Comfort Cravers
      front: CC_WhatDrivesThem_front,
      back: CC_WhatDrivesThem_back,
      CC_Mod_PropensityToExplore_2: CC_Mod_PropensityToExplore_2,
      CC_Mod_PropensityToExplore_3: CC_Mod_PropensityToExplore_3,
      CC_Mod_PropensityToExplore_4: CC_Mod_PropensityToExplore_4,
      CC_Mod_PropensityToExplore_5B: CC_Mod_PropensityToExplore_5B,
      CC_Mod_PropensityToExplore_5C: CC_Mod_PropensityToExplore_5C,
      CC_Mod_Health: CC_Mod_Health,
      CC_Mod_Outlook: CC_Mod_Outlook,
      CC_Mod_Sociability_2: CC_Mod_Sociability_2,
      CC_Mod_Sociability_3: CC_Mod_Sociability_3,
      CC_Mod_Sociability_4: CC_Mod_Sociability_4,
      CC_Mod_Sociability_5E: CC_Mod_Sociability_5E,
      CC_Mod_Spending: CC_Mod_Spending,
      CC_Mod_Status: CC_Mod_Status,
      CC_PropensityToExplore_5A_Video: CC_PropensityToExplore_5A_Video,
      CC_Sociability_5A_Video: CC_Sociability_5A_Video,
      CC_Sociability_5B_Video: CC_Sociability_5B_Video,
      CC_Sociability_5C_Video: CC_Sociability_5C_Video,
      CC_Sociability_5D_Video: CC_Sociability_5D_Video,
      CC_PropensityToExplore_5A_Video_thumbnail: CC_PropensityToExplore_5A_Video_thumbnail,
      CC_Sociability_5A_Video_thumbnail: CC_Sociability_5A_Video_thumbnail,
      CC_Sociability_5B_Video_thumbnail: CC_Sociability_5B_Video_thumbnail,
      CC_Sociability_5C_Video_thumbnail: CC_Sociability_5C_Video_thumbnail,
      CC_Sociability_5D_Video_thumbnail: CC_Sociability_5D_Video_thumbnail
    },
    Fram_PSGMT_3: {
      //Loyal Traditionalists
      front: LT_WhatDrivesThem_front,
      back: LT_WhatDrivesThem_back,
      LT_Mod_PropensityToExplore_2: LT_Mod_PropensityToExplore_2,
      LT_Mod_PropensityToExplore_3: LT_Mod_PropensityToExplore_3,
      LT_Mod_PropensityToExplore_4: LT_Mod_PropensityToExplore_4,
      LT_Mod_PropensityToExplore_5D: LT_Mod_PropensityToExplore_5D,
      LT_Mod_PropensityToExplore_5E: LT_Mod_PropensityToExplore_5E,
      LT_Mod_Health: LT_Mod_Health,
      LT_Mod_Outlook: LT_Mod_Outlook,
      LT_Mod_Sociability_2: LT_Mod_Sociability_2,
      LT_Mod_Sociability_3: LT_Mod_Sociability_3,
      LT_Mod_Sociability_4: LT_Mod_Sociability_4,
      LT_Mod_Sociability_5B: LT_Mod_Sociability_5B,
      LT_Mod_Sociability_5C: LT_Mod_Sociability_5C,
      LT_Mod_Sociability_5D: LT_Mod_Sociability_5D,
      LT_Mod_Spending: LT_Mod_Spending,
      LT_Mod_Status: LT_Mod_Status,
      LT_PropensityToExplore_5A_Video: LT_PropensityToExplore_5A_Video,
      LT_PropensityToExplore_5B_Video: LT_PropensityToExplore_5B_Video,
      LT_PropensityToExplore_5C_Video: LT_PropensityToExplore_5C_Video,
      LT_Sociability_5A_Video: LT_Sociability_5A_Video,
      LT_PropensityToExplore_5A_Video_thumbnail: LT_PropensityToExplore_5A_Video_thumbnail,
      LT_PropensityToExplore_5B_Video_thumbnail: LT_PropensityToExplore_5B_Video_thumbnail,
      LT_PropensityToExplore_5C_Video_thumbnail: LT_PropensityToExplore_5C_Video_thumbnail,
      LT_Sociability_5A_Video_thumbnail: LT_Sociability_5A_Video_thumbnail
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      front: BE_WhatDrivesThem_front,
      back: BE_WhatDrivesThem_back,
      BE_Mod_PropensityToExplore_2: BE_Mod_PropensityToExplore_2,
      BE_Mod_PropensityToExplore_3: BE_Mod_PropensityToExplore_3,
      BE_Mod_PropensityToExplore_4: BE_Mod_PropensityToExplore_4,
      BE_Mod_PropensityToExplore_5C: BE_Mod_PropensityToExplore_5C,
      BE_Mod_Health: BE_Mod_Health,
      BE_Mod_Outlook: BE_Mod_Outlook,
      BE_Mod_Sociability_2: BE_Mod_Sociability_2,
      BE_Mod_Sociability_3: BE_Mod_Sociability_3,
      BE_Mod_Sociability_4: BE_Mod_Sociability_4,
      BE_Mod_Sociability_5D: BE_Mod_Sociability_5D,
      BE_Mod_Spending: BE_Mod_Spending,
      BE_Mod_Status: BE_Mod_Status,
      BE_PropensityToExplore_5A_Video: BE_PropensityToExplore_5A_Video,
      BE_PropensityToExplore_5B_Video: BE_PropensityToExplore_5B_Video,
      BE_PropensityToExplore_5D_Video: BE_PropensityToExplore_5D_Video,
      BE_Sociability_5A_Video: BE_Sociability_5A_Video,
      BE_Sociability_5B_Video: BE_Sociability_5B_Video,
      BE_Sociability_5C_Video: BE_Sociability_5C_Video,
      BE_PropensityToExplore_5A_Video_thumbnail: BE_PropensityToExplore_5A_Video_thumbnail,
      BE_PropensityToExplore_5B_Video_thumbnail: BE_PropensityToExplore_5B_Video_thumbnail,
      BE_PropensityToExplore_5D_Video_thumbnail: BE_PropensityToExplore_5D_Video_thumbnail,
      BE_Sociability_5A_Video_thumbnail: BE_Sociability_5A_Video_thumbnail,
      BE_Sociability_5B_Video_thumbnail: BE_Sociability_5B_Video_thumbnail,
      BE_Sociability_5C_Video_thumbnail: BE_Sociability_5C_Video_thumbnail
    },
    Fram_PSGMT_6: {
      //Diligent Discoverer
      front: DD_WhatDrivesThem_front,
      back: DD_WhatDrivesThem_back,
      DD_Mod_PropensityToExplore_2: DD_Mod_PropensityToExplore_2,
      DD_Mod_PropensityToExplore_3: DD_Mod_PropensityToExplore_3,
      DD_Mod_PropensityToExplore_4: DD_Mod_PropensityToExplore_4,
      DD_Mod_PropensityToExplore_5D: DD_Mod_PropensityToExplore_5D,
      DD_Mod_PropensityToExplore_5E: DD_Mod_PropensityToExplore_5E,
      DD_Mod_Health: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F31ceed3a02ef457bab78b45da48cff03",
      DD_Mod_Outlook: DD_Mod_Outlook,
      DD_Mod_Sociability_2: DD_Mod_Sociability_2,
      DD_Mod_Sociability_3: DD_Mod_Sociability_3,
      DD_Mod_Sociability_4: DD_Mod_Sociability_4,
      DD_Mod_Sociability_5D: DD_Mod_Sociability_5D,
      DD_Mod_Sociability_5E: DD_Mod_Sociability_5E,
      DD_Mod_Spending: DD_Mod_Spending,
      DD_Mod_Status: DD_Mod_Status,
      DD_PropensityToExplore_5A_Video: DD_PropensityToExplore_5A_Video,
      DD_PropensityToExplore_5B_Video: DD_PropensityToExplore_5B_Video,
      DD_PropensityToExplore_5C_Video: DD_PropensityToExplore_5C_Video,
      DD_Sociability_5A_Video: DD_Sociability_5A_Video,
      DD_Sociability_5B_Video: DD_Sociability_5B_Video,
      DD_Sociability_5C_Video: DD_Sociability_5C_Video,
      DD_PropensityToExplore_5A_Video_thumbnail: DD_PropensityToExplore_5A_Video_thumbnail,
      DD_PropensityToExplore_5B_Video_thumbnail: DD_PropensityToExplore_5B_Video_thumbnail,
      DD_PropensityToExplore_5C_Video_thumbnail: DD_PropensityToExplore_5C_Video_thumbnail,
      DD_Sociability_5A_Video_thumbnail: DD_Sociability_5A_Video_thumbnail,
      DD_Sociability_5B_Video_thumbnail: DD_Sociability_5B_Video_thumbnail,
      DD_Sociability_5C_Video_thumbnail: DD_Sociability_5C_Video_thumbnail
    },
    Fram_PSGMT_8: {
      //Carefree Fun Lovers
      front: CFL_WhatDrivesThem_front,
      back: CFL_WhatDrivesThem_back,
      CFL_Mod_PropensityToExplore_2: CFL_Mod_PropensityToExplore_2,
      CFL_Mod_PropensityToExplore_3: CFL_Mod_PropensityToExplore_3,
      CFL_Mod_PropensityToExplore_4: CFL_Mod_PropensityToExplore_4,
      CFL_Mod_PropensityToExplore_5E: CFL_Mod_PropensityToExplore_5E,
      CFL_Mod_Health: CFL_Mod_Health,
      CFL_Mod_Outlook: CFL_Mod_Outlook,
      CFL_Mod_Sociability_2: CFL_Mod_Sociability_2,
      CFL_Mod_Sociability_3: CFL_Mod_Sociability_3,
      CFL_Mod_Sociability_4: CFL_Mod_Sociability_4,
      CFL_Mod_Sociability_5D: CFL_Mod_Sociability_5D,
      CFL_Mod_Sociability_5E: CFL_Mod_Sociability_5E,
      CFL_Mod_Spending: CFL_Mod_Spending,
      CFL_Mod_Status: CFL_Mod_Status,
      CFL_PropensityToExplore_5A_Video: CFL_PropensityToExplore_5A_Video,
      // CFL_PropensityToExplore_5B_Video: CFL_PropensityToExplore_5B_Video,
      CFL_PropensityToExplore_5C_Video: CFL_PropensityToExplore_5C_Video,
      CFL_PropensityToExplore_5D_Video: CFL_PropensityToExplore_5D_Video,
      CFL_Sociability_5A_Video: CFL_Sociability_5A_Video,
      CFL_Sociability_5B_Video: CFL_Sociability_5B_Video,
      CFL_Sociability_5C_Video: CFL_Sociability_5C_Video,
      CFL_PropensityToExplore_5A_Video_thumbnail: CFL_PropensityToExplore_5A_Video_thumbnail,
      CFL_PropensityToExplore_5B_Video_thumbnail: CFL_PropensityToExplore_5B_Video_thumbnail,
      CFL_PropensityToExplore_5C_Video_thumbnail: CFL_PropensityToExplore_5C_Video_thumbnail,
      CFL_PropensityToExplore_5D_Video_thumbnail: CFL_PropensityToExplore_5D_Video_thumbnail,
      CFL_Sociability_5A_Video_thumbnail: CFL_Sociability_5A_Video_thumbnail,
      CFL_Sociability_5B_Video_thumbnail: CFL_Sociability_5B_Video_thumbnail,
      CFL_Sociability_5C_Video_thumbnail: CFL_Sociability_5C_Video_thumbnail
    },
    Fram_PSGMT_9: {
      //Proud Celebrators
      front: PC_WhatDrivesThem_front,
      back: PC_WhatDrivesThem_back,
      PC_Mod_PropensityToExplore_2: PC_Mod_PropensityToExplore_2,
      PC_Mod_PropensityToExplore_3: PC_Mod_PropensityToExplore_3,
      PC_Mod_PropensityToExplore_4: PC_Mod_PropensityToExplore_4,
      PC_Mod_PropensityToExplore_5E: PC_Mod_PropensityToExplore_5E,
      PC_Mod_Health: PC_Mod_Health,
      PC_Mod_Outlook: PC_Mod_Outlook,
      PC_Mod_Sociability_2: PC_Mod_Sociability_2,
      PC_Mod_Sociability_3: PC_Mod_Sociability_3,
      PC_Mod_Sociability_4: PC_Mod_Sociability_4,
      PC_Mod_Sociability_5D: PC_Mod_Sociability_5D,
      PC_Mod_Sociability_5E: PC_Mod_Sociability_5E,
      PC_Mod_Spending: PC_Mod_Spending,
      PC_Mod_Status: PC_Mod_Status,
      PC_PropensityToExplore_5A_Video: PC_PropensityToExplore_5A_Video,
      PC_PropensityToExplore_5B_Video: PC_PropensityToExplore_5B_Video,
      PC_PropensityToExplore_5C_Video: PC_PropensityToExplore_5C_Video,
      PC_PropensityToExplore_5D_Video: PC_PropensityToExplore_5D_Video,
      PC_Sociability_5A_Video: PC_Sociability_5A_Video,
      PC_Sociability_5B_Video: PC_Sociability_5B_Video,
      PC_Sociability_5C_Video: PC_Sociability_5C_Video,
      PC_PropensityToExplore_5A_Video_thumbnail: PC_PropensityToExplore_5A_Video_thumbnail,
      PC_PropensityToExplore_5B_Video_thumbnail: PC_PropensityToExplore_5B_Video_thumbnail,
      PC_PropensityToExplore_5C_Video_thumbnail: PC_PropensityToExplore_5C_Video_thumbnail,
      PC_PropensityToExplore_5D_Video_thumbnail: PC_PropensityToExplore_5D_Video_thumbnail,
      PC_Sociability_5A_Video_thumbnail: PC_Sociability_5A_Video_thumbnail,
      PC_Sociability_5B_Video_thumbnail: PC_Sociability_5B_Video_thumbnail,
      PC_Sociability_5C_Video_thumbnail: PC_Sociability_5C_Video_thumbnail

    },
    Fram_PSGMT_11: {
      //Creative Explorers
      front: CE_WhatDrivesThem_front,
      back: CE_WhatDrivesThem_back,
      CE_Mod_PropensityToExplore_2: CE_Mod_PropensityToExplore_2,
      CE_Mod_PropensityToExplore_3: CE_Mod_PropensityToExplore_3,
      CE_Mod_PropensityToExplore_4: CE_Mod_PropensityToExplore_4,
      CE_Mod_PropensityToExplore_5C: CE_Mod_PropensityToExplore_5C,
      CE_Mod_PropensityToExplore_5D: CE_Mod_PropensityToExplore_5D,
      CE_Mod_Health: CE_Mod_Health,
      CE_Mod_Outlook: CE_Mod_Outlook,
      CE_Mod_Sociability_2: CE_Mod_Sociability_2,
      CE_Mod_Sociability_3: CE_Mod_Sociability_3,
      CE_Mod_Sociability_4: CE_Mod_Sociability_4,
      CE_Mod_Spending: CE_Mod_Spending,
      CE_Mod_Status: CE_Mod_Status,
      CE_PropensityToExplore_5A_Video: CE_PropensityToExplore_5A_Video,
      CE_PropensityToExplore_5B_Video: CE_PropensityToExplore_5B_Video,
      CE_Sociability_5A_Video: CE_Sociability_5A_Video,
      CE_Sociability_5B_Video: CE_Sociability_5B_Video,
      CE_Sociability_5C_Video: CE_Sociability_5C_Video,
      CE_Sociability_5D_Video: CE_Sociability_5D_Video,
      CE_PropensityToExplore_5A_Video_thumbnail: CE_PropensityToExplore_5A_Video_thumbnail,
      CE_PropensityToExplore_5B_Video_thumbnail: CE_PropensityToExplore_5B_Video_thumbnail,
      CE_Sociability_5A_Video_thumbnail: CE_Sociability_5A_Video_thumbnail,
      CE_Sociability_5B_Video_thumbnail: CE_Sociability_5B_Video_thumbnail,
      CE_Sociability_5C_Video_thumbnail: CE_Sociability_5C_Video_thumbnail,
      CE_Sociability_5D_Video_thumbnail: CE_Sociability_5D_Video_thumbnail
    },
    Fram_PSGMT_12: {
      //Social Curators
      front: SC_WhatDrivesThem_front,
      back: SC_WhatDrivesThem_back,
      SC_Mod_PropensityToExplore_2: SC_Mod_PropensityToExplore_2,
      SC_Mod_PropensityToExplore_3: SC_Mod_PropensityToExplore_3,
      SC_Mod_PropensityToExplore_4: SC_Mod_PropensityToExplore_4,
      SC_Mod_PropensityToExplore_5D: SC_Mod_PropensityToExplore_5D,
      SC_Mod_PropensityToExplore_5E: SC_Mod_PropensityToExplore_5E,
      SC_Mod_Health: SC_Mod_Health,
      SC_Mod_Outlook: SC_Mod_Outlook,
      SC_Mod_Sociability_2: SC_Mod_Sociability_2,
      SC_Mod_Sociability_3: SC_Mod_Sociability_3,
      SC_Mod_Sociability_4: SC_Mod_Sociability_4,
      SC_Mod_Sociability_5E: SC_Mod_Sociability_5E,
      SC_Mod_Spending: SC_Mod_Spending,
      SC_Mod_Status: SC_Mod_Status,
      SC_PropensityToExplore_5A_Video: SC_PropensityToExplore_5A_Video,
      SC_PropensityToExplore_5B_Video: SC_PropensityToExplore_5B_Video,
      SC_PropensityToExplore_5C_Video: SC_PropensityToExplore_5C_Video,
      SC_Sociability_5A_Video: SC_Sociability_5A_Video,
      SC_Sociability_5B_Video: SC_Sociability_5B_Video,
      SC_Sociability_5C_Video: SC_Sociability_5C_Video,
      SC_Sociability_5D_Video: SC_Sociability_5D_Video,
      SC_PropensityToExplore_5A_Video_thumbnail: SC_PropensityToExplore_5A_Video_thumbnail,
      SC_PropensityToExplore_5B_Video_thumbnail: SC_PropensityToExplore_5B_Video_thumbnail,
      SC_PropensityToExplore_5C_Video_thumbnail: SC_PropensityToExplore_5C_Video_thumbnail,
      SC_Sociability_5A_Video_thumbnail: SC_Sociability_5A_Video_thumbnail,
      SC_Sociability_5B_Video_thumbnail: SC_Sociability_5B_Video_thumbnail,
      SC_Sociability_5C_Video_thumbnail: SC_Sociability_5C_Video_thumbnail,
      SC_Sociability_5D_Video_thumbnail: SC_Sociability_5D_Video_thumbnail
    },
  },
  meetThePeople: {
    Fram_PSGMT_2: {
      // Comfort Cravers
      front: CC_MeetThePeople_front,
      back: CC_MeetThePeople_back,
      avatars: [
        CC_Carousel1_Avatar,
        CC_Carousel2_Avatar,
        CC_Carousel3_Avatar,
        CC_Carousel4_Avatar,
        CC_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [CC_Carousel1_SquareImage1, CC_Carousel1_SquareImage2],
        verticalImage: CC_Carousel1_VerticalImage,
        videos: [CC_Carousel1_VideoLeft, CC_Carousel1_VideoRight],
      },
      "carousel-1": {
        squareImages: [CC_Carousel2_SquareImage1, CC_Carousel2_SquareImage2],
        verticalImage: CC_Carousel2_VerticalImage,
        videos: [CC_Carousel2_VideoLeft, CC_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [CC_Carousel3_SquareImage1, CC_Carousel3_SquareImage2],
        verticalImage: CC_Carousel3_VerticalImage,
        videos: [CC_Carousel3_VideoLeft, CC_Carousel3_VideoRight],
      },
      "carousel-3": {
        squareImages: [CC_Carousel4_SquareImage1, CC_Carousel4_SquareImage2],
        verticalImage: CC_Carousel4_VerticalImage,
        videos: [CC_Carousel4_VideoLeft, CC_Carousel4_VideoRight],
        thumbnails: [CC_Carousel4_VideoLeft_Thumbnail, null]
      },
      "carousel-4": {
        squareImages: [CC_Carousel5_SquareImage1, CC_Carousel5_SquareImage2],
        verticalImage: CC_Carousel5_VerticalImage,
        videos: [CC_Carousel5_VideoLeft, CC_Carousel5_VideoRight],
      },
    },
    Fram_PSGMT_3: {
      // Loyal Traditionalists
      front: LT_MeetThePeople_front,
      back: LT_MeetThePeople_back,
      avatars: [
        LT_Carousel1_Avatar,
        LT_Carousel2_Avatar,
        LT_Carousel3_Avatar,
        LT_Carousel4_Avatar,
        LT_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [LT_Carousel1_SquareImage1, LT_Carousel1_SquareImage2],
        verticalImage: LT_Carousel1_VerticalImage,
        videos: [LT_Carousel1_VideoLeft, LT_Carousel1_VideoRight],
      },
      "carousel-1": {
        squareImages: [LT_Carousel2_SquareImage1, LT_Carousel2_SquareImage2],
        verticalImage: LT_Carousel2_VerticalImage,
        videos: [LT_Carousel2_VideoLeft, LT_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [LT_Carousel3_SquareImage1, LT_Carousel3_SquareImage2],
        verticalImage: LT_Carousel3_VerticalImage,
        videos: [LT_Carousel3_VideoLeft, LT_Carousel3_VideoRight],
      },
      "carousel-3": {
        squareImages: [LT_Carousel4_SquareImage1, LT_Carousel4_SquareImage2],
        verticalImage: LT_Carousel4_VerticalImage,
        videos: [LT_Carousel4_VideoLeft, LT_Carousel4_VideoRight],
      },
      "carousel-4": {
        squareImages: [LT_Carousel5_SquareImage1, LT_Carousel5_SquareImage2],
        verticalImage: LT_Carousel5_VerticalImage,
        videos: [LT_Carousel5_VideoLeft, LT_Carousel5_VideoRight],
      },
    },
    Fram_PSGMT_5: {
      // Balanced Enjoyers
      front: BE_MeetThePeople_front,
      back: BE_MeetThePeople_back,
      avatars: [
        BE_Carousel1_Avatar,
        BE_Carousel2_Avatar,
        BE_Carousel3_Avatar,
        BE_Carousel4_Avatar,
        BE_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [BE_Carousel1_SquareImage1, BE_Carousel1_SquareImage2],
        verticalImage: BE_Carousel1_VerticalImage,
        videos: [BE_Carousel1_VideoLeft, BE_Carousel1_VideoRight],
      },
      "carousel-1": {
        squareImages: [BE_Carousel2_SquareImage1, BE_Carousel2_SquareImage2],
        verticalImage: BE_Carousel2_VerticalImage,
        videos: [BE_Carousel2_VideoLeft, BE_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [BE_Carousel3_SquareImage1, BE_Carousel3_SquareImage2],
        verticalImage: BE_Carousel3_VerticalImage,
        videos: [BE_Carousel3_VideoLeft, BE_Carousel3_VideoRight],
      },
      "carousel-3": {
        squareImages: [BE_Carousel4_SquareImage1, BE_Carousel4_SquareImage2],
        verticalImage: BE_Carousel4_VerticalImage,
        videos: [BE_Carousel4_VideoLeft, BE_Carousel4_VideoRight],
      },
      "carousel-4": {
        squareImages: [BE_Carousel5_SquareImage1, BE_Carousel5_SquareImage2],
        verticalImage: BE_Carousel5_VerticalImage,
        videos: [BE_Carousel5_VideoLeft, BE_Carousel5_VideoRight],
      },
    },
    Fram_PSGMT_6: {
      // Diligent Discoverers
      front: DD_MeetThePeople_front,
      back: DD_MeetThePeople_back,
      avatars: [
        DD_Carousel1_Avatar,
        DD_Carousel2_Avatar,
        DD_Carousel3_Avatar,
        DD_Carousel4_Avatar,
        DD_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [DD_Carousel1_SquareImage1, DD_Carousel1_SquareImage2],
        verticalImage: DD_Carousel1_VerticalImage,
        videos: [DD_Carousel1_VideoLeft, DD_Carousel1_VideoRight],
      },
      "carousel-1": {
        squareImages: [DD_Carousel2_SquareImage1, DD_Carousel2_SquareImage2],
        verticalImage: DD_Carousel2_VerticalImage,
        videos: [DD_Carousel2_VideoLeft, DD_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [DD_Carousel3_SquareImage1, DD_Carousel3_SquareImage2],
        verticalImage: DD_Carousel3_VerticalImage,
        videos: [DD_Carousel3_VideoLeft, DD_Carousel3_VideoRight],
      },
      "carousel-3": {
        squareImages: [DD_Carousel4_SquareImage1, DD_Carousel4_SquareImage2],
        verticalImage: DD_Carousel4_VerticalImage,
        videos: [DD_Carousel4_VideoLeft, DD_Carousel4_VideoRight],
      },
      "carousel-4": {
        squareImages: [DD_Carousel5_SquareImage1, DD_Carousel5_SquareImage2],
        verticalImage: DD_Carousel5_VerticalImage,
        videos: [DD_Carousel5_VideoLeft, DD_Carousel5_VideoRight],
      },
    },
    Fram_PSGMT_8: {
      // Carefree Fun Lovers
      front: CFL_MeetThePeople_front,
      back: CFL_MeetThePeople_back,
      avatars: [
        CFL_Carousel1_Avatar,
        CFL_Carousel2_Avatar,
        CFL_Carousel3_Avatar,
        CFL_Carousel4_Avatar,
        CFL_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [CFL_Carousel1_SquareImage1, CFL_Carousel1_SquareImage2],
        verticalImage: CFL_Carousel1_VerticalImage,
        videos: [CFL_Carousel1_VideoLeft, CFL_Carousel1_VideoRight],
        thumbnails: [null, CFL_Carousel1_VideoRight_Thumbnail]
      },
      "carousel-1": {
        squareImages: [CFL_Carousel2_SquareImage1, CFL_Carousel2_SquareImage2],
        verticalImage: CFL_Carousel2_VerticalImage,
        videos: [CFL_Carousel2_VideoLeft, CFL_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [CFL_Carousel3_SquareImage1, CFL_Carousel3_SquareImage2],
        verticalImage: CFL_Carousel3_VerticalImage,
        videos: [CFL_Carousel3_VideoLeft, CFL_Carousel3_VideoRight],
      },
      "carousel-3": {
        squareImages: [CFL_Carousel4_SquareImage1, CFL_Carousel4_SquareImage2],
        verticalImage: CFL_Carousel4_VerticalImage,
        videos: [null, CFL_Carousel4_VideoRight],
      },
      "carousel-4": {
        squareImages: [CFL_Carousel5_SquareImage1, CFL_Carousel5_SquareImage2],
        verticalImage: CFL_Carousel5_VerticalImage,
        videos: [CFL_Carousel5_VideoLeft, CFL_Carousel5_VideoRight],
      },
    },
    Fram_PSGMT_9: {
      // Proud Celebrators
      front: PC_MeetThePeople_front,
      back: PC_MeetThePeople_back,
      avatars: [
        PC_Carousel1_Avatar,
        PC_Carousel2_Avatar,
        PC_Carousel3_Avatar,
        PC_Carousel4_Avatar,
        PC_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [PC_Carousel1_SquareImage1, PC_Carousel1_SquareImage2],
        verticalImage: PC_Carousel1_VerticalImage,
        videos: [PC_Carousel1_VideoLeft, PC_Carousel1_VideoRight],
        thumbnails: [PC_Carousel1_VideoLeft_Thumbnail, PC_Carousel1_VideoRight_Thumbnail]
      },
      "carousel-1": {
        squareImages: [PC_Carousel2_SquareImage1, PC_Carousel2_SquareImage2],
        verticalImage: PC_Carousel2_VerticalImage,
        videos: [PC_Carousel2_VideoLeft, PC_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [PC_Carousel3_SquareImage1, PC_Carousel3_SquareImage2],
        verticalImage: PC_Carousel3_VerticalImage,
        videos: [PC_Carousel3_VideoLeft, PC_Carousel3_VideoRight],
      },
      "carousel-3": {
        squareImages: [PC_Carousel4_SquareImage1, PC_Carousel4_SquareImage2],
        verticalImage: PC_Carousel4_VerticalImage,
        videos: [PC_Carousel4_VideoLeft, PC_Carousel4_VideoRight],
        thumbnails: [PC_Carousel4_VideoLeft_Thumbnail, null]
      },
      "carousel-4": {
        squareImages: [PC_Carousel5_SquareImage1, PC_Carousel5_SquareImage2],
        verticalImage: PC_Carousel5_VerticalImage,
        videos: [PC_Carousel5_VideoLeft, PC_Carousel5_VideoRight],
      },
    },
    Fram_PSGMT_11: {
      // Creative Explorers
      front: CE_MeetThePeople_front,
      back: CE_MeetThePeople_back,
      avatars: [
        CE_Carousel1_Avatar,
        CE_Carousel2_Avatar,
        CE_Carousel3_Avatar,
        CE_Carousel4_Avatar,
        CE_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [CE_Carousel1_SquareImage1, CE_Carousel1_SquareImage2],
        verticalImage: CE_Carousel1_VerticalImage,
        videos: [CE_Carousel1_VideoLeft, CE_Carousel1_VideoRight],
      },
      "carousel-1": {
        squareImages: [CE_Carousel2_SquareImage1, CE_Carousel2_SquareImage2],
        verticalImage: CE_Carousel2_VerticalImage,
        videos: [CE_Carousel2_VideoLeft, CE_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [CE_Carousel3_SquareImage1, CE_Carousel3_SquareImage2],
        verticalImage: CE_Carousel3_VerticalImage,
        videos: [CE_Carousel3_VideoLeft, CE_Carousel3_VideoRight],
      },
      "carousel-3": {
        squareImages: [CE_Carousel4_SquareImage1, CE_Carousel4_SquareImage2],
        verticalImage: "",
        videos: [CE_Carousel4_VideoLeft, CE_Carousel4_VideoRight],
      },
      "carousel-4": {
        squareImages: [CE_Carousel5_SquareImage1, CE_Carousel5_SquareImage2],
        verticalImage: CE_Carousel5_VerticalImage,
        videos: [CE_Carousel5_VideoLeft, CE_Carousel5_VideoRight],
      },
    },
    Fram_PSGMT_12: {
      // Social Curators
      front: SC_MeetThePeople_front,
      back: SC_MeetThePeople_back,
      avatars: [
        SC_Carousel1_Avatar,
        SC_Carousel2_Avatar,
        SC_Carousel3_Avatar,
        SC_Carousel4_Avatar,
        SC_Carousel5_Avatar,
      ],
      "carousel-0": {
        squareImages: [SC_Carousel1_SquareImage1, SC_Carousel1_SquareImage2],
        verticalImage: SC_Carousel1_VerticalImage,
        videos: ["", SC_Carousel1_VideoRight],
        videoImage: [SC_Carousel1_VideoImage1],
      },
      "carousel-1": {
        squareImages: [SC_Carousel2_SquareImage1, SC_Carousel2_SquareImage2],
        verticalImage: SC_Carousel2_VerticalImage,
        videos: [SC_Carousel2_VideoLeft, SC_Carousel2_VideoRight],
      },
      "carousel-2": {
        squareImages: [SC_Carousel3_SquareImage1, SC_Carousel3_SquareImage2],
        verticalImage: SC_Carousel3_VerticalImage,
        videos: ["", SC_Carousel3_VideoRight],
        videoImage: [SC_Carousel3_VideoImage1],
      },
      "carousel-3": {
        squareImages: [SC_Carousel4_SquareImage1, SC_Carousel4_SquareImage2],
        verticalImage: SC_Carousel4_VerticalImage,
        videos: [SC_Carousel4_VideoLeft, SC_Carousel4_VideoRight],
      },
      "carousel-4": {
        squareImages: [SC_Carousel5_SquareImage1, SC_Carousel5_SquareImage2],
        verticalImage: SC_Carousel5_VerticalImage,
        videos: [SC_Carousel5_VideoLeft, SC_Carousel5_VideoRight],
      },
    },
  },
  consumption: {
    Fram_PSGMT_2: {
      // Comfort Cravers
      front: CC_WhatTheyDrink_front,
      back: CC_WhatTheyDrink_back,
      carouselImages: [
        CC_WhatTheyDrink_Carousel1_Image,
        CC_WhatTheyDrink_Carousel2_Image,
        CC_WhatTheyDrink_Carousel3_Image,
      ],
      audio: CC_WhatTheyDrink_Audio,
      videos: [CC_WhatTheyDrink_Video1, CC_WhatTheyDrink_Video2]
    },
    Fram_PSGMT_3: {
      // Loyal Traditionalists
      front: LT_WhatTheyDrink_front,
      back: LT_WhatTheyDrink_back,
      carouselImages: [
        LT_WhatTheyDrink_Carousel1_Image,
        LT_WhatTheyDrink_Carousel2_Image,
        LT_WhatTheyDrink_Carousel3_Image,
      ],
      audio: LT_WhatTheyDrink_Audio,
      videos: [LT_WhatTheyDrink_Video1, LT_WhatTheyDrink_Video2]
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      front: BE_WhatTheyDrink_front,
      back: BE_WhatTheyDrink_back,
      carouselImages: [
        BE_WhatTheyDrink_Carousel1_Image,
        BE_WhatTheyDrink_Carousel2_Image,
        BE_WhatTheyDrink_Carousel3_Image,
      ],
      audio: BE_WhatTheyDrink_Audio,
      videos: [BE_WhatTheyDrink_Video1, BE_WhatTheyDrink_Video2]
    },
    Fram_PSGMT_6: {
      // "Diligent Discoverer",
      front: DD_WhatTheyDrink_front,
      back: DD_WhatTheyDrink_back,
      carouselImages: [
        DD_WhatTheyDrink_Carousel1_Image,
        DD_WhatTheyDrink_Carousel2_Image,
        DD_WhatTheyDrink_Carousel3_Image,
      ],
      audio: DD_WhatTheyDrink_Audio,
      videos: [DD_WhatTheyDrink_Video1, DD_WhatTheyDrink_Video2]
    },
    Fram_PSGMT_8: {
      // "Carefree Fun Lovers",
      front: CFL_WhatTheyDrink_front,
      back: CFL_WhatTheyDrink_back,
      carouselImages: [
        CFL_WhatTheyDrink_Carousel1_Image,
        CFL_WhatTheyDrink_Carousel2_Image,
        CFL_WhatTheyDrink_Carousel3_Image,
      ],
      audio: CFL_WhatTheyDrink_Audio,
      videos: [CFL_WhatTheyDrink_Video1, CFL_WhatTheyDrink_Video2]
    },
    Fram_PSGMT_9: {
      // "Proud Celebrators",
      front: PC_WhatTheyDrink_front,
      back: PC_WhatTheyDrink_back,
      carouselImages: [
        PC_WhatTheyDrink_Carousel1_Image,
        PC_WhatTheyDrink_Carousel2_Image,
        PC_WhatTheyDrink_Carousel3_Image,
      ],
      audio: PC_WhatTheyDrink_Audio,
      videos: [PC_WhatTheyDrink_Video1, PC_WhatTheyDrink_Video2]
    },
    Fram_PSGMT_11: {
      // "Creative Explorers",
      front: CE_WhatTheyDrink_front,
      back: CE_WhatTheyDrink_back,
      carouselImages: [
        CE_WhatTheyDrink_Carousel1_Image,
        CE_WhatTheyDrink_Carousel2_Image,
        CE_WhatTheyDrink_Carousel3_Image,
      ],
      audio: CE_WhatTheyDrink_Audio,
      videos: [CE_WhatTheyDrink_Video1, CE_WhatTheyDrink_Video2]
    },
    Fram_PSGMT_12: {
      // "Social Curators",
      front: SC_WhatTheyDrink_front,
      back: SC_WhatTheyDrink_back,
      carouselImages: [
        SC_WhatTheyDrink_Carousel1_Image,
        SC_WhatTheyDrink_Carousel2_Image,
        SC_WhatTheyDrink_Carousel3_Image,
      ],
      audio: SC_WhatTheyDrink_Audio,
      videos: [SC_WhatTheyDrink_Video1, SC_WhatTheyDrink_Video2]
    },
  },
  demographics: {
    Fram_PSGMT_2: {
      //Comfort Cravers
      front: CC_Demographics_front,
      back: CC_Demographics_back,
    },
    Fram_PSGMT_3: {
      //Loyal Traditionalists
      front: LT_Demographics_front,
      back: LT_Demographics_back,
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      front: BE_Demographics_front,
      back: BE_Demographics_back,
    },
    Fram_PSGMT_6: {
      //Diligent Discoverer
      front: DD_Demographics_front,
      back: DD_Demographics_back,
    },
    Fram_PSGMT_8: {
      //Carefree Fun Lovers
      front: CFL_Demographics_front,
      back: CFL_Demographics_back,
    },
    Fram_PSGMT_9: {
      //Proud Celebrators
      front: PC_Demographics_front,
      back: PC_Demographics_back,
    },
    Fram_PSGMT_11: {
      //Creative Explorers
      front: CE_Demographics_front,
      back: CE_Demographics_back,
    },
    Fram_PSGMT_12: {
      //Social Curators
      front: SC_Demographics_front,
      back: SC_Demographics_back,
    },
  },
};
