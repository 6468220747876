import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NoMealsRoundedIcon from "@mui/icons-material/NoMealsRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import { t } from "i18next";

function MicroOccasionCard({
  locations,
  title,
  description,
  occasionsov,
  sov,
  occasionsoi,
  soi,
}) {
  const keyOccasionIconItems = {
    location: <HomeRoundedIcon color="#000" fontSize="medium" />,
    nomeal: <NoMealsRoundedIcon color="#000" fontSize="medium" />,
    groupIcon: <GroupRoundedIcon color="#000" fontSize="medium" />,
    meal: <RestaurantRoundedIcon color="#000" fontSize="medium" />,
  };
  return (
    <>
      <Card className="macroItems-main-div">
        <CardContent className="macroItems-cardcontent">
          <Box className="macroItems-div">
            <Typography className="occasionDisplayName-typo">
              {title}
            </Typography>
            <Typography className="description-typo">{description}</Typography>
          </Box>
          <Box className="icon-main-div">
            <Box className="macroitem-details-box">
              <Box className="macro-item-soi-main">
                <Box className="macro-item-soi">{Math.round(occasionsov)}%</Box>
                <Box className="macro-item-soi-typo">{sov}</Box>
              </Box>
              <Box className="macro-item-soi-main">
                <Box className="macro-item-soi">{Math.round(occasionsoi)}%</Box>
                <Box className="macro-item-soi-typo">{soi}</Box>
              </Box>
            </Box>
            <Box className="icon-main-second-div">
              {locations?.map((loc, index) => (
                <Box key={index} className="location-items">
                  <Typography className="location-items-typo">
                    {keyOccasionIconItems[loc.icon]}
                  </Typography>
                  <Typography className="location-items-typo2">
                    {t(loc.value)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default MicroOccasionCard;
