import React from 'react'

import OccasionsWrapUp from '../../common/page-sections/Wrap-up/OccasionsWrapUp'
import { useParams } from 'react-router-dom'

function SectionSix({ sectionData }) {
  const { macro, micro } = useParams()
  return (
    <div className='section6'>
        <OccasionsWrapUp macro={macro}
            selectedMicro={micro}
            sectionContent={sectionData}/>
    </div>
  )
}

export default SectionSix