import { Box } from '@mui/material'
import React from 'react'

function VideoCard({ theme, cardData }) {
    return (
        <Box className='video-card-container'>
            <video key={cardData.video} controls className='video-visual' poster={cardData.thumbnail}>
                <source
                    src={cardData.video}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </Box>
    )
}

export default VideoCard;

