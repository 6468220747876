import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { occasionsAssets } from "../../common/OccasionsAssets";

function SectionOne() {
  const { t } = useTranslation(i18next.language);
  return (
    <Box className="hero"
      sx={{
        background: `linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.5) 0%,
              rgba(0, 0, 0, 0.5) 100%
            ),
            url(${occasionsAssets.hubHeroImage})`
      }}>
        <Box className="hero-content-container">
          <Box className="hero-intro-container">
            <Typography variant="h1" className="title">
              { t("occasions.pagename") }
            </Typography>
            <Typography variant="h5" className="subtitle">
              <Trans
                components={{ colored: <strong /> }}
              >
                { t("occasions.hub.intro.description") }
              </Trans>
            </Typography>
          </Box>
        </Box>
    </Box>
  );
}

export default SectionOne;
