import { Route, Routes } from "react-router-dom";

import Login from '../components/login/';
import TermsConditions from '../components/login/TermsConditions';
import Help from '../components/common/Help';
import Error from '../components/Error';
import Home from '../components/home/';
import LoginLayout from '../components/common/LoginLayout';
import AppLayout from '../components/common/AppLayout'
import OccasionsHub from '../components/occasions/hub';
import OccasionsTemplate from '../components/occasions/template';
import DemandSpaceTemplate from '../components/demand-spaces/template';
import DemandSpacesHub from '../components/demand-spaces/hub'
import PeopleSegmentTemplate from "../components/people-segments/template";
import PeopleSegmentHub from "../components/people-segments/hub";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

const AppRoutes = () => {

    return (
        <>
            <AuthenticatedTemplate>
                <Routes>
                    <Route element={<LoginLayout />}
                        errorElement={<Error />}>
                        <Route path='/terms' element={<TermsConditions />} />
                    </Route>
                    <Route element={<AppLayout />}
                        errorElement={<Error />}>
                        <Route path="*" element={<Login />} />
                        <Route path='/' element={<Home />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/peoplesegment" element={<PeopleSegmentHub />} />
                        <Route
                            path="/peoplesegment/:macroSegment/:microSegment"
                            element={<PeopleSegmentTemplate />}
                        />
                        <Route path='/occasions' element={<OccasionsHub />} />
                        <Route path='/occasions/:macro/:micro'
                            element={<OccasionsTemplate />} />
                        <Route path="/demandspace" element={<DemandSpacesHub />} />
                        <Route path='/demandspace/:space'
                            element={<DemandSpaceTemplate />} />
                        <Route path='/help' element={<Help />} />
                    </Route>
                </Routes>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Routes>
                    <Route element={<LoginLayout />}
                        errorElement={<Error />}>
                        <Route path='/' element={<Login />} />
                    </Route>
                </Routes>
            </UnauthenticatedTemplate>

        </>
    )
}

export default AppRoutes;