import { assets } from "../components/common/images";

export const helpQna = [
  {
    title: "help.qa.section1.title",
    itemsPath: "help.qa.section1",
    items: [1, 2, 3],
  },
  {
    title: "help.qa.section2.title",
    itemsPath: "help.qa.section2",
    items: [1, 2, 3],
  },
  {
    title: "help.qa.section3.title",
    itemsPath: "help.qa.section3",
    items: [1, 2, 3, 4],
  },
  {
    title: "help.qa.section4.title",
    itemsPath: "help.qa.section4",
    items: [1, 2],
  },
];

export const helpTooltipPopup = {
  page: "help",
  tooltipData: [
    {
      label: "full-screen-experience-img",
      imgPath: assets.home.fullScreenExperienceImg,
      title: "home.modal.fullScreenExperienceTitle",
      description: "home.modal.fullScreenExperienceDescription",
    },
    {
      label: "quick-link-img",
      imgPath: assets.tooltip.quickLinkImg,
      title: "common.popupCard.quickLinkTitle",
      description: "common.popupCard.quickLinkDescription",
    },
    {
      label: "category-img",
      imgPath: assets.tooltip.categorySizingImg,
      title: "common.popupCard.categorySizingTitle",
      description: "common.popupCard.categorySizingDescription",
    },
    {
      label: "keydrivers-img",
      imgPath: assets.tooltip.keyDriversImg,
      title: "common.popupCard.keyDriversTitle",
      description: "common.popupCard.keyDriversDescription",
    },
    {
      label: "expanding-img",
      imgPath: assets.tooltip.expandingDataImg,
      title: "common.popupCard.expandingDataTitle",
      description: "common.popupCard.expandingDataDescription",
    },
    {
      label: "download-img",
      imgPath: assets.tooltip.downloadsImg,
      title: "common.popupCard.downloadsTitle",
      description: "common.popupCard.downloadsDescription",
    },
  ],
};
