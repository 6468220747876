import React, { useEffect, useState } from "react";
import AnchorNavigationBar from "../../common/AnchorNavigationBar";
import { ComfortCraverAnchorNavItems } from "../../../constants/constants";
import { useLocation, useParams } from "react-router-dom";
import SectionZero from "./SectionZero";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionEight from "./SectionEight";
import SectionNine from "./SectionNine";
import Runner from "../../custom/Runner";
import i18next from "i18next";
import SectionFive from "./SectionFive";
import { fetchPeopleSegmentData, getInfoPopupStatus } from "../../../service/API";
import Loader from "../../common/Loader";
import ToolTipPopUp from "../../custom/Modal/ToolTipPopUp";
import { PeopleSegmentTooltipCardData } from "../../../constants/PeopleSegment";
import { useMsal } from "@azure/msal-react";

export default function PeopleSegmentTemplate() {
  const { macroSegment, microSegment } = useParams();
  const { accounts } = useMsal();
  // const userId = accounts[0].username;
  const [peopleSegmentData, setPeopleSegmentResponse] = useState(null);
  const [popupStatus, setPopupStatus] = useState(true)
  const [previousPath, setPreviousPath] = useState("");
  const location = useLocation();
  if (location.pathname !== previousPath) {
    setPreviousPath(location.pathname);
    setPeopleSegmentResponse(null);
  }

  // const peoplesegmentTooltipLoaded =
  //   window.sessionStorage.getItem("popupData").peoplesegment;

  useEffect(() => {
    //window.scrollTo(0,0)
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  });

  useEffect(() => {
    if (!peopleSegmentData) {
      fetchData();
    }
  });

  const fetchData = async () => {
    try {
      const response = await fetchPeopleSegmentData(microSegment);
      setPeopleSegmentResponse(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPopupData = async () => {
    try {
      const response = await getInfoPopupStatus(accounts[0].username);
      setPopupStatus(response.peoplesegment)
      // window.sessionStorage.setItem("popupData", JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPopupData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function navigateToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  const anchorNavItems = ComfortCraverAnchorNavItems.map((item) => {
    return {
      key: item.key,
      text: item.text,
    };
  });

  return (
    <>
      <div className="main-content">
        {
        !popupStatus && 
        (
          <ToolTipPopUp CardData={PeopleSegmentTooltipCardData} />
        ) }
        {peopleSegmentData ? (
          <>
            <AnchorNavigationBar
              data={{
                category: "people-segment",
                type: microSegment,
                navItems: anchorNavItems,
              }}
              clickAction={navigateToSection}
            />
            <SectionZero sectionData={peopleSegmentData} />

            <SectionOne sectionData={peopleSegmentData} />
            <div className="ps-section2" id="section2">
              <SectionTwo sectionData={peopleSegmentData?.sliders} />
            </div>
            <div className="ps-section3" id="section3">
              <SectionThree />
            </div>
            <div className="ps-section4" id="section4">
              <SectionFour sectionData={peopleSegmentData?.demographics} />
            </div>
            <div className="ps-section5" id="section5">
              <SectionFive sectionData={peopleSegmentData?.drinks} />
            </div>
            <div className="ps-section6" id="section6">
              <SectionSix
                space={microSegment}
                sectionData={peopleSegmentData?.dsMacros}
              />
            </div>
            <div className="ps-section7" id="section7">
              <SectionSeven sectionData={peopleSegmentData} />
            </div>
            <div
              className={`ps-footer-container ${macroSegment}-dark`}
              id="section8"
            >
              <div className="ps-section8">
                <SectionEight />
              </div>
              <div className="ps-section9" id="section9">
                <SectionNine />
              </div>
            </div>
            <div className="runner">
              <Runner
                forPage={"peopleSegmentTemplatePage"}
                forType={microSegment}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
