import { createSlice } from "@reduxjs/toolkit";

export const PeopleSegmentTemplateSlice = createSlice({
    name: 'peopleSegmentTemplate',
    initialState: {
        peopleSegmentData: {}
    },
    reducers: {
      setTemplateData: (state, action) => {
        state.peopleSegmentData = action.payload
      },
    }
  })
  
  // Action creators are generated for each case reducer function
  export const { setTemplateData } = PeopleSegmentTemplateSlice.actions
  
  export default PeopleSegmentTemplateSlice.reducer