import React from 'react'
import FeedbackSection from '../../common/page-sections/FeedbackSection'
import { useParams } from "react-router-dom"; 

export default function SectionEight() {
  const { macro, micro } = useParams();
  return (
    <div className='section8'>
        <FeedbackSection 
            macro={macro}
            micro={micro}/>
    </div>
  )
}
