import React, { useState, useRef } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import CustomSlider from "../../custom/Slider/CustomSlider";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "./Modal";
import { peopleSegmentAssets } from "../../common/PeopleSegmentAssets";
import { PlayArrow } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function SectionOne({ sectionData }) {
  const { t } = useTranslation(i18next.language);
  const { macroSegment, microSegment } = useParams();
  const navigate = useNavigate();

  const videoRef = useRef();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const peopleSegmentData = sectionData.macros[0].peopleSegments.map(
    (item) => item.microCode
  );
  let microMappingData = [];
  if (peopleSegmentData.indexOf(microSegment) > 0) {
    microMappingData = peopleSegmentData.toSpliced(
      0,
      0,
      peopleSegmentData.splice(peopleSegmentData.indexOf(microSegment), 1)[0]
    );
  } else {
    microMappingData = peopleSegmentData;
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCompareClick = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleImageClick = (clickedItem) => {
    navigate(`/peoplesegment/${macroSegment}/${clickedItem}`);
  };

  function playVideo() {
    videoRef.current.play();
  }

  return (
    <>
      {isModalVisible && (
        <Modal
          data={sectionData}
          microSegment={microSegment}
          macroSegment={macroSegment}
          onClose={closeModal}
          microMappingData={microMappingData}
        />
      )}
      <Box className={`people-section1-main-div ${macroSegment}-dark`}>
        <Box className="intro-container">
          <Grid container className="intro-graphic-grid-container">
            <Grid item xs={5.3} sx={{ height: '100%' }}>
              <Box className="people-section1-left-div">
                <Typography
                  variant="h4"
                  className={`intro-text ${microSegment}`}
                >
                  <Trans components={{ colored: <i /> }}>
                    {t(
                      "people-segment.template.intro.selectedPeopleSegmentInfo",
                      {
                        selectedPeopleSegment: t(
                          `people-segment.type.${microSegment}`
                        ),
                        macro: t(
                          `people-segment.macroPeopleSegment.${macroSegment}`
                        ),
                      }
                    )}
                  </Trans>
                </Typography>
                <Box className="people-section1-img-div">
                  <Box className="people-section1-img-heading">
                    <Divider
                      className={`people-section1-heading-divider ${microSegment}`}
                    />
                    <Typography
                      variant="body1"
                      color={`${microSegment}.main`}
                      className={`people-section1-img-typo`}
                    >
                      {t(`people-segment.macroPeopleSegment.${macroSegment}`)}
                    </Typography>
                    <Divider
                      className={`people-section1-heading-divider ${microSegment}`}
                    />
                  </Box>
                  {/* <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  {microMappingData.map((item) => (
                    <Box className="people-section1-img-box" key={item}>
                      <Box
                        className={`people-section1-img1 ${
                          item !== microSegment && "non-selected-people"
                        }`}
                        color={`${item}.main`}
                      >
                        <img
                          src={peopleSegmentAssets.heroImages[item]}
                          color={`${item}.main`}
                          alt={t(`people-segment.type.${microSegment}`)}
                        />
                      </Box>
                      <Box
                        bgcolor={`${item}.main`}
                        className={`people-section1-img1-typo-box ${
                          item !== microSegment && "non-selected-typo"
                        }`}
                      >
                        <Typography
                          color={`${
                            item !== microSegment ? `${item}.main` : "black"
                          }`}
                          className={`people-section1-img1-typo 
                              
                            }`}
                        >
                          {t(`people-segment.type.${item}`)}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack> */}
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {microMappingData.map((item) => (
                      <Grid
                        item
                        xs={6}
                        className="people-section1-img-box"
                        key={item}
                      >
                        <Box
                          className={`people-section1-img1 ${item !== microSegment && "non-selected-people"
                            }`}
                          color={`${item}.main`}
                        >
                          {item !== microSegment ? (
                            <Box onClick={() => handleImageClick(item)}>
                              <img
                                src={peopleSegmentAssets.heroImages[item]}
                                color={`${item}.main`}
                                alt={t(`people-segment.type.${item}`)}
                              />
                            </Box>
                          ) : (
                            <img
                              src={peopleSegmentAssets.heroImages[item]}
                              color={`${item}.main`}
                              alt={t(`people-segment.type.${item}`)}
                            />
                          )}
                        </Box>
                        <Typography
                          component={"div"}
                          bgcolor={`${item}.main`}
                          color={`${item !== microSegment ? `${item}.main` : "black"
                            }`}
                          className={`people-section1-img1-typo ${item !== microSegment && "non-selected-typo"
                            }`}
                        >
                          {t(`people-segment.type.${item}`)}
                        </Typography>
                        {/* </Box> */}
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    className="people-section-compare-persona"
                    onClick={handleCompareClick}
                  >
                    <OpenInNewIcon sx={{ color: "#F6E003" }} />
                    <Typography className="compare-typo">
                      {t("people-segment.template.intro.comparePersonas")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6.7} sx={{ height: '100%' }}>
              <Box className="people-section1-right-div">
                <Box className='text-container'>
                  <Typography
                    variant="body1"
                    className={`people-section1-right-div-typo ${microSegment}`}
                  >
                    <Trans components={{ colored: <i /> }}>
                      {t(
                        `people-segment.template.intro.${microSegment}.description`
                      )}
                    </Trans>
                  </Typography>
                  <Box className="people-section1-onhover">
                    <InfoOutlinedIcon color="primary" />
                    <Typography
                      variant="body2"
                      className="people-section1-onhover-typo"
                    >
                      {t("people-segment.template.intro.hoverTooltip")}
                    </Typography>
                  </Box>
                </Box>

                <Box className="people-section1-slider-div">
                  {sectionData?.sliders?.map((item, index) => {
                    const values = item?.values?.filter(
                      (subItem) => subItem?.microCode === microSegment
                    );
                    return (
                      <CustomSlider
                        key={index}
                        sliderHeading={item?.sliderName}
                        sliderEndtValue={item?.sliderEndtValue}
                        sliderStartValue={item?.sliderStartValue}
                        tooltipText={t(
                          `people-segment.template.intro.${microSegment}.keydriversHoverText.${index + 1
                          }`
                        )}
                        index={index}
                        space={microSegment}
                        values={values}
                      />
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="video-text-container">
          <Grid container spacing={1}>
            <Grid item xs={4.8}>
              <Typography
                variant="h4"
                className={`people-section1-featuredPerson-typo ${microSegment}`}
              >
                <Trans components={{ colored: <i /> }}>
                  {t(
                    `people-segment.template.intro.${microSegment}.featuredPersonText`,
                    {
                      featuredPerson: t(
                        `people-segment.template.intro.${microSegment}.featuredPerson`
                      ),
                    }
                  )}
                </Trans>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Divider
                orientation="vertical"
                flexItem
                className="people-section1-divider-video"
              />
            </Grid>
            <Grid item xs={6.2}>
              <Typography
                variant="body1"
                className="people-section1-featuredPerson-desc"
              >
                <Trans components={{ colored: <i /> }}>
                  {t(
                    `people-segment.template.intro.${microSegment}.videoIntroduction`,
                    {
                      featuredPerson: t(
                        `people-segment.template.intro.${microSegment}.featuredPerson`
                      ),
                    }
                  )}
                </Trans>
              </Typography>
            </Grid>
          </Grid>
          <Box className="people-featuredPerson-video-div">
            {!isVideoPlaying && (
              <Box className="callout-button">
                <PlayArrow
                  className="play-circle"
                  onClick={() => playVideo()}
                />
              </Box>
            )}

            {/* <Container
              disableGutters={true}
              className="people-featuredPerson-container"
              maxWidth="false"
            >
              <video
                className={`video-tag ${microSegment}`}
                controls
                poster={
                  peopleSegmentAssets.intro[microSegment]
                    .featuredPersonVideoThumbnail
                }
                ref={videoRef}
                onPlay={() => setIsVideoPlaying(true)}
                onEnded={() => setIsVideoPlaying(false)}
                src={
                  peopleSegmentAssets.intro[microSegment].featuredPersonVideo
                }
                type="video/mp4"
              />
            </Container> */}
            <video
              className={`video-tag ${microSegment}`}
              controls
              poster={
                peopleSegmentAssets.intro[microSegment]
                  .featuredPersonVideoThumbnail
              }
              ref={videoRef}
              onPlay={() => setIsVideoPlaying(true)}
              onEnded={() => setIsVideoPlaying(false)}
              src={
                peopleSegmentAssets.intro[microSegment].featuredPersonVideo
              }
              type="video/mp4"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
