import React from "react";

import { Grid, Divider, Box, Typography } from "@mui/material";

import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";

import WrapUpHero from "./WrapUpHero";
import { occasionsAssets } from "../../OccasionsAssets";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { ReactComponent as GroupIcon } from "../../../../assets/images/Occasions/wrapup-occasion/group.svg";
import { ReactComponent as RestaurantIcon } from "../../../../assets/images/Occasions/wrapup-occasion/meal.svg";
import { ReactComponent as NoRestaurantIcon } from "../../../../assets/images/Occasions/wrapup-occasion/nomeal.svg";
import { ReactComponent as PlaceIcon } from "../../../../assets/images/Occasions/wrapup-occasion/location.svg";

function WrapUpUGC({ type, selectedMicro }) {
  const { t } = useTranslation(i18next.language);

  function getUGCIcon() {
    let ugcCardIcon = null;
    switch (type) {
      case "groupSize":
        ugcCardIcon = <GroupIcon className="occasions-wrapup-ugc-icon" />;
        break;
      case "location":
        ugcCardIcon = <PlaceIcon className="occasions-wrapup-ugc-icon" />;
        break;
      case "meal":
        ugcCardIcon = <RestaurantIcon className="occasions-wrapup-ugc-icon" />;
        break;
      case "nomeal":
        ugcCardIcon = <NoRestaurantIcon className="occasions-wrapup-ugc-icon" />;
        break;
      default:
        break;
    }
    return ugcCardIcon;
  }
  return (
    <Box className="wrapup-ugc-card">
      {getUGCIcon()}
      <Box className="ugc-text-container">
        <Typography className="ugc-title">
          {t(`occasions.template.howTheyDrink.${type}`)}
        </Typography>
        <Typography className="ugc-description">
          {t(`occasions.template.howTheyDrink.${selectedMicro}.${type}Value`)}
        </Typography>
      </Box>
    </Box>
  );
}

function OccasionsWrapUp({ selectedMicro, sectionContent }) {
  const { t } = useTranslation(i18next.language);

  const wrapupContent = {
    title: t("occasions.anchorItems.wrapUp"),
    micro: t(`occasions.type.${selectedMicro}`),
    sov: sectionContent.sov,
    sop: sectionContent.sop,

    microDescription:
      t(`occasions.template.wrapup.${selectedMicro}.macroDescription`) +
      "<br/><br/>" +
      t(`occasions.template.wrapup.${selectedMicro}.microDescription`),
    keywords: t(`occasions.keywords.${selectedMicro}`, { returnObjects: true }),
    microImage: occasionsAssets.heroImages[selectedMicro], // Hero image of the selected micro
    visualText: t(`occasions.template.intro.${selectedMicro}.paragraph`), // Visual text of the selected micro
    microID: selectedMicro
  };

  // function navigateToSection(id) {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //       inline: "start",
  //     });
  //   }
  // }

  return (
    <Box className="wrapup-main">
      <Box className="wrapup-intro">
        <Divider className="divider" />
        <WrapUpHero content={wrapupContent} />
      </Box>
      <Grid container gap={5} className="wrapup-ugc-container">
        <Grid item xs={3.7} className="wrapup-ugc-item">
          <WrapUpUGC type="groupSize" selectedMicro={selectedMicro} />
        </Grid>
        <Grid item xs={3.7} className="wrapup-ugc-item">
          {/* if selectedMicro is Fram_OCCAS_6 or Fram_OCCAS_5 or Fram_OCCAS_14 or Fram_OCCAS_13 then type is nomeal else meal */}
          { selectedMicro === "Fram_OCCAS_6" || selectedMicro === "Fram_OCCAS_5" || selectedMicro === "Fram_OCCAS_14" || selectedMicro === "Fram_OCCAS_13" ? (
            <WrapUpUGC type="nomeal" selectedMicro={selectedMicro} />
          ) : (
            <WrapUpUGC type="meal" selectedMicro={selectedMicro} />
          )}
        </Grid>
        <Grid item xs={3.7} className="wrapup-ugc-item">
          <WrapUpUGC type="location" selectedMicro={selectedMicro} />
        </Grid>
      </Grid>
      <Box className="wrapup-interaction-tooltip">
        <QueryStatsIcon className="icon" color="primary" />
        <Typography
          variant="body1"
          color="secondary"
          className="interaction-tooltip-text"
          // onClick={() => navigateToSection("section7")}
        >
          <Trans components={{ underline: <i /> }}>
            {t("common.wrapup.interactionTooltipText")}
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
}

export default OccasionsWrapUp;
