import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import SectionIntro from "../../common/page-sections/SectionIntro";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PieChartComponent from "../../custom/Charts/PieChartComponent";
import BarChartComponent from "../../custom/Charts/BarChartComponent";
import CustomHorizontalBar from "../../custom/Charts/CustomHorizontalBar";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import HorizontalBarChart from "../../custom/Charts/HorizontalBarChart.js";
import { microIdentifiersShortHand } from "../../../constants/DemandSpace.js";
import CustomSlider from "../../custom/Slider/CustomSlider.js";
import { demandSpaceAssets } from "../../common/DemandSpaceAssets.js";
import { InfoOutlined } from "@mui/icons-material";
import { peopleSegmentAssets } from "../../common/PeopleSegmentAssets.js";
import { getTemplateColors } from "../../../constants/constants.js";

function SectionTwo({ macro, space }) {
  const { t } = useTranslation(i18next.language);
  const [showButton, setShowButton] = useState(true);
  const [isDivVisible, setDivVisibility] = useState(false);
  const [selectedPeopleSegment, setSelectedPeopleSegment] = useState({});

  const handleButtonClick = () => {
    setDivVisibility(!isDivVisible);
    setShowButton(!showButton);
  };

  const handleSegmentClick = (peopleSegmentName) => {
    setSelectedPeopleSegment({
      name: peopleSegmentName.microDisplayName,
      value: microIdentifiersShortHand[peopleSegmentName.microCode],
    });
  };

  const demographicData = t("common.demographics", {
    returnObjects: true,
  });

  const sharedtraitsData = t(
    `demandspace.template.thePeople.sharedTraits.${space}`,
    {
      returnObjects: true,
    }
  );
  const getMappingArray = (microArray) => {
    const microCodeArray = microArray.map((item) => item.microCode);
    let mappingArray = [];
    if (
      microCodeArray.indexOf("Fram_PSGMT_2") > -1 &&
      microCodeArray.indexOf("Fram_PSGMT_3") > -1
    ) {
      mappingArray = [...microArray].reverse();
    } else {
      mappingArray = microArray;
    }
    return mappingArray;
  };

  function getMacroDisplayString(macroText) {
    const macro = macroText.split('+').map((text) => text.trim());
    let macroDisplayString = ''
    if(macro.indexOf("Familiarity Seeking") !== -1) {
      if(macro.indexOf("Familiarity Seeking") > 0) {
        macroDisplayString = `${macro[1].split(' ').join('-')} + ${macro[0]}`
      } else {
        macroDisplayString = `${macro[0].split(' ').join('-')} + ${macro[1]}`
      }
    } else {
      macroDisplayString = macroText
    }

    return macroDisplayString
  }
  return (
    <>
      <Box className="people-background">
        <Box className="people-main">
          <SectionIntro
            heading={t("demandspace.anchorItems.thePeople")}
            description={
              <Typography className={`people-description-typo`}>
                <Trans
                  components={{
                    colored: <strong />,
                    coloredDynamic: <i />,
                    coloredgreen: <b />,
                    coloredyellow: <strong />,
                    coloredorange: <coloredorange />,
                    coloredpurple: <u />,
                    coloredblue: <coloredblue />,
                  }}
                >
                  {t(
                    `demandspace.template.thePeople.peopleDescription.${space}.partOfMacro`,
                    {
                      type: t(`demandspace.type.${space}`),
                      macroOccasion: t(`demandspace.macroOccasion.${space}`),
                    }
                  )}
                </Trans>
              </Typography>
            }
            sectionImages={{
              back: demandSpaceAssets.thePeople[space].back,
              front: demandSpaceAssets.thePeople[space].front,
            }}
          />
        </Box>
      </Box>
      <Box className="content">
        <Box className="macro-segement">
          <Grid container className="macro-segment-multiple" gap={1}>
            {macro?.macros?.map((item, index) => {
              let colorPicker = item.peopleSegments.map(
                (items) => items.microCode
              );
              let mappingColor = [];
              if (
                colorPicker.indexOf("Fram_PSGMT_2") > -1 &&
                colorPicker.indexOf("Fram_PSGMT_3") > -1
              ) {
                mappingColor = [...colorPicker].reverse();
              } else {
                mappingColor = colorPicker;
              }
              const color = `linear-gradient(100deg, ${getTemplateColors(
                mappingColor[0]
              )} 0%, ${getTemplateColors(mappingColor[1])}  100%)`;
              return (
                <Grid
                  item
                  xs={macro.macros.length > 1 ? 11 / macro.macros.length : 6}
                >
                  <Box key={index} className="macro-segment-section">
                    <Box className="macro-micro-box">
                      <Box
                        className={`macro-typo-box ${
                          macro.macros.length > 2 ? "macro-height" : ""
                        }`}
                      >
                        <Typography
                          className="macro-typo"
                          sx={{ background: color ? color : "#F6E003" }}
                        >
                          { getMacroDisplayString(item.macro.replace(/\s*\(NET\)/, "")) }
                        </Typography>
                      </Box>
                      <Box className="segment-types">
                        {getMappingArray(item.peopleSegments).map(
                          (items, subIndex) => {
                            return (
                              <Box
                                key={subIndex}
                                className={`micro-typo-box ${items.microCode}`}
                              >
                                <Typography className="micro-typo">
                                  {items?.microDisplayName}
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box className="demandspace">
            {showButton ? (
              <Button
                className="demand-space-demographics"
                onClick={handleButtonClick}
              >
                <VisibilityIcon fontSize="18px" />
                {t("common.demographics.showbutton")}
              </Button>
            ) : (
              <Button
                className="demand-space-demographics"
                onClick={handleButtonClick}
              >
                <VisibilityOffIcon fontSize="18px" />
                {t("common.demographics.hidebutton")}
              </Button>
            )}
          </Box>
          {isDivVisible && (
            <Grid container className="demographics-grid-container">
              <Grid item md={6} lg={4}>
                <Box>
                  <PieChartComponent
                    data={macro?.demographics?.gender}
                    title={demographicData?.genderGraphHeading}
                    colors={["white", "yellow"]}
                  />
                </Box>
              </Grid>
              <Grid item md={6} lg={4}>
                <Box>
                  <BarChartComponent
                    data={macro?.demographics?.age}
                    title={demographicData?.ageGraphHeading}
                    color="#F6E003"
                  />
                </Box>
              </Grid>
              <Grid item md={6} lg={4}>
                <Box>
                  <HorizontalBarChart
                    data={macro?.demographics?.ethnicity}
                    title={demographicData?.ethnicityGraphHeading}
                    color="#F6E003"
                  />
                </Box>
              </Grid>
              <Grid item md={6} lg={4}>
                <Box>
                  <HorizontalBarChart
                    data={macro?.demographics?.income}
                    title={demographicData?.incomeGraphHeading}
                    color="#F6E003"
                  />
                </Box>
              </Grid>
              <Grid item md={6} lg={4}>
                <Box>
                  <HorizontalBarChart
                    data={macro?.demographics?.livingArea}
                    title={demographicData?.livingAreaGraphHeading}
                    color="#F6E003"
                  />
                </Box>
              </Grid>
              <Grid item md={6} lg={4}>
                <Box>
                  <CustomHorizontalBar
                    data={macro?.demographics?.regions}
                    title={demographicData?.regionsGraphHeading}
                    color="#F6E003"
                  />
                </Box>
              </Grid>
              <Grid item sm={12} className="demographics-tool-tip-container">
                <InfoOutlined className="demographics-info-icon" />
                <span className="demographics-tool-tip">
                  {t("demandspace.template.thePeople.demographics.toolTipText")}
                </span>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box className="shared-traits-main-div">
          <Typography className="shared-traits-text">
            {t("demandspace.template.thePeople.sharedTraits.heading")}
          </Typography>
          <Grid container className="shared-traits-div" gap={5}>
            {sharedtraitsData?.map(
              (item, index) =>
                item?.titleItems?.length > 0 && (
                  <Grid
                    item
                    xs={
                      sharedtraitsData.length > 1
                        ? 11.5 / sharedtraitsData.length
                        : 6
                    }
                    key={index}
                    className="shared-traits-title-div"
                  >
                    <Box className="shared-traits-title-container">
                      <Divider className="shared-divider-line" />
                      <Divider
                        className="shared-traits-typo"
                        textAlign="center"
                      >
                        {item?.title}
                      </Divider>
                      <Divider className="shared-divider-line" />
                    </Box>
                    <Box className="shared-traits-Box">
                      {item?.titleItems?.map((desc, subIndex) => (
                        <Box key={subIndex} className="shared-traits-items">
                          <Typography className="shared-traits-desc-typo">
                            {desc}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        </Box>

        <Box className="notable-differences-main">
          <Box className="notable-diff-div">
            <Box className="notable-diff-heading">
              <Typography className="notable-typo">
                {t("demandspace.template.thePeople.notabledifferences.heading")}
              </Typography>
              <Box className="infosharp-div">
                <InfoOutlined className="infosharpicon"></InfoOutlined>
                <Typography className="notable-hover-typo">
                  {t(
                    "demandspace.template.thePeople.notabledifferences.subheading"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="notable-divider"></Box>
          <Grid container className="notable-diff-leftright" gap={10}>
            <Grid item xs={4} className="notable-left-div">
              {macro?.macros?.map((item, index) =>
                getMappingArray(item.peopleSegments).map(
                  (subItem, subIndex) => {
                    const uniqueKey = `${index}-${subIndex}`;
                    const isPeopleSegmentSelected =
                      selectedPeopleSegment.value &&
                      selectedPeopleSegment.value !==
                        microIdentifiersShortHand[subItem?.microCode];
                    return (
                      <React.Fragment key={uniqueKey}>
                        <Button
                          key={subIndex}
                          className="notable-image-div"
                          onClick={() => handleSegmentClick(subItem)}
                        >
                          <Box>
                            <img
                              className={`notable-images ${
                                subItem?.microCode
                              } ${
                                isPeopleSegmentSelected && "notable-blur-image"
                              } `}
                              alt={`demandspace people ${t(
                                `people-segment.type.${subItem.microCode}`
                              )}`}
                              src={
                                peopleSegmentAssets.heroImages[
                                  subItem?.microCode
                                ]
                              }
                            />
                          </Box>
                          <Box
                            className={`care-free-items ${subItem?.microCode} ${
                              isPeopleSegmentSelected &&
                              "care-free-non-selected"
                            } `}
                          >
                            <Typography
                              className={`care-free-typo ${
                                isPeopleSegmentSelected &&
                                "care-free-non-selected-text"
                              } `}
                            >
                              {/* {subItem?.microDisplayName} */}
                              {t(`people-segment.type.${subItem?.microCode}`)}
                            </Typography>
                          </Box>
                        </Button>
                      </React.Fragment>
                    );
                  }
                )
              )}
              {selectedPeopleSegment?.name && (
                <Box className="notable-clearAll">
                  <Button onClick={() => setSelectedPeopleSegment({})}>
                    <DisabledByDefaultIcon />{" "}
                    {t(
                      "demandspace.template.thePeople.notabledifferences.clearAll"
                    )}
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={8} className="notable-right-div">
              {macro?.sliders?.map((item, index) => {
                return (
                  <CustomSlider
                    key={index}
                    sliderHeading={item?.sliderName}
                    sliderEndtValue={item?.sliderEndtValue}
                    sliderStartValue={item?.sliderStartValue}
                    textColor="#C7C7C7"
                    index={index}
                    tooltipText={(values, parentIndex, index) => {
                      const text = t(
                        `demandspace.template.thePeople.notabledifferences.${
                          values?.microCode
                        }.keydriversHoverText.${parentIndex + 1}`
                      );
                      return text;
                    }}
                    values={item?.values}
                    selectedPeopleSegment={selectedPeopleSegment}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default SectionTwo;
