export const occasionsAssets = {
    "howTheyDrink": {
      "Fram_OCCAS_14": {
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/me-time/images/MT_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/me-time/images/MT_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/me-time/images/MT_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/me-time/images/MT_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/me-time/images/MT_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/me-time/images/MT_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_9": {
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-dining-in/images/WDI_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-dining-in/images/WDI_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-dining-in/images/WDI_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-dining-in/images/WDI_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-dining-in/images/WDI_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-dining-in/images/WDI_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_2": {
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/big-weekend-out/images/BWO_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/big-weekend-out/images/BWO_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/big-weekend-out/images/BWO_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/big-weekend-out/images/BWO_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/big-weekend-out/images/BWO_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/big-weekend-out/images/BWO_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_3": {
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/lively-meal-out/images/LMO_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/lively-meal-out/images/LMO_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/lively-meal-out/images/LMO_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/lively-meal-out/images/LMO_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/lively-meal-out/images/LMO_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/lively-meal-out/images/LMO_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_6": {
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-hang/images/WH_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-hang/images/WH_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-hang/images/WH_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-hang/images/WH_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-hang/images/WH_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-hang/images/WH_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_10": {
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-meal/images/WM_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-meal/images/WM_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-meal/images/WM_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-meal/images/WM_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-meal/images/WM_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekday-meal/images/WM_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_13": {
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/unwinding-together/images/UT_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/unwinding-together/images/UT_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/unwinding-together/images/UT_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/unwinding-together/images/UT_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/unwinding-together/images/UT_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/unwinding-together/images/UT_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_5": {
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-get-together/images/WGT_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-get-together/images/WGT_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-get-together/images/WGT_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-get-together/images/WGT_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-get-together/images/WGT_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/weekend-get-together/images/WGT_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_7": {
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/hosted-meal/images/HM_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/hosted-meal/images/HM_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/hosted-meal/images/HM_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/hosted-meal/images/HM_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/hosted-meal/images/HM_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/hosted-meal/images/HM_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_12": {
        "gridGroup_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/tv-and-dinner/images/TVD_HowTheyDrink_GridGroup_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/tv-and-dinner/images/TVD_HowTheyDrink_GridMeals_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridGroup_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/tv-and-dinner/images/TVD_HowTheyDrink_GridGroup_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridMeals_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/tv-and-dinner/images/TVD_HowTheyDrink_GridMeals_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/tv-and-dinner/images/TVD_HowTheyDrink_GridLocations_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "gridLocations_Image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/how-they-drink/tv-and-dinner/images/TVD_HowTheyDrink_GridLocations_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      }
    },
    "heroImages": {
      "Fram_OCCAS_13": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/UT_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_14": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/MT_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_9": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/WDI_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/LMO_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_12": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/TVD_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/WGT_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/WH_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/BWO_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_7": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/HM_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "Fram_OCCAS_10": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/common/WM_Hero.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
    },
    "whatItLooksLike": {
      "Fram_OCCAS_9": {
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-dining-in/images/WDI_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "example": {
          "carousel5": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F70b3961c34164ae1bc0280ebd1cc9f48",
          "carousel3": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5e011af5a51640708bfc4befd363a029",
          "carousel2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-dining-in/images/WDI_WhatItLooksLike_Example_CarouselImage2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel4": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F607233e558e844ef85798bd6a0f36b8f",
          "carousel1": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff5dcc2b04ec84f0aa96b860c89e8cd38",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-dining-in/images/WDI_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-dining-in/images/WDI_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "modularContent": {
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-dining-in/images/WDI_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-dining-in/images/WDI_WhatItLooksLike_Details_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        }
      },
      "Fram_OCCAS_14": {
        "example": {
          "carousel2": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F052dac5df8e9402cb8e42fa104032560",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_Example_CarouselImage5.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_Example_CarouselImage3.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel4": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_Example_CarouselImage4.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "modularContent": {
          "image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_Details_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/me-time/images/MT_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        }
      },
      "Fram_OCCAS_10": {
        "modularContent": {
          "image1": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F96eeedeecf4143d6a6d181f441e801d9",
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "example": {
          "carousel3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_Example_CarouselImage3.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel4": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_Example_CarouselImage4.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_Example_CarouselImage2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_Example_CarouselImage5.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-meal/images/WM_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_12": {
        "example": {
          "carousel4": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_Example_CarouselImage4.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_Example_CarouselImage5.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_Example_CarouselImage2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_Example_CarouselImage3.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "modularContent": {
          "image2": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F03a2ed66c0854255877935ff22dc9db8",
          "image1": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F27b98bd589034f2081261cd02e75edc1"
        },
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/tv-and-dinner/images/TVD_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_7": {
        "example": {
          "carousel5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Example_CarouselImage5.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Example_CarouselImage2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Example_CarouselImage3.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel4": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Example_CarouselImage4.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "modularContent": {
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/hosted-meal/images/HM_WhatItLooksLike_Details_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        }
      },
      "Fram_OCCAS_3": {
        "example": {
          "carousel5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Example_CarouselImage5.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel4": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Example_CarouselImage4.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Example_CarouselImage3.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Example_CarouselImage2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "modularContent": {
          "image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Details_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/lively-meal-out/images/LMO_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        }
      },
      "Fram_OCCAS_2": {
        "modularContent": {
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/big-weekend-out/images/BWO_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image1": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6466dafbca7e424aa41e1618b93230c6"
        },
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/big-weekend-out/images/BWO_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/big-weekend-out/images/BWO_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "example": {
          "carousel2": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F35324391f9d04d6faa79aa616a62671f",
          "carousel3": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6d998865912c4684ad1980a9f7e0cc29",
          "carousel6": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa39908ac4b37451b9eb584ebc797cd2e",
          "carousel4": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/big-weekend-out/images/BWO_WhatItLooksLike_Example_CarouselImage4.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/big-weekend-out/images/BWO_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel5": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F99ea9092dad7436796cf5bd0df0deecc"
        }
      },
      "Fram_OCCAS_6": {
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "example": {
          "carousel4": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F52c21534e06a4483992a223da656f361",
          "carousel3": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5d377a4611534ca48d6facd9acdb39c6",
          "carousel2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_Example_CarouselImage2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_Example_CarouselImage5.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "modularContent": {
          "image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_Details_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekday-hang/images/WH_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        }
      },
      "Fram_OCCAS_5": {
        "example": {
          "carousel4": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7d932a28d5e9409d92292cd696395c18",
          "carousel5": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbb7444a1c5474ed293c5c108864dc8ba",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-get-together/images/WGT_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-get-together/images/WGT_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel2": "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb560aa813979414887ebe76e1bed621d",
          "carousel3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-get-together/images/WGT_WhatItLooksLike_Example_CarouselImage3.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "modularContent": {
          "image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-get-together/images/WGT_WhatItLooksLike_Details_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-get-together/images/WGT_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-get-together/images/WGT_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/weekend-get-together/images/WGT_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_13": {
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "modularContent": {
          "image1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Details_Image1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "image2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Details_Image2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        },
        "example": {
          "carousel2": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Example_CarouselImage2.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel3": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Example_CarouselImage3.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel5": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Example_CarouselImage5.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel1": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Example_CarouselImage1.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel6": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Example_CarouselImage6.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
          "carousel4": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/what-it-looks-like/unwinding-together/images/UT_WhatItLooksLike_Example_CarouselImage4.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
        }
      }
    },
    "whatDefinesAnOccasion": {
      "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/hub/images/Occasions_WhatDefinesAnOccasion_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
      "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/hub/images/Occasions_WhatDefinesAnOccasion_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
    },
    "hubHeroImage": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/hub/images/Occasions_Hub_HeroImage.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
    "contextualFactors": {
      "Fram_OCCAS_5": {
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekend-get-together/images/WGT_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekend-get-together/images/WGT_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_12": {
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/tv-and-dinner/images/TVD_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/tv-and-dinner/images/TVD_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_6": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekday-hang/images/WH_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekday-hang/images/WH_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_9": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekend-dining-in/images/WDI_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekend-dining-in/images/WDI_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_7": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/hosted-meal/images/HM_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/hosted-meal/images/HM_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_10": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekday-meal/images/WM_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/weekday-meal/images/WM_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_14": {
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/me-time/images/MT_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/me-time/images/MT_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_3": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/lively-meal-out/images/LMO_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/lively-meal-out/images/LMO_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_2": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/big-weekend-out/images/BWO_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/big-weekend-out/images/BWO_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_13": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/unwinding-together/images/UT_ContextualFactors_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/contextual-factors/unwinding-together/images/UT_ContextualFactors_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      }
    },
    "consumption": {
      "Fram_OCCAS_5": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekend-get-together/images/WGT_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekend-get-together/images/WGT_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_9": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekend-dining-in/images/WDI_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekend-dining-in/images/WDI_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_7": {
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/hosted-meal/images/HM_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/hosted-meal/images/HM_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_10": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekday-meal/images/WM_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekday-meal/images/WM_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_12": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/tv-and-dinner/images/TVD_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/tv-and-dinner/images/TVD_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_13": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/unwinding-together/images/UT_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/unwinding-together/images/UT_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_6": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekday-hang/images/WH_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/weekday-hang/images/WH_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_2": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/big-weekend-out/images/BWO_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/big-weekend-out/images/BWO_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_14": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/me-time/images/MT_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/me-time/images/MT_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      },
      "Fram_OCCAS_3": {
        "front": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/lively-meal-out/images/LMO_Consumption_front.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D",
        "back": "https://accenturewebstorage2.blob.core.windows.net/uiimagecontainer/occasions/template/consumption/lively-meal-out/images/LMO_Consumption_back.jpg?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-10-14T02:11:46Z&st=2024-06-13T18:11:46Z&spr=https&sig=b3BZ1u0ueYstQXE2clPyksZmtX0BB5c6kcSoDgfbDA8%3D"
      }
    }
  }