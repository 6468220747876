import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AnchorNavigationBar from "../../common/AnchorNavigationBar";
import ScopeBadge from "../../custom/Badges/ScopeBadge";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionEight from "./SectionEight";
import Runner from "../../custom/Runner";
import { useLocation, useParams } from "react-router-dom";
import { occasionsAnchorNavItems } from "../../../constants/OccasionsConstants";
import SectionFive from "./SectionFive";
import { fetchOccasionsData, getInfoPopupStatus } from "../../../service/API";
import Loader from "../../common/Loader";
import ToolTipPopUp from "../../custom/Modal/ToolTipPopUp";
import { OccasionTooltipCardData } from "../../../constants/OccasionsConstants";
import { occasionsAssets } from "../../common/OccasionsAssets";
import WayFinderPeopleTemplate from "../../common/page-sections/WayFinderPeopleTemplate";
import { useMsal } from "@azure/msal-react";

function OccasionsTemplate() {
  const { t } = useTranslation(i18next.language);
  const { micro } = useParams();
  const { accounts } = useMsal();
  // const userId = accounts[0].username;

  const [occasionResponse, setOccasionsResponse] = useState(null);
  const [popupStatus, setPopupStatus] = useState(true)
  const [previousPath, setPreviousPath] = useState("");
  const location = useLocation();
  if (location.pathname !== previousPath) {
    setPreviousPath(location.pathname);
    setOccasionsResponse(null);
  }

  // const occasionsTooltipLoaded =
  //   window.sessionStorage.getItem("popupData")?.occasionsFramework;

  useEffect(() => {
    //window.scrollTo(0,0)
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  });

  const fetchData = async () => {
    try {
      const response = await fetchOccasionsData(micro);
      setOccasionsResponse(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!occasionResponse) {
      fetchData();
    }
  });

  const fetchPopupData = async () => {
    try {
      const response = await getInfoPopupStatus(accounts[0].username);
      setPopupStatus(response.occasionsFramework)
      // window.sessionStorage.setItem("popupData", JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPopupData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const anchorNavItems = occasionsAnchorNavItems.map((item) => {
    return {
      key: item.key,
      text: item.text,
    };
  });

  function navigateToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }
  return (
    <>
      <div className="main-content">
        {
        !popupStatus && 
        (
          <ToolTipPopUp CardData={OccasionTooltipCardData} />
        ) }

        {occasionResponse ? (
          <>
            <AnchorNavigationBar
              data={{
                category: "occasions",
                type: micro,
                navItems: anchorNavItems,
              }}
              clickAction={navigateToSection}
            />

            <Box
              className="template-container"
              sx={{
                backgroundImage: `linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.5) 0%,
                  rgba(0, 0, 0, 0.5) 100%
                ),
                url(${occasionsAssets.heroImages[micro]})`,
              }}
            >
              <Box className="hero-container">
                <Box className={`hero-wayfinder ${micro}`}>
                  <WayFinderPeopleTemplate ids={[micro]} />
                </Box>
                <Box className="hero-content">
                  <Typography className="occasion-type">
                    {t(`occasions.type.${micro}`)}
                  </Typography>
                  <Box className="occasion-type-scope">
                    <ScopeBadge
                      data={{
                        value: t("common.scopeBadge.scopeBadgeValue", {
                          value: Math.round(occasionResponse.populationVolume),
                        }),
                        label: t("common.scopeBadge.ofIncidence"),
                      }}
                    />
                    <ScopeBadge
                      data={{
                        value: t("common.scopeBadge.scopeBadgeValue", {
                          value: Math.round(occasionResponse.totalVolume),
                        }),
                        label: t("common.scopeBadge.totalAlcoholVolume"),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <SectionOne />
            <div className="section2-container" id="section2">
              <SectionTwo sectionData={occasionResponse?.contexualFactors} />
            </div>
            <div className="section3-container" id="section3">
              <SectionThree />
            </div>
            <div
              className="occasions-template-section4-container"
              id="section4"
            >
              <SectionFour
                sectionData={occasionResponse?.consumptions.drinks}
              />
            </div>
            <div className="section5-container" id="section5">
              <SectionFive sectionData={occasionResponse?.dsMacros} />
            </div>
            <div className="section6-container" id="section6">
              <SectionSix
                sectionData={{
                  sov: Math.round(occasionResponse.totalVolume),
                  sop: Math.round(occasionResponse.populationVolume),
                }}
              />
            </div>
            <div
              className="occasions-template-section7-container"
              id="section7"
            >
              <SectionSeven />
            </div>
            <div className="occasions-template-section8-container">
              <SectionEight />
            </div>
            <div className="runner">
              <Runner forPage={"occasionsTemplatePage"} forType={micro} />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

export default OccasionsTemplate;
