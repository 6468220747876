import { configureStore } from "@reduxjs/toolkit";
import loginReducers from "./LoginSlice";
import occasionsReducers from './OccasionsTemplateSlice'
import demandSpaceTemplateReducers from "./DemandSpaceTemplateSlice";
import PeopleSegementTemplateReducers from "./PeopleSegmentTemplateSlice";

export const store = configureStore({
    reducer: {
        login: loginReducers,
        occasions: occasionsReducers,
        demandSpaceTemplate: demandSpaceTemplateReducers,
        peopleSegmentTemplate: PeopleSegementTemplateReducers,
  },
});
