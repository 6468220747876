import { Box, Typography } from "@mui/material";
import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${Math.round(percent * 100)}%`}
    </text>
  );
};

const PieChartComponent = ({ data = [], title, colors = [] }) => {
  function getIndexColor(indexValue) {
    if (indexValue < 86) {
      return "negative";
    } else if (indexValue > 115) {
      return "positive";
    } else {
      return "neutral";
    }
  }
  // Convert genderPercent values to numbers
  const formattedData = data.map((item) => ({
    ...item,
    genderPercent: Math.round(parseFloat(item.genderPercent)),
  }));

  // Sort formattedData based on genderType in descending order
  formattedData.sort((a, b) => {
    return b.genderType.localeCompare(a.genderType);
  });
  return (
    <>
      <Box className="horizontal-main">
        <Typography className="horizontal-heading">{title}</Typography>
        <Typography className="horizontal-line" />
        <Box className="piechart-box">
          <Typography
            className={`piechart-typo-index ${getIndexColor(
              data[0]?.genderIndex
            )}`}
          >
            {Math.round(data[0]?.genderIndex)}
          </Typography>
          <Typography className="piechart-typo-name">
            {data[0]?.genderType}
          </Typography>

          <PieChart width={185} height={300}>
            <Pie
              dataKey="genderPercent"
              isAnimationActive={true}
              data={formattedData}
              // cx={200}
              // cy={200}
              outerRadius={80}
              fill="#8884d8"
              label={renderCustomizedLabel}
              labelLine={false}
              startAngle={-270}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
          </PieChart>
          <Typography color={colors[1]} className="piechart-typo-name1">
            {data[1]?.genderType}
          </Typography>
          <Typography
            className={`piechart-typo-index ${getIndexColor(
              data[1]?.genderIndex
            )}`}
          >
            {Math.round(data[1]?.genderIndex)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PieChartComponent;
