import React, { useEffect } from "react";
import { Box } from "@mui/material";
import DownloadResources from "../../common/page-sections/DownloadResources";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { DemandspaceTemplateDownloadItems } from "../../../constants/DemandSpace";

function SectionEight({ space }) {
  const { t } = useTranslation(i18next.language);
  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const items = DemandspaceTemplateDownloadItems[space];
  const downloadItems = items?.map((item) => {
    return {
      title: t(item.title, { micro: t(`demandspace.type.${space}`) }),
      subtitle: t(item.subtitle, { micro: t(`demandspace.type.${space}`) }),
      multipleFiles: item.multipleFiles,
      fileToDownload: item.fileToDownload,
    };
  });

  return (
    <div className="section8-demandspace">
      <Box sx={{ flexGrow: 1 }}>
        <DownloadResources listItems={downloadItems} />
      </Box>
    </div>
  );
}

export default SectionEight;
