import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const PriceBrandTableHeading = ({ heading, showIndex }) => {
  return (
    <>
      <Box className="price-tier-heading-box">
        <Box className="price-tier-heading">
          <Typography className="price-tier-heading-typo">
            {`${heading} type`}
          </Typography>
          { showIndex && 
            <Box className="price-tier-heading2">
              <Typography className="price-tier-heading2-typo">%</Typography>
              <Typography className="price-tier-heading2-typo">Index</Typography>
            </Box>
          }
        </Box>
        <Divider className="price-tier-divider"></Divider>
      </Box>
    </>
  );
};

export default PriceBrandTableHeading;
