import React from 'react'
import './FilledOutlinedIcon.scss'
 
function FilledOutlinedIcon(props) {
  return (
<div className={`icon-container ${props.type}`}>
      { props.outlined && <div className={`outline ${props.type}`} /> }
<div className='icon-box'>
        {props.children}
</div>
</div>
  )
}
 
export default FilledOutlinedIcon