import React, { useEffect, useState } from "react";
import i18next from "i18next";
import AnchorNavigationBar from "../../common/AnchorNavigationBar";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionEight from "./SectionEight";
import SectionNine from "./SectionNine";
import { useLocation, useParams } from "react-router-dom";
import { DemandSpaceAnchorNavItems } from "../../../constants/constants";
//import "../../../styles/demand-spaces.scss";
import Runner from "../../custom/Runner";
import SectionZero from "./SectionZero";
import { fetchDemandSpaceData, getInfoPopupStatus } from "../../../service/API";
import Loader from "../../common/Loader";
import ToolTipPopUp from "../../custom/Modal/ToolTipPopUp";
import { DemandspaceToolTipCardData } from "../../../constants/DemandSpace";
import { useMsal } from "@azure/msal-react";

function DemandSpaceTemplate() {
  const { space } = useParams();
  const { accounts } = useMsal();
  // const userId = accounts[0].username;
  const [demandSpaceResponse, setDemandSpaceResponse] = useState(null);
  const [popupStatus, setPopupStatus] = useState(true)
  const [previousPath, setPreviousPath] = useState("");
  const [visibleHover, setVisibleHover] = useState(null);
  const location = useLocation();
  if (location.pathname !== previousPath) {
    setPreviousPath(location.pathname);
    setDemandSpaceResponse(null);
  }

  // const demandspaceTooltipLoaded =
  //   window.sessionStorage.getItem("popupData")?.demandLandscape;

  useEffect(() => {
    //window.scrollTo(0, 0);
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  });

  useEffect(() => {
    if (!demandSpaceResponse) {
      fetchData();
    }
  });

  const anchorNavItems = DemandSpaceAnchorNavItems.map((item) => {
    return {
      key: item.key,
      text: item.text,
    };
  });

  const fetchData = async () => {
    try {
      const response = await fetchDemandSpaceData(space);
      setDemandSpaceResponse(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function navigateToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }
  const fetchPopupData = async () => {
    try {
      const response = await getInfoPopupStatus(accounts[0].username);
      setPopupStatus(response.demandLandscape)
      // window.sessionStorage.setItem("popupData", JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPopupData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <div className="main-content">
        {
        !popupStatus && 
        (
          <ToolTipPopUp CardData={DemandspaceToolTipCardData} />
        ) }

        {demandSpaceResponse ? (
          <>
            <AnchorNavigationBar
              data={{
                category: "demandspace",
                type: space,
                navItems: anchorNavItems,
              }}
              visibleHover={visibleHover}
              clickAction={navigateToSection}
            />
            <div className="ds-section-0">
              <SectionZero macro={demandSpaceResponse} space={space} />
            </div>

            <div className="ds-section-1">
              <SectionOne
                macro={demandSpaceResponse?.consumptions?.drinks}
                space={space}
              />
            </div>
            <div
              onMouseOver={() => {setVisibleHover("section2")}}
              className="ds-section-2"
              id="section2"
            >
              <SectionTwo macro={demandSpaceResponse} space={space} />
            </div>
            <div
              onMouseOver={() => setVisibleHover("section3")}
              className="ds-section3"
              id="section3"
            >
              <SectionThree macro={demandSpaceResponse} space={space} />
            </div>

            <div
              className="section4-container-section4-contextual-factor"
              id="section4"
            >
              <SectionFour
                space={space}
                sectionData={demandSpaceResponse?.contexualFactors}
              />
            </div>

            <div className="ds-section5" id="section5">
              <SectionFive space={space} macro={demandSpaceResponse?.drivers} />
            </div>
            <div className="section6-container" id="section6">
              <SectionSix
                space={space}
                sectionData={demandSpaceResponse?.consumptions}
              />
            </div>
            <div className="ds-section7" id="section7">
              <SectionSeven
                macro={demandSpaceResponse}
                selectedMicro={space}
                sectionData={{ sov: "10", sop: "7" }}
              />
            </div>
            <div className="section8-container-downloads" id="section8">
              <SectionEight space={space} />
            </div>
            <div className="section9-container">
              <SectionNine macro={space} selectedMicro={space} />
            </div>
            <div className="runner">
              <Runner forPage={"demandspaceTemplatePage"} forType={space} />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

export default DemandSpaceTemplate;
