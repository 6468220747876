import React from "react";
import FeedbackSection from "../../common/page-sections/FeedbackSection";

//Demandspace->Feedback

function SectionNine({ macro, selectedMicro }) {
  return (
    <div className="section9">
      <FeedbackSection macro={macro} micro={selectedMicro} />
    </div>
  );
}

export default SectionNine;
