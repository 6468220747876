import React from "react";
import DemandSpacesWrapUp from "../../common/page-sections/Wrap-up/DemandSpacesWrapUp";

function SectionSeven({ macro, selectedMicro, sectionData }) {
  return (
    <div className="section7-demandSpaceTemplate">
      <DemandSpacesWrapUp
        macro={macro}
        selectedMicro={selectedMicro}
        sectionContent={sectionData}
      />
    </div>
  );
}

export default SectionSeven;
