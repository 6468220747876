import React, { Suspense , useEffect}  from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/Routes';
import './styles/App.scss';
import { ThemeProvider } from '@mui/material';
import { theme } from './Theme'

import { MsalProvider } from '@azure/msal-react';
import { getAssetsSASToken } from './service/API';

function App({ instance }) {

  useEffect(() => {
    getAssetsSASToken();
  },[])

  return (
    <Suspense>
      <BrowserRouter>
        <ThemeProvider theme={theme} >
          <MsalProvider instance={instance}>
            <AppRoutes  />
          </MsalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App