import { Box } from "@mui/material";
import React, { useEffect } from "react";
import DownloadResources from "../../common/page-sections/DownloadResources";
import { demandspaceHubDownloadItems } from "../../../constants/DemandSpace";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function SectionTwo() {
  const { t } = useTranslation(i18next.language);

  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  let downloadItems = demandspaceHubDownloadItems.map((item) => {
    return {
      title: t(item.title),
      subtitle: t(item.subtitle),
      multipleFiles: item.multipleFiles,
      fileToDownload: item.fileToDownload
    };
  });
  return (
    <div className="section2-demand-spaces">
      <Box sx={{ flexGrow: 1 }}>
        <DownloadResources listItems={downloadItems} />
      </Box>
    </div>
  );
}

export default SectionTwo;
