import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend" //takes files from public/locales/{language}/translation.json

i18next
.use(backend)
.use(I18nextBrowserLanguageDetector)
.use(initReactI18next)
.init({
    backend: {
        loadPath: "/locales/{{lng}}/translation.json"
    },
    fallbackLng: 'en',
    // set true for debug mode. Should be false for prod
    debug: false,
    interpolation: {
        escapeValue: false,
        keySeparator: "."
    }
})

export default i18next;