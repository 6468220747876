import { Box, Card, Typography } from "@mui/material";
import React from "react";
import FilledOutlinedIcon from "../Icons/FilledOutlinedIcon";
//import "../../../styles/Cards.scss";

const MacroOccasionCard = ({ icon, title, description, icontext = "" }) => {
  const context = { icontext };

  return (
    // <Grid container>
    <>
      <Card className="macro-occassion-cards" key={title}>
        {icon && (
          <FilledOutlinedIcon outlined type="dark">
            {icon}
          </FilledOutlinedIcon>
        )}
        <Box className="card-text-content-container">
          {context.icontext.length > 0 && (
            <Typography variant="body2" className="macro-card-icontext">
              {icontext}
            </Typography>
          )}

          <Typography variant="body2" className="macro-card-title">
            {title}
          </Typography>
          <Typography className="macro-card-description">
            {description}
          </Typography>
        </Box>
      </Card>
    </>
    // </Grid>
  );
};

export default MacroOccasionCard;
