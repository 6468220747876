import React from "react";
import { AppBar, Toolbar, Box, Typography } from "@mui/material";
import { t } from "i18next";

function Footer() {
  return (
    <div className="page-footer">
      <AppBar component="nav" className="page-footer-div" color="transparent">
        <Toolbar disableGutters={true}>
          {/* <Box className="box-footer-content">
            {NavOptions.footerOptions.map((item, index) => (
               <React.Fragment key={item.title}>
                <Button
                  size="h3"
                  key={item.title}
                  className="footer-nav-item"
                  //href={item.path}
                >
                  {item.title}
                </Button>
                {index !== NavOptions.footerOptions.length - 1 && (
                  <span key={`separator-${index}`} className="footer-nav-item-separator">|</span>
                )}
               </React.Fragment>
            ))}
          </Box> */}
          <Typography variant="h6" component="div" className="footer-typo2" />
          <Box className="box-footer-content2" />
          <Box className="box-footer-content">
            <Typography className="footer-nav-item" variant="caption">
              {t("footer.copyright", { year: new Date().getFullYear() })}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Footer;
