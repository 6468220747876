import React, { useEffect, useState } from 'react'
import DemandSpaces from "../../common/page-sections/DemandSpaces";
import { useParams } from 'react-router-dom';

function SectionFive({ sectionData }) {
    const { macro, micro } = useParams()
    const [demandSpaceData, setDemandspaceData] = useState({})
    
    
    useEffect(() => {
        let data = {}
        for(let i=0;i < sectionData.length; i++){
            data[sectionData[i].microCode] = parseFloat(sectionData[i].volume).toFixed(1)
        }
        setDemandspaceData(data)
    },[sectionData])
    
    return (
        <div className='section5'>
            <DemandSpaces 
                page="occasions"
                macro={macro}
                micro={micro} 
                data={demandSpaceData}/>
        </div>
    )
}

export default SectionFive