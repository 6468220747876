import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

/*
    Props:
        tableData: { 
            initialRowCount: int //initial rows to be shown,
            isExpandable: bool // pass if pagination is required,
            paginationAlignment: 'left/right/center' //for aligning the pagination visual,
            paginationTextExpand: String    //Visual text of the pagination component at the bottom of the table before expansion
            paginationTextContract: String  //Visual text of the pagination component at the bottom of the table after expansion
            header: [], // header labels if any
            rowData: [{ key: value },..] // row data
        }

        theme: //Colors to be used for the heading & texts

*/

function ConsumptionDataTable({ theme, tableData, color = "#F6E003" }) {
  const [rowCount, setRowCount] = useState(tableData.initialRowCount);
  const [isExpanded, setIsTableExpanded] = useState(false);

  useEffect(() => {}, [rowCount, isExpanded]);

  function expandTable() {
    if (rowCount < tableData.rowData.length) {
      setRowCount(rowCount + (tableData.rowData.length - rowCount));
    } else {
      setRowCount(tableData.initialRowCount);
    }

    setIsTableExpanded(!isExpanded);
  }

  function getColor(value) {
    if (value < 85) {
      return "negative";
    } else if (value > 115) {
      return "positive";
    } else {
      return "neutral";
    }
  }

  function formatDrinkName(drinkName) {
    return drinkName
      .replace(/beverages/i, "bev")
      .replace(/\s*\(NET\)/, "")
      .replace(/\s*\+ NAB/, "");
  }

  useEffect(() => {
    // Apply border to the last row containing "(net)" in all the displayed tables
    const tables = document.querySelectorAll('.MuiTable-root');
    tables.forEach(table => {
      const rows = table.querySelectorAll('.data-table-row');
      let lastAverageRowIndex = -1;
      rows.forEach((row, index) => {
        if (row.innerText.toLowerCase().indexOf('(net)') > -1) {
          lastAverageRowIndex = index;
        }
      });
      if (lastAverageRowIndex !== -1) {
        rows[lastAverageRowIndex].classList.add('averageFieldRow');
      }
    });
  }, []);

  return (
    <>
    <TableContainer className="consumption-data-table-container">
      <Table aria-label="data table" className="data-table">
        {tableData && tableData.header && (
          <TableHead className="data-table-head">
            <TableRow className="data-table-row">
              {tableData.header.map((item) => (
                <TableCell key={item} className={`data-table-cell ${theme}`}>
                  <Typography color={color}>{formatDrinkName(item)}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody className="data-table-body">
          {(tableData.isExpandable
            ? tableData.rowData.slice(0, rowCount)
            : tableData.rowData
          ).map((row, index) => (
            <TableRow key={`row-${index}`} 
              className={`data-table-row`}
            >
              {Object.keys(row).map((keyName, index) => {
                return (
                  row[keyName] && (
                    <TableCell
                      key={`table-cell ${index}`}
                      className={`data-table-cell  
                    ${index > 0 ? "medium" : "small"} 
                    ${index > 0 ? "center" : "left"}
                    `}
                    >
                      {index === Object.keys(row).length - 1 ? (
                        <Typography
                          component="div"
                          className={`table-cell-values ${getColor(
                            row[keyName]
                          )}`}
                        >
                          {row[keyName]}
                        </Typography>
                      ) : (
                        row[keyName] && (
                          <Typography
                            className={`${
                              keyName === "drinkTypeIndex" ||
                              keyName === "typeIndex"
                                ? `table-cell-values ${getColor(row[keyName])}`
                                : keyName === "drinkTypePercent"
                                ? "table-cell-values"
                                : "table-cell-text"
                            } `}
                          >
                            {keyName === "drinkTypePercent" ||
                            keyName === "typePercent"
                              ? `${Math.round(row[keyName])}%`
                              : keyName === "drinkTypeIndex" ||
                                keyName === "typeIndex"
                              ? Math.round(row[keyName])
                              : row[keyName].replace(/\s*\(NET\)/i, "")}
                          </Typography>
                        )
                      )}
                    </TableCell>
                  )
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {
        //tableData.isExpandable &&
        <Box className={`data-table-pagination ${tableData.isExpandable ? 'showExpand' : 'hideExpand'}`} onClick={expandTable}>
          {isExpanded ? (
            <Typography variant="body1" className="label">
              {tableData.paginationTextContract}
            </Typography>
          ) : (
            <Typography variant="body1" className="label">
              {tableData.paginationTextExpand}
            </Typography>
          )}
          {isExpanded ? (
            <ExpandLess className="icon" />
          ) : (
            <ExpandMore className="icon" />
          )}
        </Box>
    }
    </TableContainer>
    </>
  );
}

export default ConsumptionDataTable;
