import { createSlice } from "@reduxjs/toolkit";

export const DemandSpaceTemplateSlice = createSlice({
    name: 'DemandSpaceTemplate',
    initialState: {
        demandSpaceData: {}
    },
    reducers: {
      setTemplateData: (state, action) => {
        state.demandSpaceData[action.payload.micro] = action.payload.microData
      },
    }
  })
  
  // Action creators are generated for each case reducer function
  export const { setTemplateData } = DemandSpaceTemplateSlice.actions
  
  export default DemandSpaceTemplateSlice.reducer