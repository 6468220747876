import { Box, Grid } from '@mui/material'
import React from 'react'
import QuoteAttributionCard from './QuoteAttributionCard'
import ImageCard from './ImageCard'

export default function JumboQuoteImageRight({ theme, cardData }) {
    return (
        <Box className='jumboquoteimageright-card-container'>
            <Grid container className='card-content-container'>
                <Grid item xs={6} xl={6}>
                    <QuoteAttributionCard theme={theme} cardData={cardData.quoteattributioncarddata} />
                </Grid>
                <Grid item xs={6} xl={6}>
                    <ImageCard theme={theme} cardData={cardData.imagecarddata} />
                </Grid>
            </Grid>
        </Box>
    )
}
