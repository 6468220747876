import { Box, Slider, SliderThumb, Tooltip, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { microIdentifiersShortHand } from "../../../constants/DemandSpace";

const PeopleSlider = styled(Slider)(() => ({
  color: "grey",
  cursor: "auto",
  "& .MuiSlider-thumb": {
    PointerEvent: "none !important",
    "&:focus, &.Mui-active": {},
  },
  "& .MuiSlider-rail": {
    color: "grey",
  },
  "& .Mui-focusVisible": {
    boxShadow: "none !important",
    PointerEvent: "none !important",
  },
}));

const CustomSlider = ({
  index,
  sliderEndtValue,
  sliderStartValue,
  sliderHeading,
  values,
  tooltipText,
  space,
  textColor = "white",
  selectedPeopleSegment,
}) => {
  const theme = useTheme();
  const headingColor = space ? theme?.palette[space]?.main : "white";

  function SliderThumbComponent(props) {
    const { children, ...other } = props;
    const sortedValues = props.name
      .slice()
      .sort((a, b) => a.percent - b.percent);
    const sliderValues = sortedValues[props["data-index"]];
    return (
      <Box className="slider-thumb-main-box">
        <Tooltip
          classes={{ tooltip: "custom-tooltip" }}
          placement="top"
          style={{ zIndex: 1000 }}
          title={
            values.length > 1
              ? tooltipText(sliderValues, index, props["data-index"])
              : tooltipText
          }
        >
          <SliderThumb {...other}>
            <span
              className={`selected-slider-thumb ${sliderValues?.microCode} ${
                selectedPeopleSegment?.value &&
                selectedPeopleSegment?.value !==
                  microIdentifiersShortHand[sliderValues?.microCode] &&
                "non-selected-thumb"
              } `}
            >
              {microIdentifiersShortHand[sliderValues?.microCode]}
            </span>
          </SliderThumb>
        </Tooltip>
      </Box>
    );
  }

  return (
    <>
      <Box className="slider-main-div" key={index} width="100%">
        <Typography color={headingColor} className="slider-heading-value">
          {sliderHeading}
        </Typography>
        <Box className="sliderstartvalue">
          <Typography color={textColor} className={`sliderstartvalue-typo1`}>
            {sliderStartValue}
          </Typography>
          <Typography color={textColor} className={`sliderstartvalue-typo1`}>
            {sliderEndtValue}
          </Typography>
        </Box>
        <PeopleSlider
          slots={{
            thumb: (thumbProps) => (
              <SliderThumbComponent {...thumbProps} name={values} />
            ),
          }}
          track={false}
          marks={[{ value: 50 }]}
          // disabled
          valueLabelDisplay="auto"
          classes={{
            thumb: "custom-thumb",
            mark: "custom-mark",
          }}
          defaultValue={values?.map((val) => Number(val?.percent))}
        />
      </Box>
    </>
  );
};

export default CustomSlider;
