import { Grid, Paper } from "@mui/material";
import i18next from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/*
  Props: 
  ids: Array[<block identifier code>'](Mandatory) 
    e.g. ['Fram_PSGMT_2', 'Fram_DS_1']
  highlightItem: Object(Optional) required when needed to highlight blocks based on certain actions 
    e.g. { hoveredSpace: 'Fram_PSGMT_2', highlightState: true/false }
  wayfinderID: String (Optional)- Identifier to distinguish a particular wayfinder component when multiple wayfinders are present
    e.g. '<any-name-of-choice>'
*/

export default function WayFinderDemandSpace({ ids, highlightItem, wayfinderID }) {
  const { t } = useTranslation(i18next.language);
  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    if (
      highlightItem &&
      highlightItem.hoveredSpace &&
      highlightItem.hoveredSpace !== ""
    ) {
      const wayfinder = document.getElementById(wayfinderID);
      const itemToHighlight = wayfinder.querySelector(
        `#${highlightItem.hoveredSpace}`
      );
      if (highlightItem.highlightState) {
        itemToHighlight.style.backgroundColor = "#F6E003";
      } else {
        itemToHighlight.style.backgroundColor = "#3D3D3D";
      }
    }
  }, [highlightItem, wayfinderID]);

  const boxStyle = {
    background: "#1D1D1D", // Set your initial color here
    position: "relative",
    height: "15.111px",
    overflow: "hidden",
    borderRadius: "2px",
  };

  const boxStyleBig = {
    background: "var(--grayscale-800, #1D1D1D)",
    position: "relative",
    height: "85.63px",
    overflow: "hidden",
    borderRadius: "2px",
  };
  const boxStyleMid = {
    background: "var(--grayscale-800, #1D1D1D)",
    position: "relative",
    height: "50.371px",
    overflow: "hidden",
    borderRadius: "2px",
  };
  const boxStyleRight = {
    background: "var(--grayscale-800, #1D1D1D)",
    position: "relative",
    height: "32px",
    overflow: "hidden",
    borderRadius: "2px",
  };
  return (
    <div className="main-content-wayfinder" id={wayfinderID ? wayfinderID : ""}>
      <Grid container className="wayfinder-content">
        <Grid item xs={1}>
          <div className="occasions-vertical-title font-change">
            { t('occasions.pagename') }
          </div>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing="2.5px">
            <Grid item xs={12}>
              <div className="people-segments-title font-change">
                { t('people-segment.pagename') }
              </div>
              <Grid container spacing="2.5px" id="people-segments">
                <Grid item xs={1.3}>
                  <Paper></Paper>
                </Grid>
                <Grid item xs={1.3}>
                  <Paper
                    id="Fram_PSGMT_3"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_3")
                        ? "#ADA1F8"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.3}>
                  <Paper
                    id="Fram_PSGMT_2"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_2")
                        ? "#B983FF"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_8"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_8")
                        ? "#8FE8BD"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_9"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_9")
                        ? "#79D271"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_11"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_11")
                        ? "#A1E8F8"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_12"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_12")
                        ? "#83BCFF"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_5"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_5")
                        ? "#ffc759"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_6"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_PSGMT_6")
                        ? "#ff8811"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1.3}>
              <Grid container spacing="2.5px" id="occasions">
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_2"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_2")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_3"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_3")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_6"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_6")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_5"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_5")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_7"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_7")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_9"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_9")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_10"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_10")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_12"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_12")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_13"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_13")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_14"
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes("Fram_OCCAS_14")
                        ? "#F6E003"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2.6}>
              <Grid container spacing="2.5px">
                <Grid item xs={12}>
                  <Paper
                    id="Fram_DS_1"
                    sx={{
                      ...boxStyleBig,
                      backgroundColor: ids.includes("Fram_DS_1")
                        ? "#FFFFFF"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_DS_2"
                    sx={{
                      ...boxStyleBig,
                      backgroundColor: ids.includes("Fram_DS_2")
                        ? "#FFFFFF"
                        : "#3D3D3D",
                    }}
                  ></Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8.1}>
              <Grid container spacing="2.5px">
                <Grid item xs={8}>
                  <Grid container spacing="2.5px">
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_3"
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes("Fram_DS_3")
                            ? "#FFFFFF"
                            : "#3D3D3D",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_4"
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes("Fram_DS_4")
                            ? "#FFFFFF"
                            : "#3D3D3D",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing="2.5px">
                        <Grid item xs={6}>
                          <Paper
                            id="Fram_DS_5"
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes("Fram_DS_5")
                                ? "#FFFFFF"
                                : "#3D3D3D",
                            }}
                          ></Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            id="Fram_DS_7"
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes("Fram_DS_7")
                                ? "#FFFFFF"
                                : "#3D3D3D",
                            }}
                          ></Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing="2.5px">
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_9"
                        sx={{
                          ...boxStyleRight,
                          backgroundColor: ids.includes("Fram_DS_9")
                            ? "#FFFFFF"
                            : "#3D3D3D",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_10"
                        sx={{
                          ...boxStyleMid,
                          backgroundColor: ids.includes("Fram_DS_10")
                            ? "#FFFFFF"
                            : "#3D3D3D",
                        }}
                      ></Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing="2.5px">
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_12"
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes("Fram_DS_12")
                            ? "#FFFFFF"
                            : "#3D3D3D",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_13"
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes("Fram_DS_13")
                            ? "#FFFFFF"
                            : "#3D3D3D",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing="2.5px">
                        <Grid item xs={4}>
                          <Paper
                            id="Fram_DS_6"
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes("Fram_DS_6")
                                ? "#FFFFFF"
                                : "#3D3D3D",
                            }}
                          ></Paper>
                        </Grid>
                        <Grid item xs={4}>
                          <Paper
                            id="Fram_DS_8"
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes("Fram_DS_8")
                                ? "#FFFFFF"
                                : "#3D3D3D",
                            }}
                          ></Paper>
                        </Grid>
                        <Grid item xs={4}>
                          <Paper
                            id="Fram_DS_11"
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes("Fram_DS_11")
                                ? "#FFFFFF"
                                : "#3D3D3D",
                            }}
                          ></Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
