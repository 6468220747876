import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/Store';

import './i18n/i18n';

import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import accessData from './data/access.json';

const msalInstance = new PublicClientApplication(msalConfig);

const emails = accessData;

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    const username = account.username;

    const email = emails.find((email) => email.email.toLowerCase() === username.toLowerCase());
    if (!email) {
      msalInstance.logoutRedirect();
    } else {
      msalInstance.setActiveAccount(account);
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App instance={msalInstance}/>
      </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
