import React, { useRef, useState } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { assets } from "../common/images";

function SectionFour() {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();
  const demandLandscapeBtnRef = useRef();

  const visualOneItems = t("home.section4.segment1.visual.visual1Items", {
    returnObjects: true,
  });
  const visualTwoItems = t("home.section4.segment1.visual.visual2Items", {
    returnObjects: true,
  });
  /*const visualThreeItems = t("home.section4.segment1.visual.visual3Items", {
    returnObjects: true,
  });*/

  const [showDemandLandscapeBtn, setShowDemandLandscapeBtn] = useState(false);

  function mouseOverAction() {
    setShowDemandLandscapeBtn(true);
  }

  function mouseLeaveAction() {
    if (!(document.activeElement === demandLandscapeBtnRef.current)) {
      setShowDemandLandscapeBtn(false);
    }
  }

  function buttonMouseOverAction() {
    setShowDemandLandscapeBtn(true);
    document
      .getElementById("image-container")
      .dispatchEvent(new Event("mouseover"));
  }

  function buttonMouseLeaveAction() { }

  return (
    <>
      <div className="home-section4">
        <div className="section4-segment-one">
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="h5" className="segment-heading">
              {t("home.section4.segment1.heading")}
            </Typography>
            <Typography variant="h5" className="segment-paragraph">
              <Trans
                components={{ colored: <strong /> }}
              >
                {t("home.section4.segment1.paragraph")}
              </Trans>
            </Typography>
          </Box>
          <div className="segment-visual">
            <Grid container className="segment-visual-container" gap={2}>
              <Grid item xs={2.5}>
                <Box className="grid-item-content-box">
                  <Typography
                    variant="h5"
                    className="section4-segment-one-visual-buttongroup-heading"
                  >
                    <Trans components={{ colored: <i /> }}>
                      {t("home.section4.segment1.visual.visual2Heading")}
                    </Trans>
                  </Typography>
                  {visualTwoItems.map((item) => (
                    <div className="items" key={item}>
                      <Typography>{item}</Typography>
                    </div>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h4" className="grid-item-divider">
                  +
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Box className="grid-item-content-box">
                  <Typography
                    variant="h5"
                    className="section4-segment-one-visual-buttongroup-heading"
                  >
                    <Trans components={{ colored: <i /> }}>
                      {t("home.section4.segment1.visual.visual1Heading")}
                    </Trans>
                  </Typography>
                  {visualOneItems.map((item) => (
                    <div className="items" key={item}>
                      <Typography>{item}</Typography>
                    </div>
                  ))}
                </Box>
              </Grid>
              {/* <Grid item>
                <Typography variant="h4" className="grid-item-divider">
                  =
                </Typography>
              </Grid>
              <Grid item>
                <Box className="grid-item-content-box">
                  <Typography
                    variant="h5"
                    className="section4-segment-one-visual-buttongroup-heading"
                  >
                    <Trans components={{ colored: <i /> }}>
                      {t("home.section4.segment1.visual.visual3Heading")}
                    </Trans>
                  </Typography>
                  {visualThreeItems.map((item) => (
                    <div className="items" key={item}>
                      <Typography>{item}</Typography>
                    </div>
                  ))}
                </Box>
              </Grid> */}
            </Grid>
          </div>
        </div>

        <div className="section4-segment-two">
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="h5" className="segment-heading">
            {t("home.section4.segment2.heading")}
          </Typography>
          <Typography variant="h5" className="segment-paragraph">
            <Trans
              components={{ colored: <strong /> }}
            >
              {t("home.section4.segment2.paragraph")}
            </Trans>
          </Typography>
          </Box>

          <Box className="segment-visual" sx={{ flexGrow: 1 }}>
            <Box
              className="image-container"
              id="image-container"
              sx={{
                backgroundImage: `url(${assets.home.demandLandscapeIntersection})`,
              }}
            ></Box>
            {/* <Grid container className="segment-visual-container">
              <Grid item className="top-item">
                <Grid container className="top-item-container" spacing={2}>
                  <Grid item xs={2.7}>
                    <Box className="top-item-left" />
                  </Grid>
                  <Grid item xs={9.3}>
                    <Box className="top-item-right">
                      <Typography
                        variant="body"
                        className="grid-item-text title"
                      >
                        {t("home.section4.segment2.visual.topRightText")}
                      </Typography>
                      <Typography
                        variant="body"
                        className="grid-item-text subtitle"
                      >
                        {t("home.section4.segment2.visual.topRightSubtext")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className="bottom-item">
                <Grid container spacing={2} className="bottom-item-container">
                  <Grid item xs={2.7}>
                    <Box className="bottom-item-left">
                      <Typography
                        variant="body"
                        className="grid-item-text title"
                      >
                        {t("home.section4.segment2.visual.bottomLeftText")}
                      </Typography>
                      <Typography
                        variant="body"
                        className="grid-item-text subtitle"
                      >
                        {t("home.section4.segment2.visual.bottomLeftSubtext")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={9.3}>
                    <Box className="bottom-item-right">
                      <Typography
                        variant="body"
                        className="grid-item-text title"
                      >
                        {t("home.section4.segment2.visual.bottomRightText")}
                      </Typography>
                      <Typography
                        variant="body"
                        className="grid-item-text subtitle"
                      >
                        {t("home.section4.segment2.visual.bottomRightSubtext")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Box>
        </div>

        <div className="section4-segment-three">
          <Typography variant="h5" className="segment-paragraph">
            <Trans
              components={{ colored: <strong /> }}
            >
              {t("home.section4.segment3.paragraph")}
            </Trans>
          </Typography>

          <Box className="segment-visual" sx={{ flexGrow: 1 }}>
            <Box
              className="image-container"
              id="image-container"
              sx={{
                backgroundImage: `url(${assets.home.demandLandscapeFrameworkImage})`,
              }}
              onMouseOver={mouseOverAction}
              onMouseLeave={mouseLeaveAction}
            ></Box>
            {showDemandLandscapeBtn && (
              <Button
                ref={demandLandscapeBtnRef}
                className="explore-demandlandscape-button outlined-button"
                variant="contained"
                onClick={() => {
                  navigate("/demandspace");
                  window.scrollTo(0, 0);
                }}
                onMouseOver={() => buttonMouseOverAction}
                onMouseLeave={() => buttonMouseLeaveAction}
              >
                {t("buttons.exploreDemandLandscape")}
              </Button>
            )}
          </Box>
          <Box className="segment-visual-buttons">
            <Button
              className="outlined-button"
              variant="contained"
              onClick={() => {
                navigate("/peoplesegment");
                window.scrollTo(0, 0);
              }}
            >
              {t("buttons.explorePeopleSegment")}
            </Button>
            <Button
              className="outlined-button"
              variant="contained"
              onClick={() => {
                navigate("/occasions");
                window.scrollTo(0, 0);
              }}
            >
              {t("buttons.exploreOccasions")}
            </Button>
            <Button
              className="outlined-button"
              variant="contained"
              onClick={() => {
                navigate("/demandspace");
                window.scrollTo(0, 0);
              }}
            >
              {t("buttons.exploreDemandSpaces")}
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
}

export default SectionFour;
