import React from "react";
import { Typography, Grid, Divider, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function SectionThree() {
  const { t } = useTranslation(i18next.language);

  return (
    <div className="home-section3">
      <div className="inner-content">
        <Typography variant="h5" className="paragraph">
          {t("home.section3.paragraph")}
        </Typography>

        <>
          <Grid container className="visual-container">
            <Grid item className="visual-items" xs={2.5}>
              <Box className="visual-item-content-container">
                <div className="info-image measure" />
                <Box className="divider-box">
                  <Divider className="divider">
                    <Typography
                      component="div"
                      variant="h5"
                      className="divider-text"
                    >
                      {t("home.section3.visual.item1.heading")}
                    </Typography>
                  </Divider>
                </Box>
                <Typography component="h4" variant="h5" className="info-text">
                  {t("home.section3.visual.item1.infoText")}
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="info-subtext"
                >
                  {t("home.section3.visual.item1.infoSubtext")}
                </Typography>
              </Box>
            </Grid>

            <Grid item className="visual-items" xs={2.5}>
              <Box className="visual-item-content-container">
                <div className="info-image analyze" />
                <Box className="divider-box">
                  <Divider className="divider">
                    <Typography
                      component="div"
                      variant="h5"
                      className="divider-text"
                    >
                      {t("home.section3.visual.item2.heading")}
                    </Typography>
                  </Divider>
                </Box>
                <Typography component="h4" variant="h5" className="info-text">
                  {t("home.section3.visual.item2.infoText")}
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="info-subtext"
                >
                  {t("home.section3.visual.item2.infoSubtext")}
                </Typography>
              </Box>
            </Grid>

            <Grid item className="visual-items" xs={2.5}>
              <Box className="visual-item-content-container">
                <div className="info-image articulate" />
                <Box className="divider-box">
                  <Divider className="divider">
                    <Typography
                      component="div"
                      variant="h5"
                      className="divider-text"
                    >
                      {t("home.section3.visual.item3.heading")}
                    </Typography>
                  </Divider>
                </Box>
                <Typography component="h4" variant="h5" className="info-text">
                  {t("home.section3.visual.item3.infoText")}
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="info-subtext"
                >
                  {t("home.section3.visual.item3.infoSubtext")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      </div>
    </div>
  );
}

export default SectionThree;
